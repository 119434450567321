import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import axios from "axios";
import classNames from "classnames";

import { restHostBackend } from "../../../../../config";
import { states } from "../states";

const uploadFiles = (file, token, setFieldValue, item) => {
  let uploadfile = {
    name: file.name,
    file: file.file,
  };

  uploadfile = JSON.stringify(uploadfile);

  let fileID = "";

  axios({
    method: "post",
    url: `${restHostBackend}/api/webforms/createfile`,
    data: uploadfile,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": token,
    },
  })
    .then((res) => {
      fileID = res.data;
      setFieldValue(item.id, fileID);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

const convertToBase64 = (file, token, setFieldValue, item) => {
  const reader = new FileReader(),
    tempFile = file;

  reader.readAsDataURL(file);
  reader.onload = () => {
    tempFile.file = reader.result;
    uploadFiles(tempFile, token, setFieldValue, item);
  };
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
};

const FileField = ({ item, token, language }) => {
  const { values, setFieldValue } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  // @todo Reset after submit.
  const [files, setFiles] = useState(null);

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}{" "}
        {item.fileExtensions != ""
          ? `- ${
              language === "en"
                ? "Allowed file formats:"
                : "Erlaubte Dateiformate:"
            } ${item.fileExtensions}`
          : null}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      <input
        id={item.id}
        name={item.id}
        type="file"
        accept="application/pdf, image/*, .csv, text/plain, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="form-control"
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        onChange={(event) => {
          const allowedExt =
            item.fileExtensions !== ""
              ? item.fileExtensions
              : ["pdf", "jpg", "jpeg", "png", "svg", "csv", "txt"];

          if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const ext = file.name.match(/\.([^.]+)$/)[1];

            if (!allowedExt.includes(ext.toLowerCase())) {
              window.alert(`${file.name} has the wrong Fileformat!`);
              setFiles(null);
            }
            // If filesize is bigger than 5mb
            else if (file.size > 5000000) {
              window.alert(`${file.name} is to big! max Filesize 5MB!`);
              setFiles(null);
            } else {
              convertToBase64(file, token, setFieldValue, item);
              setFiles(file);
            }
          }
        }}
      />
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
      {files != null && (
        <div>
          <span style={{ marginRight: "5px" }}>{files.name}</span>
          <button
            onClick={() => {
              setFiles(null);
              setFieldValue(item.id, "");
            }}
            className="btn hfmdk-button"
          >
            <div className="overflow-wrapper">
              <div className="animation">
                <span data-title="x">x</span>
              </div>
            </div>
          </button>
        </div>
      )}
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
      />
    </div>
  );
};

FileField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
    states: PropTypes.array,
    fileExtensions: PropTypes.string,
    multiple: PropTypes.object,
  }),
  token: PropTypes.string,
  language: PropTypes.oneOf(["de", "en"]),
};

export default FileField;
