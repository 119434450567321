import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { states } from "../states";
import classNames from "classnames";

const CheckboxesField = ({ item }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  return (
    <div
      className={classNames({
        "form-group checkboxes": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      {item.options.map((el) => {
        return (
          <div key={`${item.id}_${el.value}`} className="form-check">
            <Field
              className="form-check-input"
              type="checkbox"
              name={item.id}
              id={el.value}
              onChange={handleChange}
              onBlur={handleBlur}
              value={el.value}
              required={(!!item.required || required) && !optional && visible}
              disabled={!enabled || disabled}
              //value={values[item.id]?.includes(el.value)}
            />
            <label className="form-check-label" htmlFor={el.value}>
              {el.title}
            </label>
          </div>
        );
      })}
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

CheckboxesField.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    required: PropTypes.object,
    multiple: PropTypes.shape({
      limit: PropTypes.number,
      message: PropTypes.string,
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    id: PropTypes.string,
    flex: PropTypes.number,
    states: PropTypes.object,
  }),
};

export default CheckboxesField;
