import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorBoundary from "../../../../error-boundary";
import FieldTime from "../../../date-time/field-time";
import moment from "moment";
import { drupalFormat } from "../../../../config";

const DateWrapper = (props) => {
  const showDate =
    (props.content.fieldPlanned ||
      (props.content.entityBundle === "event" &&
      (moment(props.content.fieldDate?.startTime, drupalFormat).format("HHmm") !==
      "1200" ||
      moment(props.content.fieldDate?.endTime, drupalFormat).format("HHmm") !==
      "1200"))
    ) && props.content.fieldDate &&
    props.content.fieldDate.value &&
    props.releaseState === "all",
    showDay =
      (props.content.fieldPlanned ||
        (props.content.entityBundle === "event" &&
          (moment(props.content.fieldDate?.startTime, drupalFormat).format("HHmm") !==
            "1200" ||
            moment(props.content.fieldDate?.endTime, drupalFormat).format("HHmm") !==
            "1200"))
      ) && props.content.fieldDate &&
      props.content.fieldDate.value &&
      props.releaseState === "dayOnly",
      planned = props.content.fieldPlanned;


  if (props.content.fieldDate) {
    return (
      <ErrorBoundary>
        <div className="date-wrapper">
          {(showDay || showDate) &&
            <div className="day">
              <FieldTime
                date={props.content.fieldDate.value}
                timestamp={true}
                format={"dd"}
                className="day"
              />
            </div>
          }
          {/* Dates are set as 12:00AM when the days are known, but no times are set. IF this happens, there should no time be visible. */}
          {showDate && (
            <div className="time">
              <FieldTime
                date={props.content.fieldDate.value}
                timestamp={true}
                format={"HH.mm"}
                className="time"
              />
              Uhr
            </div>
          )}
          {!planned &&
            <div className="tba">
              TBA
            </div>
          }
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default DateWrapper;
