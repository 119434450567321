import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ParagraphExtendedTeaserOverview from "./../paragraph-extended-teaser-overview";
import {drupalFormat, self} from "../../../../../config";
import moment from "moment";
import { compose } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import mainSettingsConfigQuery from "../../../../../main-settings-config-query.graphql";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import OutsideClickHandler from 'react-outside-click-handler';

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  hideSort: reduxStore.appStore.hideSort,
  hideFilter: reduxStore.appStore.hideFilter
});

class FestivalConferenceOverview extends Component {

  getFestivalTime = () => {
    if (
      this.props.mainSettingsConfig.configPagesById &&
      this.props.mainSettingsConfig.configPagesById.fieldFestivalTime
    ) {
      let endDate = moment
          .utc(
            this.props.mainSettingsConfig.configPagesById.fieldFestivalTime
              .endTime,
            drupalFormat
          )
          .local(),
        startDate1 = moment
          .utc(
            this.props.mainSettingsConfig.configPagesById.fieldFestivalTime
              .startTime,
            drupalFormat
          )
          .local(),
        festivalDates = [];

      let startDate = startDate1.clone();


      // @todo: ugly fix for day and time problems. Someone has to review this, i do not have any time.
      startDate.add(4, "hours");

      const festivalLength = moment
        .duration(
          moment(endDate, "YYYY-MM-DD").diff(
            moment(startDate, drupalFormat).add(4, "hours")
          )
        )
        .asDays();

      for (let i = 0; i <= festivalLength; i++) {
        festivalDates.push(moment(startDate, drupalFormat).add(i, "days"));
      }

      return festivalDates;
    } else {
      return false;
    }
  };

  constructor(props) {
    super(props);

    // Highlight the corresponding sort option that is used in graphql sort in frontend sort overlay
    let initiallyHighlightedSortOption = false;

    if (this.props.content.fieldParticipantType === "programme") {
      initiallyHighlightedSortOption = "schedule";
    } else if (this.props.content.fieldParticipantType === "speaker" || this.props.content.fieldParticipantType === "act") {
      initiallyHighlightedSortOption = "alphabetically";
    }

    this.state = {
      festivalTime: this.getFestivalTime(),
      selectedDay: 0,
      filterForAll: true,
      forcedSort: false,
      initiallyHighlightedSortOption,
      openSort: false,
      scrollOptions:  {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.classList.contains("body-scroll-lock-ignore")) {
              return true;
            }
            el = el.parentElement;
          }
        },
      },
    };

    this.sortWrapper = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.mainSettingsConfig.loading !==
      prevProps.mainSettingsConfig.loading
    ) {
      this.setState({
        festivalTime: this.getFestivalTime(),
      });
    }
  }

  toggleOverlay = () => {
    this.setState({
      openSort: !this.state.openSort,
    });
  };

  changeDay = (index) => {
    window.scrollTo(0, 0);
    if (index === "all") {
      this.setState({
        filterForAll: true,
      });
    } else {
      this.setState({
        selectedDay: index,
        filterForAll: false,
      });
    }
  };

  sortBy = (sortType) => {
    window.scrollTo(0, 0);
    this.setState({
      initiallyHighlightedSortOption: false,
      forcedSort: sortType,
    });
    this.toggleOverlay();
  };

  render() {
    moment.locale(this.props.currentLanguage);
    return (
      <section className={`festival-conference-wrapper ${this.props.hideFilter ? 'hide-filter' : 'show-filter'} ${this.props.hideSort ? 'hide-sort' : 'show-sort'}`}>
        {this.props.content.fieldTimeFilter && this.state.festivalTime && (
          <div className="day-filter">
            <div className="fixed-wrapper header-element">
              <div className="border-element"/>
              <ul className="list-wrapper">
                <li
                  key={"all"}
                  className={`${
                    this.state.filterForAll ? "active" : "inactive"
                  }`}
                >
                  <button
                    tabIndex={0}
                    role="button"
                    aria-label="Show all"
                    onClick={() => this.changeDay("all")}
                  >
                    <FormattedMessage id="all" />
                  </button>
                </li>
                {this.state.festivalTime &&
                  this.state.festivalTime.map((day, index) => (
                    <li
                      key={index}
                      className={`${
                        !this.state.filterForAll &&
                        index === this.state.selectedDay
                          ? "active"
                          : "inactive"
                      }`}
                    >
                      <button
                        tabIndex={0}
                        role="button"
                        aria-label={`Go to ${moment(day, drupalFormat).format(
                          "dd, DD.MM."
                        )}`}
                        onClick={() => this.changeDay(index)}
                      >
                        {moment(day, drupalFormat).format("dd")}
                      </button>
                    </li>
                  ))}
              </ul>
              <div className="effect-element" />
            </div>
          </div>
        )}
        {((this.props.content.entityBundle === "participants_overview" &&
            this.props.content.fieldParticipantType !== "speaker") || this.props.content.entityBundle === "conference_overview") &&
            this.props.content.fieldShowSort &&
          <div
            className={`sort ${this.state.openSort && !this.props.hideSort ? "active" : "inactive"}`}
            ref={this.sortWrapper}
          >
            {/*<div className="blur-background" />*/}
            <div className="sort-wrapper">
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (this.state.openSort) {
                    this.toggleOverlay();
                  }
                }}
              >
                <div className="accordion-wrapper">
                  <div className={"border-element"}/>
                  <div className="collapse-content">
                    <button
                      tabIndex={0}
                      role="button"
                      aria-label="Sort by newest"
                      className={`btn ${this.state.forcedSort === "newest" || this.state.initiallyHighlightedSortOption === "newest"  ? 'active' : ''}`}
                      onClick={() => this.sortBy("newest")}
                    >
                      <FormattedMessage id="newest" />
                    </button>
                    <button
                      tabIndex={0}
                      role="button"
                      aria-label="Sort alphabetically"
                      className={`btn ${this.state.forcedSort === "alphabetically" || this.state.initiallyHighlightedSortOption === "alphabetically" ? 'active' : ''}`}
                      onClick={() => this.sortBy("alphabetically")}
                    >
                      A-Z
                    </button>
                    <button
                      tabIndex={0}
                      role="button"
                      aria-label="Sort by schedule"
                      className={`btn ${this.state.forcedSort === "schedule" || this.state.initiallyHighlightedSortOption === "schedule" ? 'active' : ''}`}
                      onClick={() => this.sortBy("schedule")}
                    >
                      <FormattedMessage id="chronological" />
                    </button>
                  </div>
                  <div className="button-wrapper">
                    <button
                      tabIndex={0}
                      role="button"
                      aria-label="Sort"
                      className="btn btn-primary more"
                      onClick={() => this.toggleOverlay()}
                    >
                      <div className="effect-element" />
                      <FormattedMessage id="sort" />
                    </button>
                  </div>
              </div>
              </OutsideClickHandler>
            </div>
          </div>
        }
        {this.state.festivalTime && (
          <ParagraphExtendedTeaserOverview
            content={this.props.content}
            skipQuery={false}
            dayFilterStart={
              this.state.filterForAll
                ? false
                : moment(
                    this.state.festivalTime[this.state.selectedDay],
                    drupalFormat
                  )
                  .startOf('day')
                  .add(3, "h")
                  .unix()
            }
            dayFilterEnd={
              this.state.filterForAll
                ? false
                : moment(
                    this.state.festivalTime[this.state.selectedDay],
                    drupalFormat
                  )
                    .startOf('day')
                    .add(3, "h")
                    .add(1, "d")
                    .unix()
            }
            dayFilterEnabled={
              this.state.filterForAll
                ? false
                : this.props.content.fieldTimeFilter
            }
            filterCloudLabels={
              this.props.content.fieldFilteroptions &&
              this.props.content.fieldFilteroptions.map(
                (item) => item && item.entity && (item.entity.fieldLabel ? item.entity.fieldLabel : item.entity.name)
              )
            }
            filterCloudFilters={
              this.props.content.fieldFilteroptions &&
              this.props.content.fieldFilteroptions.map(
                (item) => item && item.entity && item.entity.fieldMachineName
              )
            }
            filterCloudFilterSingle={false}
            filterCloudMultipleFilterCondition={"OR"}
            filterCloudPreventEmptyResult={true}
            filterDisplayType={"overlay"}
            forcedSort={this.state.forcedSort}
            showSort={this.props.content.fieldShowSort}
            removeDuplicates={this.state.filterForAll}
          />
        )}
      </section>
    );
  }
}

FestivalConferenceOverview.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldTimeFilter: PropTypes.bool,
    fieldFilteroptions: PropTypes.arrayOf(PropTypes.object),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  mainSettingsConfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(mainSettingsConfigQuery, {
      name: "mainSettingsConfig",
    })
  )(withRouter(FestivalConferenceOverview))
);
