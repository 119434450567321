

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { calculateCollapseHeight } from "../../../lib/calculate-collape-height";
import TagWrapper from "./components/tag-wrapper";
import Presenter from "./components/presenter";
import SocialLinks from "./components/social-links";
import LookingFor from "./components/looking-for";
import MoreDates from "./components/more-dates";
import AllDates from "./components/all-dates";
import Sponsors from "./components/sponsors";
import Credits from "./components/credits";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile,
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage
});

class TeaserParticipantCollapse extends Component {
  static defaultProps = { pagerFullPage: false };

  componentDidMount() {
    calculateCollapseHeight(this.props.mainTeaserRef);
  }

  render() {
    return (
      <div className="inner-wrapper">
        <div className="row">
          <div
            className={`col-16 ${
              this.props.overviewType === "artists-overview"
                ? "col-xl-16"
                : "col-xl-12 offset-xl-4"
            } content-wrapper`}
          >
            <TagWrapper content={this.props.item} />
            <Presenter
              content={this.props.item}
              dayFilterEnabled={this.props.dayFilterEnabled}
            />
            {this.props.item.fieldText && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldText.processed,
                }}
              />
            )}
            {this.props.overviewType === "artists-overview" ? (
              <AllDates content={this.props.item} releaseState={this.props.mainSettings.fieldReleaseState}/>
            ) : (
              <MoreDates content={this.props.item} releaseState={this.props.mainSettings.fieldReleaseState} />
            )}
            <SocialLinks content={this.props.item} />
            <LookingFor content={this.props.item} />
            <Sponsors
              content={this.props.item}
              dayFilterEnabled={this.props.dayFilterEnabled}
            />
            <Credits content={this.props.item} />
          </div>
        </div>
      </div>
    );
  }
}

export const teaserParticipantPropTypes = PropTypes.shape({
  item: PropTypes.object,
  mainTeaserRef: PropTypes.object,
});

TeaserParticipantCollapse.propTypes = {
  item: PropTypes.object.isRequired,
  mainTeaserRef: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withRouter(TeaserParticipantCollapse));