import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Image from "../../image/image";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Rename. This is the participant teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
class TeaserPersonOverview extends Component {
  state = { isOpen: false };

  toggleOverlay = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        document.querySelector("html").style.overflow = "visible";
      });
    } else {
      this.setState({ isOpen: true }, () => {
        document.querySelector("html").style.overflow = "hidden";
      });
    }
  };

  render() {
    return (
      <>
        <article
          className="node node-teaser teaser-person teaser-person-overview"
          data-testid="teaser-person"
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <div className="container">
            <div className="row">
              <div className="col-8 image">
                <div className="image-wrapper">
                  {this.props.item.fieldBildWCaption ? (
                    <Image
                      data={
                        this.props.item.fieldBildWCaption.entity.fieldMediaImage
                      }
                      nodeTitle={this.props.item.title}
                    />
                  ) : (
                    <div className="placeholder">
                      {/* Getting first Letter of First Name */}
                      <div className="initials">
                        {this.props.item.title.split(" ")[0][0] && (
                          <>{this.props.item.title.split(" ")[0][0]}</>
                        )}
                        {/* Getting last Letter of Last Name, ignoring middle Names */}
                        {this.props.item.title.split(" ")[
                          this.props.item.title.split(" ").length - 1
                        ] && (
                          <>
                            {
                              this.props.item.title.split(" ")[
                                this.props.item.title.split(" ").length - 1
                              ][0]
                            }
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-8 info">
                <div className="info-wrapper">
                  <h2>{this.props.item.title}</h2>
                  {this.props.item.fieldCompany && (
                    <div className="company">
                      <span className="top-line">
                        {this.props.item.fieldCompany}
                      </span>
                    </div>
                  )}
                  {this.props.item.fieldPosition && (
                    <div className="role">
                      <span className="top-line">
                        {this.props.item.fieldPosition}
                      </span>
                    </div>
                  )}
                  {this.props.item.fieldBeschreibung && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.fieldBeschreibung.processed,
                      }}
                    />
                  )}
                  <div className={"contact"}>
                    {this.props.item.fieldMail && (
                      <a
                        className="mail"
                        href={`mailto:${this.props.item.fieldMail}`}
                      >
                        Email schreiben
                      </a>
                    )}

                    {this.props.item.fieldTelefon && (
                      <a
                        className="phone"
                        href={`tel:${this.props.item.fieldTelefon
                          .replace(/ /g, "")
                          .replace(/\([\s\S]*?\)/g, "")}`}
                      >
                        {this.props.item.fieldTelefon}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        {/*this.state.isOpen && (
          <div className="person-overlay-bg">
            <div className="person-overlay">
              <div className="container">
                <TeaserPersonOverlay
                  item={this.props.item}
                  toggleOverlay={this.toggleOverlay}
                />
              </div>
            </div>
          </div>
        )*/}
      </>
    );
  }
}

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonOverview));
