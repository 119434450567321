import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { encodeUrl } from "../../../lib/encode-url";
import FieldTime from "../../date-time/field-time";
import { calculateCollapseHeight } from "../../../lib/calculate-collape-height";
import Presenter from "./components/presenter";
import LookingFor from "./components/looking-for";
import SocialLinks from "./components/social-links";
import FeaturedEvents from "./components/featured-events";
import Credits from "./components/credits";
import BusinessWrapper from "./components/business-wrapper";
import {waitForElm} from "../../../lib/wait-for-elm";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile,
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage
});

class TeaserParticipant extends Component {
  static defaultProps = { pagerFullPage: false };

  teaser = React.createRef();
  teaserImage = React.createRef();
  accordionHeading = React.createRef();
  collapseWrapper = React.createRef();

  constructor(props) {
    super(props);

    let preExpandedItem = null;

    /** Check if the url consists a speaker name to be opened, by checking:
     *  Are we located on a overview page? ("speaker")
     */
    if (
      this.props.item &&
      this.props.location.pathname.includes("speaker") &&
      encodeUrl(this.props.item.title) ===
        this.props.location.pathname.split("/").pop()
    ) {
      preExpandedItem = this.props.item.entityId;
    }

    this.state = {
      preExpandedItem,
      initial: true,
      expandable: true,
      heightSet: false,
      expanded: !!preExpandedItem,
    };
  }

  fixImageHeight = () => {
    if (this.props.isMobile && !this.state.heightSet) {
      waitForElm(".image img", this.teaser.current).then((image) => {
        // no height change on open element
        let imageHeight = this.teaser.current.querySelector(".accordion__heading").clientHeight + 15;

        if (imageHeight < 200) {
          imageHeight = 200;
        }
        image.style.maxHeight = `${imageHeight}px`;
        image.style.height = `${imageHeight}px`;
        this.setState({
          heightSet: true
        })
      });
    }
  };

  updateAccordion = (type) => {
    this.fixImageHeight();
    if (this.props.updateUrl) {
      let scrollPosition =
        this.teaser.current.getBoundingClientRect().top + window.scrollY - 150;

      history.pushState(
        null,
        window.location.pathname,
        `${this.props.currentLanguage === "en" ? `/en/${window.location.pathname.split("/")[2]}` : `/de/${window.location.pathname.split("/")[2]}`}/${encodeUrl(
          this.props.item.title
        )}`
      );

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
    if (type !== "just-scroll") {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  checkIfAccordionNeeded = () => {
    if (this.teaserImage.current && this.accordionHeading.current) {
      if (
        this.teaserImage.current.clientHeight <
        this.accordionHeading.current.clientHeight +
          this.collapseWrapper.current.clientHeight
      ) {
        this.setState({
          expandable: true,
        });
      } else {
        this.setState({
          expandable: false,
        });
      }
    }
  };

  componentDidMount() {
    // Timeout is only set because the "todoerror" below forces a re rendering, can be removed after error is fixed
    setTimeout(() => {
      calculateCollapseHeight(this.teaser.current);
      this.checkIfAccordionNeeded();
      this.fixImageHeight();
    }, 500);

    if (
      this.state.initial &&
      this.props.item &&
      encodeUrl(this.props.item.title) ===
        this.props.location.pathname.split("/").pop()
    ) {
      setTimeout(() => {
        this.updateAccordion("just-scroll");
        this.setState({
          initial: false,
        });
      }, 500);
    }
  }

  render() {
    return (
      <>
        <article
          className={`node node-teaser teaser-speaker programme-element ${
            this.state.expanded ? "expanded" : "closed"
          } ${this.props.item.fieldImage ? "with-image" : "no-image"} ${
            this.state.expandable ? "expandable" : "not-expandable"
          }`}
          ref={this.teaser}
          id={encodeUrl(this.props.item.title)}
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          {/*@todo @dba: Uncaught Error: This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.*/}
          <React.Suspense fallback={<div />}>
            <Accordion
              allowZeroExpanded={true}
              onChange={() => this.updateAccordion()}
              preExpanded={[this.state.preExpandedItem]}
            >
              <AccordionItem uuid={this.props.item.entityId}>
                <div className="container">
                  <div className="row">
                    {/* Image only needed for: Speaker Overview and mobile */}
                    <div
                      className="col-8 col-md-4 col-lg-3 image"
                      ref={this.teaserImage}
                    >
                      {this.props.item.fieldImage && (
                        <LazyLoad offset={1000}>
                          <Image
                            data={
                              this.props.item.fieldImage.entity.fieldMediaImage
                            }
                            nodeTitle={this.props.item.title}
                            styleSmall={true}
                          />
                        </LazyLoad>
                      )}
                    </div>
                    {!this.props.isMobile &&
                      <div className="col-8 offset-8 offset-md-0 col-md-3 featured-events">
                        <FeaturedEvents content={this.props.item} releaseState={this.props.mainSettings.fieldReleaseState}/>
                      </div>
                    }
                    <div className="col-16 col-md-9 col-lg-10 main-infos">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {this.props.isMobile &&
                            <FeaturedEvents content={this.props.item} releaseState={this.props.mainSettings.fieldReleaseState}/>
                          }
                          <div
                            className="title-wrapper"
                            ref={this.accordionHeading}
                          >
                            {this.props.item.fieldLocationName &&
                              this.props.mainSettings.fieldReleaseState ===
                                "all" && (
                                <div className="location">
                                  {this.props.item.fieldLocationName}
                                </div>
                              )}
                            <h2 className="name">{this.props.item.title}</h2>
                            {this.props.item.fieldCountry &&
                              this.props.item.fieldCountry[0] && (
                                <div className="tag country">
                                  {this.props.item.fieldCountry[0]}
                                </div>
                              )}

                            <BusinessWrapper content={this.props.item}/>
                          </div>
                          {this.state.expandable && (
                            <div className="icon-wrapper desktop">
                              <div className="icon plus" />
                            </div>
                          )}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div
                          className="inner-wrapper"
                          ref={this.collapseWrapper}
                        >
                          <div className="info-col">
                            {this.props.item.fieldText && (
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html: this.props.item.fieldText.processed,
                                }}
                              />
                            )}
                            {this.props.item.fieldBiography && (
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.props.item.fieldBiography.processed,
                                }}
                              />
                            )}
                            <Presenter content={this.props.item} />
                            <LookingFor content={this.props.item} />
                            <SocialLinks content={this.props.item} />
                            <Credits content={this.props.item} />
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </div>
                </div>
              </AccordionItem>
            </Accordion>
          </React.Suspense>
        </article>
      </>
    );
  }
}

export const teaserParticipantPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  fieldCountry: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldText: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldGenre: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldCategory: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  fieldImage: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldLocationName: PropTypes.string,
  fieldDate: PropTypes.shape({
    value: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  fieldMainPresenterCompany: PropTypes.string,
  fieldType: PropTypes.string,
  fieldMainPresenterWeb: PropTypes.shape({
    uri: PropTypes.string,
  }),
  fieldDeezer: PropTypes.shape({
    uri: PropTypes.string,
  }),
});

TeaserParticipant.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserParticipantPropTypes,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  updateUrl: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(TeaserParticipant));
