import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";

import { calculateCollapseHeight } from "../../../../lib/calculate-collape-height";
import { encodeUrl } from "../../../../lib/encode-url";

class ParagraphAkkordionText extends Component {

  accordion = React.createRef();

  constructor(props) {
    super(props);

    let preExpandedItem = null;

    this.props.content.fieldAbschnitte.map((item, index) => {
      if ((typeof window !== "undefined") && item.entity && encodeUrl(item.entity.fieldTitel) === window.location.hash.replace('#', '')) {
        preExpandedItem = item.entity.entityId;
      }
    });

    this.state = {
      preExpandedItem,
      initial: true
    };
  }

  updateAccordion = (uuids) => {
    // Check if there are openes elements left, first element defaults to null
    if (uuids[uuids.length - 1] !== null) {
      // Get the Corresponding Element by UUID to get its Title
      this.props.content.fieldAbschnitte.map((item, index) => {
        // Check if UUID matches paragraphs entity id, if yes, push the title to the url
        if (item.entity.entityId === uuids[uuids.length - 1]) {
          // Set the URL Hash
          history.pushState(
            null,
            window.location.pathname,
            `#${encodeUrl(item.entity.fieldTitel)}`
          );
        }
      });
    }
  }

  componentDidMount = () => {
    calculateCollapseHeight(this.accordion.current);

    if (this.state.preExpandedItem && this.state.initial) {
      // Scroll to Element
      if (typeof window !== undefined && window.location.hash) {
        // get Element by extracting id from hash, find matching id in element
        let openedElement = document.getElementById(window.location.hash.replace("#", ""));
        if (openedElement) {
          let scrollPosition = openedElement.getBoundingClientRect().top + window.scrollY - 150;

          window.scrollTo({
            top: scrollPosition
          });
        }
      }
    }

    if (this.props.initial) {
      this.setState({
        initial: false
      })
    }
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-akkordion-text": true,
    });

    return (
      <section className={sectionClassNames} ref={this.accordion} data-darkmode={this.props.content.fieldDarkMode}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <React.Suspense fallback={<div />}>
                <Accordion
                  allowZeroExpanded={true}
                  allowMultipleExpanded={true}
                  preExpanded={[this.state.preExpandedItem]}
                  onChange={(uuids) => this.updateAccordion(uuids)}
                >
                  {this.props.content.fieldAbschnitte.map((item, index) => (
                    <ErrorBoundary key={index}>
                      <AccordionItem
                        id={encodeUrl(item.entity.fieldTitel).toString()}
                        uuid={item.entity.entityId}
                        key={encodeUrl(item.entity.fieldTitel)}
                        data-uuid={item.entity.entityId}
                      >
                        <div className="dashed-border"/>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <h3>{item.entity.fieldTitel}</h3>
                            <div className="icon-wrapper">
                              <div className="icon plus"/>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="collapse-content">
                            <div
                              className="text"
                              dangerouslySetInnerHTML={{
                                __html: item.entity.fieldText.processed,
                              }}
                            />
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </ErrorBoundary>
                  ))}
                </Accordion>
              </React.Suspense>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphAkkordionText;
