import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { encodeUrl } from "../../../lib/encode-url";
import DateWrapper from "./components/date-wrapper";
import TeaserParticipantCollapse from "./teaser-participant-collapse";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile,
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class TeaserParticipant extends Component {
  static defaultProps = { pagerFullPage: false };

  teaser = React.createRef();

  constructor(props) {
    super(props);

    let preExpandedItem = null;

    /* Check if the url consists an artist name to be opened, by checking:
     *  Are we located on a overview page? ("festival", "conference", "konferenz")
     */

    if (
      this.props.item &&
      (this.props.location.pathname.includes("festival") ||
        this.props.location.pathname.includes("artists")) &&
      encodeUrl(this.props.item.title) ===
        this.props.location.pathname.split("/").pop()
    ) {
      preExpandedItem = this.props.item.entityId;
    }

    this.state = {
      preExpandedItem,
      initial: true,
    };
  }

  updateAccordion = () => {
    if (this.props.updateUrl) {
      let scrollPosition =
        this.teaser.current.getBoundingClientRect().top +
        window.scrollY -
        (this.props.isMobile ? 120 : window.innerHeight * 0.1);

      history.pushState(
        null,
        window.location.pathname,
        `${
          this.props.currentLanguage === "en"
            ? `/en/${window.location.pathname.split("/")[2]}`
            : `/de/${window.location.pathname.split("/")[2]}`
        }/${encodeUrl(this.props.item.title)}`
      );

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  componentDidMount() {
    if (
      this.state.initial &&
      this.props.item &&
      encodeUrl(this.props.item.title) ===
        this.props.location.pathname.split("/").pop()
    ) {
      setTimeout(() => {
        this.updateAccordion();
        this.setState({
          initial: false,
        });
      }, 500);
    }
  }

  render() {
    return (
      <>
        <article
          className={`node node-teaser teaser-participant programme-element ${
            this.props.item.fieldImage ? "with-image" : "no-image"
          }`}
          ref={this.teaser}
          id={encodeUrl(this.props.item.title)}
          data-to-scrollspy-id={encodeUrl(this.props.item.title)}
          data-teaser-index={this.props.index}
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          {/*@todo @dba: Uncaught Error: This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.*/}
          <React.Suspense fallback={<div />}>
            <Accordion
              allowZeroExpanded={true}
              onChange={() => this.updateAccordion()}
              preExpanded={[this.state.preExpandedItem]}
            >
              <AccordionItem uuid={this.props.item.entityId}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="row">
                      {/* Image only needed for: Speaker Overview and mobile */}
                      {this.props.isMobile && (
                        <div className="col-8 image">
                          {this.props.item.fieldImage?.entity
                            ?.fieldMediaImage && (
                            <LazyLoad offset={1000}>
                              <Image
                                data={
                                  this.props.item.fieldImage.entity
                                    .fieldMediaImage
                                }
                                nodeTitle={this.props.item.title}
                              />
                            </LazyLoad>
                          )}
                        </div>
                      )}
                      <div className="col-8 col-lg-16 infos">
                        <div className="row">
                          {this.props.overviewType === "programme-overview" && (
                            <div className="col-16 col-lg-4 date-col">
                              <DateWrapper
                                content={this.props.item}
                                releaseState={
                                  this.props.mainSettings.fieldReleaseState
                                }
                              />
                            </div>
                          )}
                          <div className="col-16 col-lg-12 info-col">
                            {this.props.item.fieldLocationName && (
                              <div className="meta-infos">
                                {this.props.item.fieldLocationName &&
                                  this.props.mainSettings.fieldReleaseState ===
                                    "all" && (
                                    <div className="location">
                                      {this.props.item.fieldLocationName}
                                    </div>
                                  )}
                                {/*this.props.item.fieldDuplicates && this.props.item.fieldDuplicates.length > 0 && this.props.item.fieldDuplicates[0] && this.props.item.fieldDuplicates[0].entity.fieldDate &&
                                <div className="more-dates-info">
                                  <FormattedMessage id={"event.multipleDates"}/>
                                </div>
                              */}
                              </div>
                            )}
                            <h2 className="name">{this.props.item.title}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <LazyLoad offset={2000} unmountIfInvisible={true}>
                    <TeaserParticipantCollapse
                      item={this.props.item}
                      mainTeaserRef={this.teaser.current}
                      dayFilterEnabled={this.props.dayFilterEnabled}
                    />
                  </LazyLoad>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </React.Suspense>
        </article>
      </>
    );
  }
}

export const teaserParticipantPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  fieldCountry: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldText: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldGenre: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldCategory: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  fieldImage: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldLocationName: PropTypes.string,
  fieldDate: PropTypes.shape({
    value: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  fieldMainPresenterCompany: PropTypes.string,
  fieldType: PropTypes.string,
  fieldMainPresenterWeb: PropTypes.shape({
    uri: PropTypes.string,
  }),
  fieldDeezer: PropTypes.shape({
    uri: PropTypes.string,
  }),
});

TeaserParticipant.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserParticipantPropTypes,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  updateUrl: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(TeaserParticipant));
