import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ParagraphExtendedTeaserOverview from "./../paragraph-extended-teaser-overview";


const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  hideSort: reduxStore.appStore.hideSort,
  hideFilter: reduxStore.appStore.hideFilter
});

class LocationWrapper extends Component {

  render() {
    return (
      <section className="paragraph location-wrapper">
        <ParagraphExtendedTeaserOverview
          content={this.props.content}
          filterCloudLabels={
            this.props.content.fieldFilteroptions &&
            this.props.content.fieldFilteroptions.map(
              (item) => item && item.entity && item.entity.name
            )
          }
          filterCloudFilters={
            this.props.content.fieldFilteroptions &&
            this.props.content.fieldFilteroptions.map(
              (item) => item && item.entity && item.entity.fieldMachineName
            )
          }
          filterCloudFilterSingle={true}
          filterCloudMultipleFilterCondition={"OR"}
          filterCloudPreventEmptyResult={false}
          filterDisplayType={"top"}
        />
      </section>
    );
  }
}

LocationWrapper.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "location",
      "veranstaltung",
    ]),
    fieldFilteroptions: PropTypes.arrayOf(PropTypes.object),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  mainSettingsConfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(LocationWrapper);