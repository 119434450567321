import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { connect } from "react-redux";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import TeaserParticipant, {
  teaserParticipantPropTypes,
} from "../../../../teaser-base/participant/teaser-participant";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ComponentTeaserlistCarousel extends React.Component {
  state = {
    slideIndex: 0,
  };

  slider = React.createRef();

  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  /*componentDidUpdate(prevProps) {
    if (
      (JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)) &&
      this.props.nodesConfig !== "Manuell"
    ) {
      this.pushPagerFullPageConfig();
    }
  }*/

  render() {
    if (
      (!this.props.nodes || this.props.nodes.loading) &&
      !this.props.manualNodes
    ) {
      return false;
    }

    const sliderSettings = {
      adaptiveHeight: false,
      arrows: true,
      centerMode: false,
      dots: false,
      infinite: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      beforeChange: (oldIndex, newIndex) => {
        setTimeout(() => {
          this.setState({ slideIndex: newIndex });
        }, 10);
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1.25,
            touchMove: true,
          },
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 1.66,
            touchMove: true,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1.25,
            touchMove: true,
          },
        },
      ],
    };

    return (
      <div ref={this.slider}>
        {this.props.nodesConfig === "Manuell" ||
        (this.props.nodesConfig !== "Manuell" && this.props.nodes.nodeQuery) ? (
          <>
            {this.props.nodesConfig === "Manuell" ? (
              <Slider {...sliderSettings}>
                {this.props.manualNodes.map((item, index) => ( {
                  if (item) {
                    return (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (item.entity.entityBundle) {
                            case "news":
                              return (
                                <TeaserNews
                                  item={item.entity}
                                  pagerFullPage={this.props.pagerFullPage}
                                  activeBefore={
                                    this.state.slideIndex !== 0 &&
                                    this.state.slideIndex - 1 === index
                                  }
                                />
                              );
                            case "participant":
                              return (
                                <TeaserParticipant
                                  item={item}
                                  pagerFullPage={this.props.pagerFullPage}
                                  activeBefore={
                                    this.state.slideIndex !== 0 &&
                                    this.state.slideIndex - 1 === index
                                  }
                                />
                              );
                            case "person":
                              return (
                                <TeaserPerson
                                  item={item.entity}
                                  pagerFullPage={this.props.pagerFullPage}
                                  activeBefore={
                                    this.state.slideIndex !== 0 &&
                                    this.state.slideIndex - 1 === index
                                  }
                                />
                              );
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    );
                  }
                }))}
              </Slider>
            ) : (
              <>
                {this.props.nodes.nodeQuery &&
                this.props.nodes.nodeQuery.entities.length > 0 ? (
                  <>
                    <Slider {...sliderSettings}>
                      {this.props.nodes.nodeQuery.entities.map((item, index) => {
                          if (item) {
                            return(
                              <React.Fragment key={index}>
                                {(() => {
                                  if (!item) {
                                    return null;
                                  }

                                  switch (item.entityBundle) {
                                    case "news":
                                      return (
                                        <TeaserNews
                                          item={item}
                                          pagerFullPage={this.props.pagerFullPage}
                                          activeBefore={
                                            this.state.slideIndex !== 0 &&
                                            this.state.slideIndex - 1 === index
                                          }
                                        />
                                      );
                                    case "participant":
                                      return (
                                        <TeaserParticipant
                                          item={item}
                                          pagerFullPage={this.props.pagerFullPage}
                                          activeBefore={
                                            this.state.slideIndex !== 0 &&
                                            this.state.slideIndex - 1 === index
                                          }
                                        />
                                      );
                                    case "person":
                                      return (
                                        <TeaserPerson
                                          item={item}
                                          pagerFullPage={this.props.pagerFullPage}
                                          activeBefore={
                                            this.state.slideIndex !== 0 &&
                                            this.state.slideIndex - 1 === index
                                          }
                                        />
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </React.Fragment>
                            );
                          }
                        }
                      )}
                    </Slider>
                  </>
                ) : (
                  <LoadingIndicator />
                )}
              </>
            )}
          </>
        ) : null}
      </div>
    );
  }
}

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserParticipantPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf([
    "news",
    "person",
    "veranstaltung",
    "all",
    "participant",
  ]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags.map((item) => item.targetId.toString()),
        sortField: "published_at.value",
        sortDirection: "DESC",
        filterTagEnabled: props.tags.length > 0,
        participantTypeFilter: props.participantTypeFilter,
        participantTypeFilterEnabled:
          props.participantTypeFilter && props.participantTypeFilter !== null,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(ComponentTeaserlistCarousel)
);
