import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classNames from "classnames";
import {drupalFormat} from "../../config";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage
});


class FieldTime extends Component {

  render() {
    //moment.tz.add('Europe/Berlin|CET CEST CEMT|-10 -20 -30');
    moment.tz.setDefault('Europe/Berlin');

    moment.locale(this.props.currentLanguage);

    const date = this.props.timestamp
      ? this.props.date
      : this.props.date.replace(" UTC", "").trim().replace(" ", "T");

    return (
      <time
        dateTime={
          this.props.timestamp
            ? moment.unix(date, drupalFormat).format("YYYY-MM-DD")
            : this.props.date.replace(" UTC", "Z").replace(" ", "T")
        }
      >
        <span
          className={classNames({
            "d-none d-md-block": typeof this.props.mobileFormat !== "undefined",
          })}
        >
          {this.props.timestamp ? (
            <>{moment.unix(date, drupalFormat).format(this.props.format)}</>
          ) : (
            <>{moment(date, drupalFormat).format(this.props.format)}</>
          )}
        </span>
        {this.props.mobileFormat && (
          <span className="d-block d-md-none">
            {this.props.timestamp ? (
              <>{moment.unix(date, drupalFormat).format(this.props.mobileFormat)}</>
            ) : (
              <>{moment(date, drupalFormat).format(this.props.mobileFormat)}</>
            )}
          </span>
        )}
      </time>
    );
  }
}

FieldTime.propTypes = {
  /**
   * Use dateField.value as date prop.
   */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * The format used by moment.format().
   */
  format: PropTypes.string.isRequired,
  /**
   * Optional format for mobile display only.
   */
  mobileFormat: PropTypes.string,
  /**
   * Interpret date as timestamp or date string?
   */
  timestamp: PropTypes.bool,
};

export default connect(mapStateToProps)(FieldTime);
