import React from "react";

const SocialMedia = ({ content }) => {
  return(
    <div className="social-media">
      <div className="headline">
        <h3>Socials</h3>
      </div>
      {content.fieldFacebook && (
        <a
          className="facebook"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldFacebook.url.path}
        >
          Facebook
        </a>
      )}
      {content.fieldYoutube && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldYoutube.url.path}
        >
          YouTube
        </a>
      )}
      {content.fieldFlickr && (
        <a
          className="flickr"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldFlickr.url.path}
        >
          Flickr
        </a>
      )}
      {content.fieldInstagram && (
        <a
          className="instagram"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldInstagram.url.path}
        >
          Instagram
        </a>
      )}
      {content.fieldVimeo && (
        <a
          className="vimeo"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldVimeo.url.path}
        >
          Vimeo
        </a>
      )}
      {content.fieldLinkedin && (
        <a
          className="linkedin"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldLinkedin.url.path}
        >
          LinkedIn
        </a>
      )}
      {content.fieldPinterest && (
        <a
          className="pinterest"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldPinterest.url.path}
        >
          Pinterest
        </a>
      )}
      {content.fieldTwitter && (
        <a
          className="x"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldTwitter.url.path}
        >
          X
        </a>
      )}
      {content.fieldXing && (
        <a
          className="xing"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldXing.url.path}
        >
          Xing
        </a>
      )}
      {content.fieldTumblr && (
        <a
          className="tumblr"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldTumblr.url.path}
        >
          Tumblr
        </a>
      )}
      {content.fieldDeezer && (
        <a
          className="deezer"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldDeezer.url.path}
        >
          Deezer
        </a>
      )}
      {content.fieldSpotify && (
        <a
          className="spotify"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldSpotify.url.path}
        >
          Spotify
        </a>
      )}
      {content.fieldTiktok && (
        <a
          className="tiktok"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldTiktok.url.path}
        >
          TikTok
        </a>
      )}
    </div>
  );
};

export default SocialMedia;