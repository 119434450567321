const changeActiveImage = (element, ref) => {
  if (element && element.parentElement) {
    let allTeasers = element.parentElement.querySelectorAll('.programme-element, .location-element');
    let activeId = [...allTeasers].indexOf(element);

    if (element.parentElement && allTeasers) {
      allTeasers.forEach((item) => {
        item.classList.remove('active-scroll-spy');
      });
      element.classList.add("active-scroll-spy");
    }
    let offset = window.innerHeight * 0.1;

    // offset for first element
    if (activeId === 0) {
      offset = 0;
    }
    if (activeId === (allTeasers.length - 1)) {
      offset = window.innerHeight * 0.3;
    }

    ref.style.setProperty("--active-image-transition", `${offset + (window.innerHeight * 0.8) * activeId * -1}px`);
  }
};

export { changeActiveImage };
