const i18nReducerDefaultState = {
    currentLanguage: 'de',
    currentContentGermanTranslation: false,
    currentContentEnglishTranslation: false,
  },
  i18nReducer = (state = i18nReducerDefaultState, action) => {
    switch (action.type) {
      case 'I18N': {
        return Object.assign({}, state, {
          currentLanguage: action.currentLanguage,
          currentContentGermanTranslation: action.currentContentGermanTranslation,
          currentContentEnglishTranslation: action.currentContentEnglishTranslation
        });
      }
      default:
        return state;
    }
  };

export { i18nReducerDefaultState, i18nReducer };