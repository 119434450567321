import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";
import LanguageSwitch from "../i18n/language-switch";
import AccessibilityMenu from "./accessibility-menu";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import FieldTime from "../general-components/date-time/field-time";
import Link from "../general-components/link/link";

const mapStateToProps = (reduxStore) => ({
  menuTitle: reduxStore.appStore.menuTitle,
  currentLanguage: reduxStore.i18n.currentLanguage
});

class SimpleHeader extends Component {
  render() {
    return (
      <div className={"fixed-wrapper"}>
        {this.props.mainSettingsConfig.fieldMenuCta && (this.props.location.pathname === "/" || this.props.location.pathname === "/en" || this.props.location.pathname === "/de/startseite" || this.props.location.pathname === "/en/frontpage") && !this.props.isMobile &&
          <div className="menu-cta">
            <div className="inner-wrapper header-element">
              <div className="border-element"/>
              <div className="effect-element"/>
              <Link
                className="learn-more"
                link={this.props.mainSettingsConfig.fieldMenuCta}
              />
            </div>
          </div>
        }
        <div className="header-element" language={this.props.currentLanguage}>
          <div className="container">
            <div className="border-element" />
            <div className="row">
              <LogoButton/>
              <div className="title-language-wrapper">
                <div className="spin-wrapper">
                  {/* Display Node Title in Menu, if not frontpage. Title is dispatched in different Node Detail Pages */}
                  {this.props.menuTitle && this.props.location.pathname !== "/" ? (
                    <h1 className="main-title">
                      {this.props.menuTitle}
                    </h1>
                  ) : (
                    <div className="title-and-date">
                      <div className="spin-wrapper-inside">
                        <h1 className="main-title">
                          Reeperbahn Festival
                        </h1>
                        <div className="festival-date">
                          {this.props.mainSettingsConfig && this.props.mainSettingsConfig.fieldFestivalTime &&
                            <>
                              <FieldTime
                                date={this.props.mainSettingsConfig.fieldFestivalTime.startTime}
                                timestamp={false}
                                format={"DD.-"}
                              />
                              <FieldTime
                                date={this.props.mainSettingsConfig.fieldFestivalTime.endTime}
                                timestamp={false}
                                format={"DD.MM.YYYY"}
                              />
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                  <LanguageSwitch />
                </div>
              </div>
              <div className="button-wrapper">
                {this.props.currentLanguage !== "en" &&
                  <AccessibilityMenu />
                }
                <SimpleNavigation
                  mainSettingsConfig={this.props.mainSettingsConfig}
                />
              </div>
            </div>
            <div className="effect-element" />
          </div>
        </div>
      </div>
    );
  }
}

SimpleHeader.propTypes = {
  mainSettingsConfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(SimpleHeader));
