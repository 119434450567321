import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { self } from "../../../../config";

const SocialLinks = (props) => {
  if (
    (props.content.fieldDeezer && props.content.fieldDeezer.uri) ||
    props.content.fieldYoutube ||
    props.content.fieldFacebook ||
    props.content.fieldInstagram ||
    props.content.fieldTwitter ||
    props.content.fieldSoundcloud ||
    props.content.fieldWebsite ||
    props.content.fieldVimeo
  ) {
    return (
      <>
        {props.content.fieldDeezer && props.content.fieldDeezer.uri && (
          <a
            className={"deezer-link"}
            href={props.content.fieldDeezer.uri}
            target={"_blank"}
            rel="noreferrer"
          >
            <img
              src={`${self}/ui/play_white.svg`}
              className="ui-icon play"
              alt="Play on deezer"
            />
            <FormattedMessage id="deezer.listen" />
          </a>
        )}
        {(props.content.fieldYoutube ||
          props.content.fieldFacebook ||
          props.content.fieldInstagram ||
          props.content.fieldTwitter ||
          props.content.fieldSoundcloud ||
          props.content.fieldWebsite ||
          props.content.fieldVimeo) && (
          <div className="social-links">
            <div className="icon-wrapper">
              <img
                src={`${self}/icon-links.svg`}
                className="icon links"
                alt="Links"
              />
            </div>
            <div className="link-wrapper">
              {props.content.fieldWebsite && props.content.fieldWebsite.uri && (
                <a
                  className={"link website"}
                  href={props.content.fieldWebsite.uri}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {props.content.fieldWebsite.uri
                    .replace("https://", "")
                    .replace("http://", "")}
                </a>
              )}
              {props.content.fieldFacebook && props.content.fieldFacebook.uri && (
                <a
                  className={"link facebook"}
                  href={props.content.fieldFacebook.uri}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Facebook
                </a>
              )}
              {props.content.fieldInstagram &&
                props.content.fieldInstagram.uri && (
                  <a
                    className={"link facebook"}
                    href={props.content.fieldInstagram.uri}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                )}
              {props.content.fieldTwitter && props.content.fieldTwitter.uri && (
                <a
                  className={"link twitter"}
                  href={props.content.fieldTwitter.uri}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  X
                </a>
              )}
              {props.content.fieldSoundcloud &&
                props.content.fieldSoundcloud.uri && (
                  <a
                    className={"link soundcloud"}
                    href={props.content.fieldSoundcloud.uri}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Soundcloud
                  </a>
                )}
              {props.content.fieldYoutube && props.content.fieldYoutube.uri && (
                <a
                  className={"link youtube"}
                  href={props.content.fieldYoutube.uri}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  YouTube
                </a>
              )}
              {props.content.fieldVimeo && props.content.fieldVimeo.uri && (
                <a
                  className={"link vimeo"}
                  href={props.content.fieldVimeo.uri}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Vimeo
                </a>
              )}
            </div>
          </div>
        )}
      </>
    );
  } else {
    return false;
  }
};

SocialLinks.propTypes = {
  content: PropTypes.object.isRequired,
};

export default SocialLinks;
