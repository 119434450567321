import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import { self } from "../../../config";
import { teaserParticipantPropTypes } from "./teaser-participant";
import {encodeUrl} from "../../../lib/encode-url"

import { Link as ReactRouterLink } from "react-router-dom";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  currentLanguage: reduxStore.i18n.currentLanguage
});

class TeaserParticipantMasked extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {

    // link to programme/festival page
    let overviewPageLink = "festival";

    // link to speaker programme page if it is a speaker
    if (this.props.item.fieldParticipantType && this.props.item.fieldParticipantType[0] && this.props.item.fieldParticipantType[0] === "Speaker") {
      overviewPageLink = "speakers-conference";
    }

    return (
      <article className="node node-teaser teaser-participant masked">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="mask-wrapper">
          <ReactRouterLink to={`/${this.props.currentLanguage}/${overviewPageLink}/${encodeUrl(this.props.item.title)}`}>
            {this.props.item.fieldImage && (
              <>
                <div className="image">
                  <Image
                    data={this.props.item.fieldImage.entity.fieldMediaImage}
                    nodeTitle={this.props.item.title}
                    styleSmall={true}
                  />
                  <img
                    src={`${self}/masks/mask-${(this.props.index % 5) + 1}.svg`}
                    alt="Color masked image"
                    className="color-element"
                  />
                </div>
              </>
            )}
            <div className="hover-infos">
              {this.props.item.title && (
                <h2 className="name">{this.props.item.title}</h2>
              )}
            </div>
          </ReactRouterLink>
        </div>
      </article>
    );
  }
}

TeaserParticipantMasked.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserParticipantPropTypes,
  index: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserParticipantMasked));
