import React, { Component } from "react";
import PropTypes from "prop-types";

import ListItem from "./list-item";
import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";

class ListView extends Component {
  render() {
    return (
      <section id="list-section">
        <div className="container-fluid">
          <div className="row">
            {this.props.entities.map((item, index) => {
              if (!item) {
                return null;
              }

              return <ListItem key={index} index={index} content={item} />;
            })}
          </div>
        </div>
      </section>
    );
  }
}

ListView.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape(ContentCloudNodePropTypes)),
};

export default ListView;
