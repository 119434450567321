import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import LoadingIndicator from "../../../loading-indicator";
import { FormattedMessage } from "react-intl";

class ParagraphNewsletteranmeldung extends Component {
  state = {
    error: false,
    errorStatus: "",
    loading: false,
    success: false,
    successMsg: "",
  };

  onSubmit = (values, actions) => {
    this.setState({ loading: true });

    let channel = this.props.content.fieldNewsletterChannel.entity;

    // uncomment to test success state
    /*
    this.setState({
      error: false,
      errorStatus: false,
      loading: false,
      success: true,
    });
    return;
    */

    axios
      .post(
        channel.fieldApiEndpoint,
        {
          email: values.email,
          privacy_policy_accepted: true,
          _unsubscribed: false,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${channel.fieldApiToken}`,
          },
        }
      )
      .catch((error) => {
        this.setState({
          error: true,
          errorStatus: error.response.status,
          loading: false,
          success: false,
        });

        actions.setSubmitting(false);
      })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          this.setState({
            error: false,
            errorStatus: false,
            loading: false,
            success: true,
          });
          actions.setSubmitting(false);
          actions.resetForm();
        } else {
          this.setState({
            error: true,
            errorStatus: 406,
            loading: false,
            success: false,
          });
          actions.setSubmitting(false);
        }
      });
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-newsletter-register": true,
      [`color-${this.props.content.fieldColorScheme}`]: true,
    });

    return (
      <section
        className={sectionClassNames}
        data-darkmode={this.props.content.fieldDarkMode}
      >
        {this.state.loading && <LoadingIndicator />}

        <div className="container">
          <div className="row">
            {!this.state.success ? (
              <>
                <div className="col-16 heading">
                  {this.props.content.fieldHeading && (
                    <h2>{this.props.content.fieldHeading}</h2>
                  )}
                </div>
                <div className="col-16 form-part">
                  <Formik
                    onSubmit={(values, actions) => {
                      this.onSubmit(values, actions);
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      email: "",
                      consent: false,
                    }}
                    validationSchema={Yup.lazy(() =>
                      Yup.object().shape({
                        email: Yup.string()
                          .email()
                          .required("E-Mail is required"),
                        consent: Yup.bool().oneOf(
                          [true],
                          "Sie müssen den Bedingungen zustimmen."
                        ),
                      })
                    )}
                  >
                    {({ errors, touched }) => (
                      <Form noValidate>
                        <div className="form-row">
                          <div className="col-16 form-group">
                            <label htmlFor="email">Ihre E-Mail-Adresse</label>
                            <div className="input-wrapper">
                              <Field
                                aria-label="Ihre E-Mail-Adresse"
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="E-Mail"
                              />
                              <button
                                type="submit"
                                className="submit-newsletter"
                                aria-label={"subscribe newsletter"}
                              >
                                <FormattedMessage id="subscribe" />
                              </button>
                            </div>
                            {errors.email && touched.email ? (
                              <div
                                className="form-error text-danger"
                                role="region"
                                aria-live="polite"
                              >
                                :-/ {errors.email}
                              </div>
                            ) : null}
                            {/*this.state.error &&
                              this.state.errorStatus !== 406 &&
                              this.state.errorStatus !== "invalid-mail" && (
                                <div
                                  className="form-error text-danger"
                                  role="region"
                                  aria-live="polite"
                                >
                                  :-/ <FormattedMessage id="error.general" />
                                </div>
                              )}
                            {this.state.error &&
                              this.state.errorStatus !== "invalid-mail" && (
                                <div
                                  className="form-error text-danger"
                                  role="region"
                                  aria-live="polite"
                                >
                                  :-/{" "}
                                  <FormattedMessage id="email.invalidMail" />
                                </div>
                              )*/}

                            {this.state.error && (
                                <div
                                  className="form-error text-danger"
                                  role="region"
                                  aria-live="polite"
                                >
                                  :-/ <FormattedMessage id="email.duplicate" />
                                </div>
                              )}

                            {/*this.state.error &&
                              this.state.errorStatus === "invalid-mail" && (
                                <div
                                  className="form-error text-danger"
                                  role="region"
                                  aria-live="polite"
                                >
                                  :-/ <FormattedMessage id="email.invalid" />
                                </div>
                              )*/}
                          </div>

                          <div className="col-16 form-check">
                            <Field
                              type="checkbox"
                              name="consent"
                              id="consent"
                              aria-labelledby="form-check-label"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="consent"
                            >
                              <FormattedMessage id="email.dsgvo">
                                {(msg) => (<div dangerouslySetInnerHTML={{__html: msg}} />)}
                              </FormattedMessage>
                            </label>
                          </div>
                          {errors.consent && touched.consent ? (
                            <span
                              className="form-error text-danger"
                              role="region"
                              aria-live="polite"
                            >
                              :-/ {errors.consent}
                            </span>
                          ) : null}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            ) : (
              <>
                <div className="col-16 heading">
                  {this.props.content.fieldHeading && (
                    <h2>
                      <FormattedMessage id="email.success" />
                    </h2>
                  )}
                </div>
                <div className="col-16">
                  <div className="messages">
                    {this.state.success && (
                      <div
                        className="alert alert-success"
                        role="region"
                        aria-live="polite"
                      >
                        {/*<FormattedMessage id="email.success" />*/}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphNewsletteranmeldung.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }),
};

export default ParagraphNewsletteranmeldung;
