import React, { Component } from "react";
import PropTypes from "prop-types";
import { Close32 } from "@carbon/icons-react";

import Image from "../../image/image";
import { teaserPersonPropTypes } from "./teaser-person";

class TeaserPersonOverlay extends Component {
  render() {
    return (
      <article className="node node-teaser teaser-person teaser-overlay">
        <div className="row">
          <div className="col-16 col-md-7 offset-md-1">
            <Image
              data={this.props.item.fieldBildWCaption.entity.fieldMediaImage}
              nodeTitle={this.props.item.title}
              credit={this.props.item.fieldBildWCaption.entity.fieldCredit}
              caption={
                this.props.item.fieldBildWCaption.defaultCaption === false
                  ? this.props.item.fieldBildWCaption.customCaption
                  : null
              }
            />
          </div>
          <div className="col-16 col-md-8">
            {this.props.toggleOverlay && (
              <div className="close" onClick={() => this.props.toggleOverlay()}>
                <Close32 />
              </div>
            )}

            <h1>{this.props.item.title}</h1>

            {this.props.item.fieldPosition &&
              <div className="role">
                <span className="top-line">
                  {this.props.item.fieldPosition}
                </span>
              </div>
            }

            {this.props.item.fieldBeschreibung && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldBeschreibung.processed,
                }}
              />
            )}

            <div className="row infos">
              <div className="col-8">
                {this.props.item.fieldMail && (
                  <div className="label margin-bottom">
                    <span>Mail</span>
                  </div>
                )}
                {this.props.item.fieldMobil && (
                  <div className="label">
                    <span>Mobil</span>
                  </div>
                )}
                {this.props.item.fieldTelefon && (
                  <div className="label margin-bottom">
                    <span>Telefon</span>
                  </div>
                )}
              </div>
              <div className="col-8">
                {this.props.item.fieldMail && (
                  <div className="value margin-bottom">
                    <a href={`mailto:${this.props.item.fieldMail}`}>
                      {this.props.item.fieldMail}
                    </a>
                  </div>
                )}
                {this.props.item.fieldMobil && (
                  <div className="value">
                    <a
                      className="mobil"
                      href={`tel:${this.props.item.fieldMobil
                        .replace(/ /g, "")
                        .replace(/\([\s\S]*?\)/g, "")}`}
                    >
                      {this.props.item.fieldMobil}
                    </a>
                  </div>
                )}
                {this.props.item.fieldTelefon && (
                  <div className="value margin-bottom">
                    <a
                      className="phone"
                      href={`tel:${this.props.item.fieldTelefon
                        .replace(/ /g, "")
                        .replace(/\([\s\S]*?\)/g, "")}`}
                    >
                      {this.props.item.fieldTelefon}
                    </a>
                  </div>
                )}
              </div>
            </div>

            {this.props.item.fieldKontakinformationen.length > 0 && (
              <div className="contact-infos infos">
                {this.props.item.fieldKontakinformationen.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-8">
                      <div className="label">
                        {item.entity.fieldContactType}
                      </div>
                    </div>
                    <div className="col-8">
                      <div
                        className="value"
                        dangerouslySetInnerHTML={{
                          __html: item.entity.fieldWert.processed,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </article>
    );
  }
}

TeaserPersonOverlay.propTypes = {
  item: teaserPersonPropTypes,
  toggleOverlay: PropTypes.func,
};

export default TeaserPersonOverlay;
