import React, { Component } from "react";
import PropTypes from "prop-types";
import { teaserParticipantPropTypes } from "../../../../teaser-base/participant/teaser-participant";
import TeaserParticipantMasked from "../../../../teaser-base/participant/teaser-participant-masked";

/**
 * A module representing a text paragraph.
 */
class BreakBanner extends Component {
  render() {
    return (
      <div className={`break-banner ${this.props.type}`} aria-disabled={"true"}>
        <div className="container highlights">
          {this.props.type === "conference" &&
            this.props.content.fieldHeading && (
              <h2 className="break-headline">
                {this.props.content.fieldHeading}
              </h2>
            )}
          <div className="highlighted-participants">
            {this.props.content.fieldSelectedParticipants &&
              this.props.content.fieldSelectedParticipants.map(
                (participant, index) => (
                  <React.Fragment key={index}>
                    <TeaserParticipantMasked
                      item={participant.entity}
                      index={index}
                    />
                  </React.Fragment>
                )
              )}
          </div>
        </div>
      </div>
    );
  }
}

BreakBanner.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldSelectedParticipants: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserParticipantPropTypes,
      })
    ),
  }).isRequired,
  type: PropTypes.oneOf(["conference", "break"]).isRequired,
};

export default BreakBanner;
