import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import { getValueFromStringObjPath } from "../webform-elements";
import {self} from "../../../../../config";

const TextAreaField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );
  const defaultRows = 4;

  const value = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? getValueFromStringObjPath(values, compositeParent)[compositeIndex][
          item.id
        ]
      : getValueFromStringObjPath(values, compositeParent)[item.id]
    : values[item.id];
  const identifier = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={identifier}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      <div className={"textarea-wrapper"}>
        <Field
          id={identifier}
          name={identifier}
          rows={item.rows === null ? defaultRows : item.rows}
          placeholder={item.placeholder != null ? `${item.placeholder}${item.required || required ? '*' : ''}` : ""}
          cols="50"
          as={item.type}
          className="form-control"
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          required={(!!item.required || required) && !optional && visible}
          disabled={!enabled || disabled}
          maxLength={item.maxLength ? item.maxLength : null}
          minLength={item.minLength ? item.minLength : null}
        />
        <img src={`${self}/ui/resize-handler.svg`} className="custom-resize-handler" alt={"resize-handler"}/>
      </div>
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

TextAreaField.propTypes = {
  item: PropTypes.shape({
    rows: PropTypes.number,
    type: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    states: PropTypes.array,
    description: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default TextAreaField;
