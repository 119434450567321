import React from "react";
import ErrorBoundary from "../../../../error-boundary";

import { encodeUrl } from "../../../../lib/encode-url";
import { Link as ReactRouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DateWrapper from "../../participant/components/date-wrapper";
import {useSelector} from "react-redux";

const LinkedActs = (props) => {
  const reduxStore = useSelector((state) => ({
    currentLanguage: state.i18n.currentLanguage
  }));

  let linkedActs = props.content.fieldArtistsRef && props.content.fieldArtistsRef.filter(
    (event) => event.entity && event.entity.status === true
  );
  if (props.content.fieldArtistsRef && linkedActs && linkedActs.length > 0) {
    return (
      <ErrorBoundary>
        <div className="linked-acts">
          <div className="label">
            <FormattedMessage id={"acts"} />
          </div>
          {linkedActs.map((item, index) => (
            <article className="linked-act" key={index}>
              <ReactRouterLink
                to={`/${reduxStore.currentLanguage}/festival/${encodeUrl(item.entity.entityLabel)}`}
              >
                <div className="act-name">{item.entity.entityLabel}{linkedActs.length > 1 && index !== (linkedActs.length - 1) ? `,` : ``}</div>
              </ReactRouterLink>
            </article>
          ))}
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default LinkedActs;
