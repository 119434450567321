import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import ErrorBoundary from "../../error-boundary";
import Image from "../../general-components/image/image";
import { FormattedMessage } from "react-intl";

const PartnerLogo = ({ content }) => {
  return (
    <div className="partner">
      {content.customCaption ? (
        <div className="headline">{content.customCaption}</div>
      ) : (
        <>
          {content.entity.fieldLabel ? (
            <div className="headline">{content.entity.fieldLabel}</div>
          ) : (
            <div className="headline placeholder" />
          )}
        </>
      )}
      {content.entity.fieldMediaImage1 && (
        <Image
          data={content.entity.fieldMediaImage1}
          nodeTitle={content.entity.fieldLabel}
          credit={content.entity.fieldMediaImage1.entity.entity?.fieldCredit}
        />
      )}
    </div>
  );
};

const FooterPartners = ({ content }) => {
  if (content) {
    return (
      <ErrorBoundary>
        <div className="partners">
          <div className="row">
            <div className="col-16">
              <h3>
                <FormattedMessage id="supported" />
              </h3>
            </div>
          </div>
          <div className="row">
            {content.map((item, index) => (
              <div className="col-8" key={index}>
                {item.entity.fieldLink ? (
                  <a
                    href={item.entity.fieldLink.url.path}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <PartnerLogo content={item} />
                  </a>
                ) : (
                  <PartnerLogo content={item} />
                )}
              </div>
            ))}
          </div>
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default FooterPartners;
