import React from "react";
import { FormattedMessage } from "react-intl";
import Image from "../../../image/image";
import LazyLoad from "react-lazyload";

const Sponsors = (props) => {
  let presenters = [],
    coop = [];

  if (props.content.fieldMainPresenterCompany) {
    presenters.push(props.content);
  }
  {/* Show Presentators of other plays as well */}
  if (props.content.fieldDuplicates && !props.dayFilterEnabled) {
    props.content.fieldDuplicates.map((duplicate, index) => {
      if (duplicate && duplicate.entity) {
        // Do not show duplicated logos
        if (duplicate.entity.fieldMainPresenterCompany && (duplicate.entity.fieldMainPresenterCompany !== props.content.fieldMainPresenterCompany)) {
          presenters.push(duplicate.entity);
        }
        if (duplicate.entity.fieldCoopLogo && (duplicate.entity.fieldCoopLogo && duplicate.entity.fieldCoopLogo !== props.content.fieldCoopLogo)) {
          coop.push(duplicate.entity);
        }
        if (duplicate.entity.fieldCoopLogo2 && (duplicate.entity.fieldCoopLogo2 && duplicate.entity.fieldCoopLogo2 !== props.content.fieldCoopLogo2 && duplicate.entity.fieldCoopLogo !== props.content.fieldCoopLogo2)) {
          coop.push(duplicate.entity);
        }
      }
    });
  }

  if (
    props.content.fieldCoopCompany ||
    props.content.fieldMainPresenterCompany
  ) {
    return (
      <div className="sponsor-wrapper">
        <div className="all-sponsors">
          <div className="label">
            <FormattedMessage id="cooperation" />
          </div>
          {presenters && presenters.length > 0 && presenters.map((presenter, index) =>
            <div className="sponsor main" key={index}>
              <div className={`sponsor-image`}>
                <a href={presenter.fieldMainPresenterWeb?.uri} target="_blank">
                  {presenter.fieldMainPresenterLogo &&
                    <>
                      <span className="sr-only">{presenter.fieldMainPresenterCompany}</span>
                      <LazyLoad>
                        <Image
                          data={
                            presenter.fieldMainPresenterLogo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={presenter.fieldMainPresenterCompany}
                        />
                      </LazyLoad>
                    </>
                  }
                </a>
              </div>
            </div>
          )}
          {/*
          @todo: needs to be finished
          coop && coop.length > 0 && coop.map((coop, index) =>
            <div className="sponsor main" key={index}>
              {coop.fieldCoopLogo &&
                <div className={`sponsor-image`}>
                  <a href={coop.fieldCoopWeb?.uri} target="_blank">
                    {coop.fieldCoopLogo &&
                      <LazyLoad>
                        <Image
                          data={
                            coop.fieldCoopLogo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={coop.fieldMainPresenterCompany}
                        />
                      </LazyLoad>
                    }
                  </a>
                </div>
              }
              {coop.fieldCoopLogo2 &&
                <div className={`sponsor-image`}>
                  <a href={coop.fieldCoopWeb2?.uri} target="_blank">
                    {coop.fieldCoopLogo2 &&
                      <LazyLoad>
                        <Image
                          data={
                            coop.fieldCoopLogo2.entity
                              .fieldMediaImage
                          }
                          nodeTitle={coop.fieldMainPresenterCompany}
                        />
                      </LazyLoad>
                    }
                  </a>
                </div>
              }
            </div>
          )*/}
          {props.content.fieldCoopLogo && (
            <div className="sponsor main">
              <div className={`sponsor-image`}>
                <a href={props.content.fieldCoopWeb?.uri} target="_blank">
                  <LazyLoad>
                    <Image
                      data={props.content.fieldCoopLogo.entity.fieldMediaImage}
                      nodeTitle={props.content.fieldCoopCompany}
                    />
                  </LazyLoad>
                </a>
              </div>
            </div>
          )}
          {props.content.fieldCoopLogo2 && (
            <div className="sponsor main">
              <div className={`sponsor-image`}>
                <a href={props.content.fieldCoopWeb2?.uri} target="_blank">
                  <LazyLoad>
                    <Image
                      data={props.content.fieldCoopLogo2.entity.fieldMediaImage}
                      nodeTitle={props.content.fieldCoopCompany2}
                    />
                  </LazyLoad>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export default Sponsors;
