import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class ParagraphNumberFacts extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-number-facts": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col d-flex align-items-center justify-content-center">
              <div className="number-fact main">
                <div className="number">
                  {this.props.content.fieldZahlenUndFakten[0].entity.fieldZahl}
                </div>
                <div className="label">
                  {this.props.content.fieldZahlenUndFakten[0].entity.fieldLabel}
                </div>
              </div>
            </div>
            <div className="d-block d-md-none w-100" />
            {this.props.content.fieldZahlenUndFakten.length > 1 && (
              <>
                <div className="col col-md-8 small">
                  {this.props.content.fieldZahlenUndFakten
                    .slice(1, this.props.content.fieldZahlenUndFakten.length)
                    .map((item, index) => (
                      <div
                        className="number-fact d-table d-md-block"
                        key={index}
                      >
                        <div className="number">{item.entity.fieldZahl}</div>
                        <div className="label">{item.entity.fieldLabel}</div>
                      </div>
                    ))}
                </div>
                <div className="d-block d-md-none w-100" />
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphNumberFacts.propTypes = {
  content: PropTypes.shape({
    fieldZahlenUndFakten: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldZahl: PropTypes.string.isRequired,
          fieldLabel: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
};

export default ParagraphNumberFacts;
