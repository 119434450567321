import React, { Component } from "react";
import PropTypes from "prop-types";
import shuffle from "lodash/shuffle";

import StoryTeaser from "./story-teaser";
import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";

class StoryContainer extends Component {
  state = {
    entities: [],
  };

  shuffle = () => {
    const shuffledEntities = shuffle(this.props.entities);

    this.setState(
      {
        entities: shuffledEntities,
      },
      () => this.props.scaleStoryElements()
    );
  };

  componentDidMount() {
    this.shuffle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entities.length !== this.props.entities.length) {
      this.shuffle();
    }
  }

  render() {
    return (
      <div className="row cloud-wrapper">
        {this.state.entities.map((item, index) => {
          if (item != null) {
            return (
              <StoryTeaser
                key={JSON.stringify(item)}
                content={item}
                allContent={this.state.entities}
                index={index}
              />
            );
          }
        })}
      </div>
    );
  }
}

StoryContainer.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape(ContentCloudNodePropTypes)),
  scaleStoryElements: PropTypes.func.isRequired,
};

export default StoryContainer;
