import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Use this component for rendering images queried from Drupal.
 */
class Image extends Component {
  render() {
    const blurStyle = {
      filter: this.props.loading ? "blur(25px)" : "blur(0px)",
      transition: "125ms filter"
    };

    let { alt, title } = this.props.data;

    if (this.props.data.entity.filemime === "image/svg+xml") {
      return (
        <>
          <img
            className={this.props.className}
            src={this.props.src ? this.props.src : this.props.data.url}
            title={title}
            alt={alt}
          />
          {(this.props.caption ||
            this.props.credit ||
            this.props.globalCaption) && (
            <div
              className={classNames({
                "caption-wrap": true,
                "col-16": this.props.wrapCaption,
              })}
            >
              {this.props.caption && (
                <span className="caption">{this.props.caption}</span>
              )}
              {this.props.globalCaption && !this.props.caption && (
                <span className="caption">{this.props.globalCaption}</span>
              )}
              {this.props.credit && (
                <span className="credit">© {this.props.credit}</span>
              )}
            </div>
          )}
        </>
      );
    } else {
      if (!alt && this.props.caption) {
        alt = this.props.caption;
      } else if (!alt && this.props.nodeTitle) {
        alt = this.props.nodeTitle;
      }

      if (!title && this.props.caption) {
        title = this.props.caption;
      } else if (!title && this.props.nodeTitle) {
        title = this.props.nodeTitle;
      }

      if (this.props.fullPage) {
        return (
          <div className={"image-caption-wrapper"}>
            {this.props.credit && (
              <span className="credit">© {this.props.credit}</span>
            )}
            <img
              className={this.props.className}
              src={
                this.props.src
                  ? this.props.src
                  : this.props.data.styleFullPage.url
              }
              style={blurStyle}
              title={title}
              alt={alt}
            />
          </div>
        );
      }

      if (this.props.styleSmall) {
        return (
          <>
            <img
              className={this.props.className}
              src={
                this.props.src ? this.props.src : this.props.data.styleSmall.url
              }
              style={blurStyle}
              title={title}
              alt={alt}
            />
          </>
        );
      }

      if (this.props.data.mobile && this.props.data.desktop) {
        return [
          <img
            key="mobile-image"
            className={`${this.props.className} mobile-image d-block d-md-none`}
            src={this.props.src ? this.props.src : this.props.data.mobile.url}
            title={title}
            style={blurStyle}
            alt={alt}
          />,
          <img
            key="desktop-image"
            className={`${this.props.className} desktop-image d-none d-md-block`}
            src={this.props.src ? this.props.src : this.props.data.desktop.url}
            title={title}
            style={blurStyle}
            alt={alt}
          />,
        ];
      }

      return (
        <div className="image-caption-wrapper">
          <img
            className={this.props.className}
            src={this.props.src ? this.props.src : this.props.data.style.url}
            title={title}
            style={blurStyle}
            alt={alt}
          />
          {(this.props.caption ||
            this.props.credit ||
            this.props.globalCaption) && (
            <div
              className={classNames({
                "caption-wrap": true,
                "col-16": this.props.wrapCaption,
              })}
            >
              {this.props.caption && (
                <span className="caption">{this.props.caption}</span>
              )}
              {this.props.globalCaption && !this.props.caption && (
                <span className="caption">{this.props.globalCaption}</span>
              )}
              {this.props.credit && (
                <span className="credit">© {this.props.credit}</span>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

Image.propTypes = {
  /**
   * The image caption.
   */
  caption: PropTypes.string,
  /**
   * Used as fallback alt="" value.
   */
  nodeTitle: PropTypes.string,
  /**
   * The image credit.
   */
  credit: PropTypes.string,
  data: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    styleFullPage: PropTypes.shape({
      url: PropTypes.string,
    }),
    desktop: PropTypes.shape({
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  /**
   * Custom class names to add to the component.
   */
  className: PropTypes.string,
  /**
   * Only provided if used inside an image gallery component.
   */
  globalCaption: PropTypes.string,
  /**
   * In fullpage-mode, image and caption are swapped.
   */
  fullPage: PropTypes.bool,
  /**
   * Wrap caption?
   */
  wrapCaption: PropTypes.bool,
};

export default Image;
