import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ComponentTeaserlistCarouselMasked from "./../components/component-teaserlist-carousel-masked";
import Link from "../../../../link/link";
import ErrorBoundary from "../../../../../error-boundary";
import { teaserParticipantPropTypes } from "../../../../teaser-base/participant/teaser-participant";
import ProgrammeOverview from "../../programme-overview/programme-overview";
import SpeakerOverview from "./../../extended-teaser-list/participants/speaker-overview";

/**
 * This is the component for the "Eierlegende Wollmilchsau" paragraph.
 * Try not not to use this! This is just for reference.
 *
 * Usage: Copy to
 * src/js/general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */
class ParagraphTeaserlistParticipants extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-list participants": true,
      "paragraph paragraph-teaser-list-slick": this.props.content.fieldKarussel,
    });

    let manualNodes = this.props.content.fieldSelectedParticipants;

    return (
      <section
        className={sectionClassNames}
        data-darkmode={
          this.props.content.fieldKarussel && this.props.content.fieldDarkMode
        }
      >
        {this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton.title && (
            <div className="more-button">
              <div className="button-wrapper">
                <div className="border-element"/>
                <div className="effect-element" />
                <ErrorBoundary>
                  <Link
                    link={this.props.content.fieldMehrMeldungenButton}
                    className="btn btn-primary more"
                  />
                </ErrorBoundary>
              </div>
            </div>
          )}
        {this.props.content.fieldHeading && (
          <div className="container heading">
            <div className="row">
              <div className="col-16">
                <h2>{this.props.content.fieldHeading}</h2>
                {/*this.props.content.fieldFilterInfo && (
                  <div className="filter-info">
                    {this.props.content.fieldFilterInfo}
                  </div>
                )*/}
              </div>
            </div>
          </div>
        )}

        <ErrorBoundary>
          {this.props.content.fieldKarussel ? (
            <ComponentTeaserlistCarouselMasked
              manualNodes={manualNodes}
              id={this.props.content.entityId}
              nodesConfig={this.props.content.fieldAutmatischOderManuell}
              count={
                this.props.content.fieldAnzahlDerAnzuzeigendenI
                  ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                  : 100
              }
              type={"participant"}
              participantTypeFilter={this.props.content.fieldParticipantType}
              pagerFullPage={
                this.props.content.fieldPagerAufVollseiten
                  ? this.props.content.entityId
                  : false
              }
              pagerFullPageOverviewLink={
                this.props.content.fieldLinkZurUebersicht
              }
              tags={this.props.content.fieldSchlagwort}
            />
          ) : (
            <>
              {this.props.content.fieldParticipantType === "speaker" ? (
                <SpeakerOverview
                  manualNodes={manualNodes}
                  participantTypeFilter={
                    this.props.content.fieldParticipantType
                  }
                  skipQuery={
                    this.props.content.fieldAutmatischOderManuell === "Manuell"
                  }
                  showBanners={false}
                  updateUrl={false}
                  type={this.props.content.fieldParticipantType}
                  mainSettings={this.props.mainSettings}
                  tags={this.props.content.fieldSchlagwort}
                />
              ) : (
                <ProgrammeOverview
                  type={"participant"}
                  participantTypeFilter={
                    this.props.content.fieldParticipantType
                  }
                  manualNodes={manualNodes}
                  skipQuery={
                    this.props.content.fieldAutmatischOderManuell === "Manuell"
                  }
                  nodesConfig={this.props.content.fieldAutmatischOderManuell}
                  id={this.props.content.entityId}
                  tags={this.props.content.fieldSchlagwort}
                  showBanners={false}
                  updateUrl={false}
                  displayType={"list"}
                  clickBehavior={"link"}
                />
              )}
            </>
          )}
        </ErrorBoundary>
      </section>
    );
  }
}

ParagraphTeaserlistParticipants.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldKarussel: PropTypes.bool,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
    fieldParticipantType: PropTypes.oneOf([
      "all",
      "act",
      "speaker",
      "moderator",
      "organizer",
      "participant",
    ]),
    fieldHeading: PropTypes.string,
    fieldFilterInfo: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldSelectedParticipants: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserParticipantPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserlistParticipants;
