import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a hero-text paragraph.
 *
 * @todo In CS we have a field "fieldHintWithColouredBackgro" but we do not use it?
 */
class ParagraphHeroText extends Component {
  render() {
    /*
     *  IMPORTANT: Relies on Taxonomy terms to be flexible. Namings are:
     * Colors: red, purple
     * Sizes: standard, smaller, smallest
     * */
    const sectionClassNames = classNames({
      "paragraph paragraph-hero-text": true,
      [this.props.content.fieldColor &&
      this.props.content.fieldColor.entity.name.toLowerCase()]:
        this.props.content.fieldColor,
      [this.props.content.fieldSize &&
      this.props.content.fieldSize.entity.name.toLowerCase()]:
        this.props.content.fieldSize,
    });

    return (
      <section
        className={sectionClassNames}
        data-darkmode={this.props.content.fieldDarkMode}
      >
        <div className="container">
          <div className="row">
            <div className="col-16">
              <div
                className="hero text"
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fieldText.processed,
                }}
              />
            </div>
          </div>
        </div>

        {this.props.content.fieldHint && (
          <div className="hint text">
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldHint.processed,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldColor: PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
    fieldSize: PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
    fieldDarkMode: PropTypes.bool,
  }).isRequired,
};

export default ParagraphHeroText;
