import moment from "moment";

import { i18nAction } from './i18n-actions';

const i18nActionHelper = (props, dispatch) => {
  let currentLanguage = props.match.params.language === 'en' ? props.match.params.language : 'de';

  // Edge case: english frontpage /en has only one path segment, so path = en,
  //  language = undefined.
  if (props.match.params.alias === 'en') {
    currentLanguage = 'en';
  }

  moment.locale(currentLanguage);

  const translationDE = props.data.route && props.data.route.entity && props.data.route.entity.translationDE ? props.data.route.entity.translationDE.entityUrl.path : false,
    translationEN = props.data.route && props.data.route.entity && props.data.route.entity.translationEN ? props.data.route.entity.translationEN.entityUrl.path : false;

  dispatch(i18nAction(currentLanguage, translationDE, translationEN));
};

export { i18nActionHelper };