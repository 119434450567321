import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import EditButton from "../../../backend/edit-button";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { self } from "../../../config";
import ErrorBoundary from "../../../error-boundary";
import { encodeUrl } from "../../../lib/encode-url";
import { calculateCollapseHeight } from "../../../lib/calculate-collape-height";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import ParticipatingSpeakers from "./components/participating-speakers";
import HostedBy from "./components/hosted-by";
import EventPresenter from "./components/event-presenter";
import DateWrapper from "../participant/components/date-wrapper";
import LinkedEvents from "./components/linked-events";
import LinkedActs from "./components/linked-acts";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile,
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/**
 * @todo use "FieldTime"
 */
class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  teaser = React.createRef();

  constructor(props) {
    super(props);

    let preExpandedItem = null;

    if (
      this.props.item &&
      (this.props.location.pathname.includes("conference") ||
        this.props.location.pathname.includes("konferenz")) &&
      encodeUrl(this.props.item.title) ===
        this.props.location.pathname.split("/").pop()
    ) {
      preExpandedItem = this.props.item.entityId;
    }

    this.state = {
      preExpandedItem,
      initial: true,
      isOpened: preExpandedItem !== null,
    };
  }

  updateAccordion = () => {
    calculateCollapseHeight(this.teaser.current);
    this.setState({
      isOpened: !this.state.isOpened,
    });

    if (this.props.updateUrl) {
      let scrollPosition =
        this.teaser.current.getBoundingClientRect().top + window.scrollY - 150;

      // Set Hash value based on title
      history.pushState(
        null,
        window.location.pathname,
        `/${
          this.props.currentLanguage === "en" ? `en/conference` : `de/konferenz`
        }/${encodeUrl(this.props.item.title)}`
      );

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  componentDidMount() {
    // Timeout is only set because the "todoerror" below forces a re rendering, can be removed after error is fixed
    setTimeout(() => {
      calculateCollapseHeight(this.teaser.current);
    }, 500);

    if (
      this.state.initial &&
      this.props.item &&
      encodeUrl(this.props.item.title) ===
        this.props.location.pathname.split("/").pop()
    ) {
      setTimeout(() => {
        this.updateAccordion();
        this.setState({
          initial: false,
          isOpened: true,
        });
      }, 500);
    }
  }

  render() {
    return (
      <article
        className={`node node-${
          this.props.item.entityId
        } node-teaser teaser-event ${
          this.props.item.fieldImage ? "with-image" : "no-image"
        }
        ${this.state.isOpened ? "opened" : "closed"}`}
        ref={this.teaser}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <Accordion
          allowZeroExpanded={true}
          onChange={() => this.updateAccordion()}
          preExpanded={[this.state.preExpandedItem]}
        >
          <AccordionItem uuid={this.props.item.entityId}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row">
                  {this.props.item.fieldImage && (
                    <div className="col-8 col-md-3 col-lg-4 image">
                      <Image
                        data={this.props.item.fieldImage.entity.fieldMediaImage}
                        nodeTitle={this.props.item.title}
                      />
                    </div>
                  )}
                  {!this.props.isMobile && (
                    <div
                      className={`${
                        this.props.item.fieldImage ? "col-8" : "col-16"
                      } col-md-3 col-lg-2 date`}
                    >
                      {this.props.item.fieldDate && (
                        <ErrorBoundary>
                          <DateWrapper
                            content={this.props.item}
                            releaseState={
                              this.props.mainSettings.fieldReleaseState
                            }
                          />
                        </ErrorBoundary>
                      )}
                    </div>
                  )}
                  <div
                    className={`${
                      this.props.item.fieldImage
                        ? "col-8 col-md-10 col-lg-9"
                        : "col-16 col-md-13 col-lg-10"
                    } main-infos`}
                  >
                    {this.props.isMobile && (
                      <>
                        {this.props.item.fieldDate && (
                          <ErrorBoundary>
                            <DateWrapper
                              content={this.props.item}
                              releaseState={
                                this.props.mainSettings.fieldReleaseState
                              }
                            />
                          </ErrorBoundary>
                        )}
                      </>
                    )}
                    <div className="meta-infos">
                      {this.props.item.fieldEventType && (
                        <div className="type">
                          {this.props.item.fieldEventType}
                        </div>
                      )}
                      {this.props.item.fieldLocationName &&
                        this.props.mainSettings.fieldReleaseState === "all" &&
                        !this.props.isMobile && (
                          <div className="location">
                            {this.props.item.fieldLocationName}
                          </div>
                        )}
                    </div>
                    <Hyphenated language={de}>
                      <h2 className="name">{this.props.item.title}</h2>
                      {this.props.item.fieldSubline &&
                        <h3 className="subline">{this.props.item.fieldSubline}</h3>
                      }
                    </Hyphenated>
                    {this.props.item.fieldCategory &&
                      this.props.item.fieldCategory.length > 0 &&
                      this.props.item.fieldCategory[0].entity !== null && (
                        <span className="top-line">
                          {this.props.item.fieldCategory[0].entity.name}
                        </span>
                      )}
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="inner-wrapper">
                <div className="container">
                  <div className="row">
                    <div
                      className={`col-16 ${
                        this.props.item.fieldImage
                          ? "col-md-10 offset-md-6 col-lg-9"
                          : "col-md-13 offset-md-2 col-lg-10"
                      }`}
                    >
                      {(this.props.item.fieldCategory ||
                        this.props.item.fieldGenre ||
                        this.props.item.fieldCountry) && (
                        <div className="tag-wrapper">
                          {this.props.item.fieldCategory &&
                            this.props.item.fieldCategory.entity && (
                              <div className="tag category">
                                {this.props.item.fieldCategory.entity.name}
                              </div>
                            )}
                          {this.props.item.fieldGenre &&
                            this.props.item.fieldGenre[0] &&
                            this.props.item.fieldGenre[0].entity && (
                              <div className="tag genre">
                                {this.props.item.fieldGenre[0].entity.name}
                              </div>
                            )}
                          {this.props.item.fieldCountry &&
                            this.props.item.fieldCountry[0] && (
                              <div className="tag country">
                                {this.props.item.fieldCountry[0]}
                              </div>
                            )}
                        </div>
                      )}
                      {this.props.item.fieldMainPresenterCompany && (
                        <div className="presented-by">
                          <span className="label">Presented by </span>
                          {this.props.item.fieldMainPresenterWeb ? (
                            <>
                              {this.props.item.fieldMainPresenterWeb.uri && (
                                <a
                                  href={
                                    this.props.item.fieldMainPresenterWeb.uri
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.props.item.fieldMainPresenterCompany}
                                </a>
                              )}
                            </>
                          ) : (
                            <span>
                              {this.props.item.fieldMainPresenterCompany}
                            </span>
                          )}
                        </div>
                      )}
                      {this.props.item.fieldInvitationOnly && (
                        <div className="invitation-only">
                          invitation only
                        </div>
                      )}
                      {this.props.item.fieldText && (
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: this.props.item.fieldText.processed,
                          }}
                        />
                      )}
                      {this.props.item.fieldLanguage && (
                        <div className="language">
                          <span className="label">
                            <FormattedMessage id={"language"} />
                          </span>
                          <span className="language-name">
                            {this.props.item.fieldLanguage}
                          </span>
                        </div>
                      )}
                      <ParticipatingSpeakers content={this.props.item} />
                      <HostedBy content={this.props.item} />
                      <LinkedActs content={this.props.item} />
                      <LinkedEvents
                        content={this.props.item}
                        releaseState={
                          this.props.mainSettings.fieldReleaseState
                        }
                      />
                      <EventPresenter content={this.props.item} />
                      {this.props.item.fieldDeezer &&
                        this.props.item.fieldDeezer.uri && (
                          <a
                            className={"deezer-link"}
                            href={this.props.item.fieldDeezer.uri}
                          >
                            <img
                              src={`${self}/ui/play_white.svg`}
                              className="ui-icon play"
                            />{" "}
                            Listen on deezer
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </article>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldImage: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDatum: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
    endTime: PropTypes.string,
    startTime: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTicketlink: PropTypes.shape({
    url: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
  fieldCategory: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  fieldGenre: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldCountry: PropTypes.arrayOf(PropTypes.string),
  fieldMainPresenterCompany: PropTypes.string,
  fieldMainPresenterWeb: PropTypes.shape({
    uri: PropTypes.string,
  }),
  fieldDeezer: PropTypes.shape({
    uri: PropTypes.string,
  }),
  fieldEventType: PropTypes.string,
  fieldLocationName: PropTypes.string,
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  updateUrl: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
