import React from "react";
import ErrorBoundary from "../../../../error-boundary";

import { encodeUrl } from "../../../../lib/encode-url";
import { Link as ReactRouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const HostedBy = (props) => {
  if (props.content.fieldModerator1Name || props.content.fieldModerator2Name) {
    return (
      <ErrorBoundary>
        <div className="hosted-by">
          <div className="label">
            <FormattedMessage id={"presentation"} />
          </div>
          {props.content.fieldModerator1Name &&
            <div className="host">
              <div className="host-name">
                {props.content.fieldModerator1Name}
              </div>
              {props.content.fieldModerator1Role && (
                <div className="role">{props.content.fieldModerator1Role}</div>
              )}
              {props.content.fieldModerator1Company && (
                <div className="company"> / {props.content.fieldModerator1Company}</div>
              )}
            </div>
          }
          {props.content.fieldModerator2Name &&
            <div className="host">
              <div className="host-name">
                {props.content.fieldModerator2Name}
              </div>
              {props.content.fieldModerator2Role && (
                <div className="role">{props.content.fieldModerator2Role}</div>
              )}
              {props.content.fieldModerator2Company && (
                <div className="company"> / {props.content.fieldModerator2Company}</div>
              )}
            </div>
          }
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default HostedBy;
