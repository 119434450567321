import React from "react";
import ErrorBoundary from "../../../../error-boundary";
import DateWrapper from "./date-wrapper";
import { FormattedMessage } from "react-intl";

const MoreDates = (props) => {
  /* Todo: @FHE Rework Logic [0] ist not a stable variable to check */
  if (
    props.content.fieldDuplicates &&
    props.content.fieldDuplicates[0] &&
    props.content.fieldDuplicates[0].entity?.fieldDate
  ) {
    return (
      <ErrorBoundary>
        <div className="multiple-dates">
          <div className="info-headline">
            <FormattedMessage id={"event.multipleDates"} />
          </div>
          {props.content.fieldDuplicates.map((item, index) => (
            <article className="other-date" key={index}>
              <DateWrapper content={item.entity} showTBA={true} releaseState={props.releaseState} />
              {item.entity.fieldLocationName &&
                props.releaseState ===
                "all" && (
                  <div className="location">
                    {item.entity.fieldLocationName}
                  </div>
                )}
            </article>
          ))}
          <article className="other-date">
            <DateWrapper content={props.content} showTBA={true} releaseState={props.releaseState} />
            {props.content.fieldLocationName &&
              props.releaseState ===
              "all" && (
                <div className="location">
                  {props.content.fieldLocationName}
                </div>
              )}
          </article>
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default MoreDates;
