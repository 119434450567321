import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import Landingpage from "./landingpage/landingpage";
import News from "./news/news";
import Event from "./event/event";
import Person from "./person/person";
import Act from "./act/act";
import NotFound from "./general-components/not-found";
import footerConfigQuery from "footer-config-query.graphql";
import mainSettingsConfigQuery from "./main-settings-config-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import {
  mainSettingsAction,
  isMobileAction,
  mainMenuOpenAction,
} from "./app-actions";

import smoothscroll from "smoothscroll-polyfill";
import LoadingIndicator from "./general-components/loading-indicator";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  updateDimensions = () => {
    if (window.innerWidth >= 1024) {
      this.props.dispatch(isMobileAction(false));
    } else {
      this.props.dispatch(isMobileAction(true));
    }
  };

  componentDidMount() {
    // kick off the scroll polyfill!
    smoothscroll.polyfill();

    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
    this.props.dispatch(
      mainSettingsAction(this.props.mainSettingsConfig.configPagesById)
    );
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    document.body.style.setProperty("--app-height", `${window.innerHeight}px`);
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
      this.props.dispatch(mainMenuOpenAction(false));
    }
    if (
      this.props.reduxStore.appStore.mainMenuOpen ||
      this.props.reduxStore.appStore.accessibilityMenuOpen
    ) {
      document.body.classList.add("fullscreen-menu-open");
    } else {
      document.body.classList.remove("fullscreen-menu-open");
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.reduxStore.i18n.currentLanguage}
        defaultLocale="de"
        messages={messages[this.props.reduxStore.i18n.currentLanguage]}
      >
        <div className="main-content">
          {this.props.mainSettingsConfig.configPagesById &&
            !this.props.mainSettingsConfig.loading && (
              <header id="site-header">
                <ErrorBoundary>
                  <SimpleHeader
                    mainSettingsConfig={
                      this.props.mainSettingsConfig.configPagesById
                    }
                    location={this.props.location}
                  />
                </ErrorBoundary>
              </header>
            )}

          <main className="main-page-content">
            <ErrorBoundary>
              <Switch
                location={this.props.location}
                key={this.props.location.pathname}
              >
                <Route
                  exact
                  path="/:language?/news/:alias"
                  component={News}
                />
                <Route
                  exact
                  path="/:language?/veranstaltung/:alias"
                  component={Event}
                />
                <Route
                  exact
                  path="/:language?/person/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/:language?/act/:alias"
                  component={Act}
                />
                {/* Needed to display The Festival and Conference Programme and copy the existing Link structure: "/festival/artist-name" without using detail pages */}
                <Route
                  exact
                  path="/:language?/festival/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/artists/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/conference/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/konferenz/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/speakers-conference/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/speakers-festival/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/node/:nodeId/revisions/:revisionId/view"
                  component={NodeRevision}
                />
                <Route exact path="/" component={Landingpage} />
                <Route component={NotFound} />
              </Switch>
            </ErrorBoundary>
          </main>

          <footer id="pageFooter">
            <ErrorBoundary>
              <PageFooter
                footerConfig={this.props.footerConfig.configPagesQuery}
                accesibilityMenu={this.props.accessibilityMenuQuery.menuByName}
              />
            </ErrorBoundary>
          </footer>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  accessibilityMenuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
  mainSettingsConfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(footerConfigQuery, {
      name: "footerConfig",
    }),
    graphql(mainSettingsConfigQuery, {
      name: "mainSettingsConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "accessibility-menu",
          language: props.reduxStore.i18n.currentLanguage
            ? props.reduxStore.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "accessibilityMenuQuery",
    })
  )(withRouter(App))
);
