import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNews extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article
        className={`${
          this.props.activeBefore ? "active-before" : ""
        } node node-teaser teaser-news`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="flex-wrap">
          {this.props.item.fieldTeaserbild?.entity?.fieldMediaImage && (
            <div
              className={`image-wrapper ${
                this.props.item.fieldTeaserbild.entity.fieldMediaImage.style
                  .width >
                this.props.item.fieldTeaserbild.entity.fieldMediaImage.style
                  .height
                  ? "horizontal"
                  : "vertical"
              }`}
            >
              <div className="caption-positioner">
                <Image
                  data={this.props.item.fieldTeaserbild.entity.fieldMediaImage}
                  nodeTitle={this.props.item.title}
                  credit={this.props.item.fieldTeaserbild.entity.fieldCredit}
                />
              </div>
            </div>
          )}
          <Link
            tabIndex={0}
            role={"button"}
            aria-label={"open page"}
            onClick={() =>
              this.props.dispatch(
                pagerFullPageContextAction(this.props.pagerFullPage)
              )
            }
            to={
              this.props.item.entityUrl
                ? this.props.item.entityUrl.path
                : this.props.item.path.alias
            }
          >
            <div className="news-info">
              <div className="meta">
                <div className="published-on">
                  {this.props.item.publishedAt && (
                    <FieldTime
                      timestamp={true}
                      date={
                        this.props.item.publishedAt.value
                          ? this.props.item.publishedAt.value
                          : this.props.item.publishedAt.publishedAtOrNow
                      }
                      format={"D MMMM"}
                    />
                  )}
                </div>
                <div className="tags">
                  {this.props.item.fieldSchlagwort.length >= 1 &&
                    this.props.item.fieldSchlagwort.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.entity !== null && (
                          <span className="tag">[{item.entity.name}]</span>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
              <div className="text-content">
                <h2>{this.props.item.title}</h2>

                {this.props.item.fieldTeasertext && (
                  <div
                    className="teaser text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.item.fieldTeasertext.processed,
                    }}
                  />
                )}
              </div>
            </div>
            <span className="read-more">learn more</span>
          </Link>
        </div>
      </article>
    );
  }
}

export const teaserNewsPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      }),
      fieldCredit: PropTypes.string,
    }),
  }),
  fieldSponsored: PropTypes.bool,
});

TeaserNews.propTypes = {
  activeBefore: PropTypes.bool,
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  active: PropTypes.bool,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNews));
