const calculateCollapseHeight = (ref) => {
  if (ref) {
    let items = ref.querySelectorAll(".accordion__panel");

    [...items].map((item) => {
      // get height of first collapse celement child and set the height of the collapse element
      item.style.setProperty('--height', `${item.firstChild.clientHeight}px`);
    });
  }
};

export { calculateCollapseHeight };
