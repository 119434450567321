import * as THREE from 'three';
import { waitForElm } from "./../../../../lib/wait-for-elm"

class Sketch {
  constructor(opts) {
    this.scene = new THREE.Scene();
    this.vertex = `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}`;
    this.fragment = opts.fragment;
    this.uniforms = opts.uniforms;
    this.renderer = new THREE.WebGLRenderer();
    this.width = opts.width;
    this.height = opts.height;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0xeeeeee, 1);
    this.delay = opts.delay || 0;
    this.duration = opts.duration || 1;
    this.debug = opts.debug || false
    this.easing = opts.easing || 'easeInOut'

    waitForElm(".slick-arrow.slick-next", opts.wrapper.closest(".paragraph-teaser-slider")).then((elm) => {
      this.clicker = elm;
      this.clickEvent();
    });

    this.container = document.getElementById(opts.id);
    this.images = JSON.parse(this.container.getAttribute('data-images'));
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );

    this.camera.position.set(0, 0, 2);
    this.time = 0;
    this.current = 0;
    this.textures = [];

    this.paused = true;
    this.userHasInteracted = false;

    this.initiate(()=>{
      this.setupResize();
      this.settings();
      this.addObjects();
      this.resize();
      this.play();
    })



  }

  initiate(cb){
    const promises = [];
    let that = this;
    this.images.forEach((url,i)=>{
      let promise = new Promise(resolve => {
        that.textures[i] = new THREE.TextureLoader().load( url, resolve );
      });
      promises.push(promise);
    })

    Promise.all(promises).then(() => {
      cb();
    });
  }

  clickEvent(){
    this.clicker.addEventListener('click',()=>{
      this.userHasInteracted = true;
      this.next();
    })
  }
  settings() {
    let that = this;
    if(this.debug) this.gui = new dat.GUI();
    this.settings = {progress:0.5};
    // if(this.debug) this.gui.add(this.settings, "progress", 0, 1, 0.01);


    Object.keys(this.uniforms).forEach((item)=> {
      this.settings[item] = this.uniforms[item].value;
      if(this.debug) this.gui.add(this.settings, item, this.uniforms[item].min, this.uniforms[item].max, 0.01);
    })
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;


    // image cover
    this.setResolution(this.textures[0], this.material.uniforms.resolution1);
    this.setResolution(this.textures[1], this.material.uniforms.resolution2);

    const dist  = this.camera.position.z;
    const height = 1;
    this.camera.fov = 2*(180/Math.PI)*Math.atan(height/(2*dist));

    this.plane.scale.x = this.camera.aspect;
    this.plane.scale.y = 1;

    this.camera.updateProjectionMatrix();

  }

  setResolution(texture, resolution) {

    this.imageAspect = texture.image.height/texture.image.width;
    let a1; let a2;
    if(this.height/this.width>this.imageAspect) {
      a1 = (this.width/this.height) * this.imageAspect ;
      a2 = 1;
    } else{
      a1 = 1;
      a2 = (this.height/this.width) / this.imageAspect;
    }

    resolution.value.x = this.width;
    resolution.value.y = this.height;
    resolution.value.z = a1;
    resolution.value.w = a2;

  }

  addObjects() {
    let that = this;
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        progress: { type: "f", value: 0 },
        border: { type: "f", value: 0 },
        intensity: { type: "f", value: 0 },
        scaleX: { type: "f", value: 40 },
        scaleY: { type: "f", value: 40 },
        transition: { type: "f", value: 40 },
        swipe: { type: "f", value: 0 },
        width: { type: "f", value: 0 },
        radius: { type: "f", value: 0 },
        texture1: { type: "f", value: this.textures[0] },
        texture2: { type: "f", value: this.textures[1] },
        // displacement: { type: "f", value: new THREE.TextureLoader().load('img/disp1.jpg') },
        resolution1: { type: "v4", value: new THREE.Vector4() },
        resolution2: { type: "v4", value: new THREE.Vector4() },
      },
      // wireframe: true,
      vertexShader: this.vertex,
      fragmentShader: this.fragment
    });

    this.geometry = new THREE.PlaneGeometry(1, 1, 2, 2);

    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.plane);
  }

  stop() {
    this.paused = true;
  }

  play() {
    this.paused = false;
    this.render();
    //this.autoNext();
  }

  next(){
    if(this.isRunning) return;
    this.isRunning = true;
    let len = this.textures.length;
    const next = (this.current+1)%len;
    let nextTexture = this.textures[next];
    this.material.uniforms.texture2.value = nextTexture;
    this.material.uniforms.texture2.value = nextTexture;
    this.material.uniforms.texture2.value = nextTexture;
    this.material.uniforms.displacement = { type: "f", value: nextTexture };
    let tl = new TimelineMax();
    tl.to(this.material.uniforms.progress,this.duration,{
      value:1,
      // ease: Power2[this.easing],
      ease: "back.inOut(1.7)",
      onComplete:()=>{

        this.current = (this.current +1)%len;
        this.material.uniforms.texture1.value = nextTexture;
        this.material.uniforms.progress.value = 0;
        this.isRunning = false;

        this.setResolution(nextTexture, this.material.uniforms.resolution1);
        const tempNext = (this.current+1)%len;
        this.setResolution(this.textures[tempNext], this.material.uniforms.resolution2);

        //this.autoNext();
      }})
  }

  autoNext() {
    if (this.delay > 0 && !this.userHasInteracted) {
      setTimeout(() => this.next(), this.delay * 1000);
    }
  }

  render() {
    if (this.paused) return;
    this.time += 0.05;
    this.material.uniforms.time.value = this.time;
    // this.material.uniforms.progress.value = this.settings.progress;

    Object.keys(this.uniforms).forEach((item)=> {
      this.material.uniforms[item].value = this.settings[item];
    });

    // this.camera.position.z = 3;
    // this.plane.rotation.y = 0.4*Math.sin(this.time)
    // this.plane.rotation.x = 0.5*Math.sin(0.4*this.time)

    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }
}

const initialization = (wrapper, id, isMobile) => {
  new Sketch({
    wrapper: wrapper,
    id: id,
    width: isMobile ? window.innerWidth * 0.333 : window.innerWidth * 0.666,
    height: isMobile ? window.innerHeight / 2 : (window.innerHeight / 3) * 2,
    delay: 1,
    duration: 2,
    uniforms: {
      intensity: {value: 2.5, type:'f', min:0., max:5},
    },
    fragment: `
		uniform float time;
		uniform float progress;
		uniform float width;
		uniform float scaleX;
		uniform float scaleY;
		uniform float transition;
		uniform float radius;
		uniform float intensity;
		uniform sampler2D texture1;
		uniform sampler2D texture2;
		uniform sampler2D displacement;
		uniform vec4 resolution1;
		uniform vec4 resolution2;
		varying vec2 vUv;

		void main()	{
		  vec2 newUV1 = (vUv - vec2(0.5)) * resolution1.zw + vec2(0.5);
			vec2 newUV2 = (vUv - vec2(0.5)) * resolution2.zw + vec2(0.5);

			float progressMultiplier = -1.0;
			float newProgress = progress * progressMultiplier;

			vec4 d1 = texture2D(texture1, newUV1);
			vec4 d2 = texture2D(texture2, newUV2);

			float displace1 = (d1.r + d1.g + d1.b)*0.33;
			float displace2 = (d2.r + d2.g + d2.b)*0.33;

			vec4 t1 = texture2D(texture1, vec2(newUV1.x - newProgress * (displace2 * intensity), newUV1.y));
			vec4 t2 = texture2D(texture2, vec2(newUV2.x + (progressMultiplier - newProgress) * (displace1 * intensity), newUV2.y));
			vec4 t3 = texture2D(texture1, newUV1);
			vec4 t4 = texture2D(texture2, newUV2);

			gl_FragColor = mix(t1, t2, progress);
		}

	`
  })
}



export default initialization;