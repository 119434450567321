import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";

import { LinkPropType } from "../../../link/link";
import Image from "../../../image/image";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";

class ParagraphAdvertisingBanner extends Component {
  constructor(props) {
    super(props);

    let ads = [...this.props.content.fieldAds];

    // shuffle ads
    for (let i = ads.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = ads[i];
      ads[i] = ads[j];
      ads[j] = temp;
    }

    this.state = {
      ads,
      sliderSettings: {
        adaptiveHeight: false,
        arrows: false,
        centerMode: false,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-advertising-banner": true,
    });

    return (
      <section
        className={sectionClassNames}
        data-darkmode={this.props.content.fieldDarkMode}
      >
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h3>{this.props.content.fieldHeading}</h3>
            </div>
            <div className="col-16 col-lg-12 offset-lg-2">
              {this.state.ads.length > 1 ? (
                <Slider {...this.state.sliderSettings}>
                  {this.state.ads.map((item, index) => (
                    <article className="ad" key={index}>
                      {item.entity.fieldLink ? (
                        <a
                          rel="noopener noreferrer"
                          href={item.entity.fieldLink.url.path}
                          target="_blank"
                        >
                          {item.entity.fieldMediaImage1 && (
                            <Image data={item.entity.fieldMediaImage1} />
                          )}
                          {/*item.entity.fieldLabel && (
                          <h2>{item.entity.fieldLabel}</h2>
                        )*/}
                        </a>
                      ) : (
                        <>
                          {item.entity.fieldMediaImage1 && (
                            <Image data={item.entity.fieldMediaImage1} />
                          )}
                          {/*item.entity.fieldLabel && (
                          <h2>{item.entity.fieldLabel}</h2>
                        )*/}
                        </>
                      )}
                    </article>
                  ))}
                </Slider>
              ) : (
                <article className="ad single">
                  {this.state.ads[0]&&
                    <>
                      {this.state.ads[0].entity.fieldLink ? (
                        <a
                          rel="noopener noreferrer"
                          href={this.state.ads[0].entity.fieldLink.url.path}
                          target="_blank"
                        >
                          {this.state.ads[0].entity.fieldMediaImage1 && (
                            <Image data={this.state.ads[0].entity.fieldMediaImage1} />
                          )}
                          {/*item.entity.fieldLabel && (
                          <h2>{item.entity.fieldLabel}</h2>
                        )*/}
                        </a>
                      ) : (
                        <>
                          {this.state.ads[0].entity.fieldMediaImage1 && (
                            <Image data={this.state.ads[0].entity.fieldMediaImage1} />
                          )}
                          {/*item.entity.fieldLabel && (
                          <h2>{item.entity.fieldLabel}</h2>
                        )*/}
                        </>
                      )}
                    </>
                  }
                </article>
              )}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphAdvertisingBanner.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLinks: PropTypes.arrayOf(LinkPropType),
    fieldAds: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldLabel: PropTypes.string,
          fieldLink: PropTypes.shape({
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
            }),
          }),
          fieldMediaImage1: PropTypes.object,
        }),
      })
    ),
  }),
};

export default ParagraphAdvertisingBanner;
