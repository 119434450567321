import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { List32, Grid32 } from "@carbon/icons-react";
import { FormattedMessage } from "react-intl";

import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base/teaser-base";
import ComponentFilterCloud from "../extended-teaser-list/components/component-filter-cloud";
import {
  timelineSetActiveFilterAction,
  timelineRemoveActiveFilterAction,
} from "./actions-timeline";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{timelineConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  timelineConfig: reduxStore.timeLine.config,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/*
 * @todo: Pager.
 */
class ParagraphTimeline extends Component {
  state = {
    activeFilter: {},
    displaySwitch: "grid",
  };

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      this.state.activeFilter.entityId === activeFilter.entityId
    ) {
      this.setState({ activeFilter: {} }, () => {
        this.props.dispatch(
          timelineRemoveActiveFilterAction(this.props.content.entityId)
        );
      });
    } else {
      this.setState({ activeFilter }, () => {
        const config = {
          timeLineId: this.props.content.entityId,
          config: activeFilter,
        };

        this.props.dispatch(timelineSetActiveFilterAction(config));
      });
    }
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-timeline": true,
    });

    let items =
        this.props.nodes.nodeQuery &&
        this.props.nodes.nodeQuery.entities.length > 0
          ? this.props.nodes.nodeQuery.entities
          : [],
      loadMore =
        this.props.nodes.nodeQuery &&
        this.props.nodes.nodeQuery.entities.length > 0 &&
        this.props.nodes.nodeQuery.entities.length <
          this.props.nodes.nodeQuery.count;

    if (items.length > 0) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            {(this.props.content.fieldFilterwolke ||
              this.props.content.fieldListenumschalter) && (
              <div className="row timeline-filter">
                <div className="filter col-16 col-md-14">
                  {/* Note: Currently only works with filter={["fieldSchlagwort"]}, no custom filters!
                    In the timeline module, every filter change triggers a new (filtered) query, so the filters
                    would be needed to be variable in the graphql query (which does not work).
                  */}
                  {this.props.content.fieldFilterwolke && (
                    <ComponentFilterCloud
                      items={this.props.nodes.nodeQuery.entities}
                      filter={["fieldSchlagwort"]}
                      activeFilter={[this.state.activeFilter]}
                      changeActiveFilter={this.changeActiveFilter}
                      wrap={false}
                    />
                  )}
                </div>
                <div className="list-switch col-16 col-md-2 d-flex justify-content-end">
                  {this.props.content.fieldListenumschalter && (
                    <>
                      <List32
                        className={
                          this.state.displaySwitch === "list" ? "active" : ""
                        }
                        onClick={() => this.setState({ displaySwitch: "list" })}
                      />
                      <Grid32
                        className={
                          this.state.displaySwitch === "grid" ? "active" : ""
                        }
                        onClick={() => this.setState({ displaySwitch: "grid" })}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div
                className={classNames({
                  "col-16": true,
                  "col-lg-14 offset-lg-1": this.state.displaySwitch === "grid",
                })}
              >
                <div className="row">
                  {this.state.displaySwitch === "grid" ? (
                    <>
                      <div className="timeline col-1 d-none d-lg-flex flex-lg-column">
                        <div className="top">
                          <span>
                            <FormattedMessage id="timeline.now" />
                          </span>
                        </div>

                        <div className="bottom">
                          <span>
                            <FormattedMessage id="timeline.earlier" />
                          </span>
                        </div>
                      </div>

                      <div className="col-16 col-lg-6 offset-lg-1">
                        {items
                          .filter((item, index) => (index + 1) % 2 !== 0)
                          .map((item, index) => (
                            <TeaserBase
                              item={item}
                              key={index}
                              viewMode={"timeline"}
                            />
                          ))}
                      </div>

                      <div className="col-16 col-lg-6 offset-lg-1 timeline-column-1">
                        {items
                          .filter((item, index) => (index + 1) % 2 === 0)
                          .map((item, index) => (
                            <TeaserBase
                              item={item}
                              key={index}
                              viewMode={"timeline"}
                            />
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="col-16">
                      {items.map((item, index) => (
                        <TeaserBase
                          item={item}
                          key={index}
                          viewMode={"timeline-list"}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.props.content.fieldZeigeMehrLaden && loadMore && (
              <div className="row">
                <div className="col-16 d-flex justify-content-end justify-content-md-center">
                  <div
                    className="btn btn-primary"
                    onClick={() => this.props.nodes.loadMore()}
                  >
                    <span>
                      <FormattedMessage id="load_more" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      );
    }

    return <LoadingIndicator />;
  }
}

ParagraphTimeline.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    fieldFilterwolke: PropTypes.bool,
    fieldListenumschalter: PropTypes.bool,
    fieldZeigeMehrLaden: PropTypes.bool,
    fieldTypenTimeline: PropTypes.arrayOf(
      PropTypes.oneOf(["news", "blogbeitrag"])
    ),
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.number,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.shape({
    nodeQuery: PropTypes.shape({
      count: PropTypes.number,
      entities: PropTypes.arrayOf(teaserNewsPropTypes),
    }),
    loadMore: PropTypes.func,
  }),
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    options: (props) => {
      const tagConfig = props.timelineConfig.filter(
          (item) => item.timeLineId === props.content.entityId
        ),
        tagFiltered = tagConfig.length === 1 && tagConfig[0].config.entityId;

      let tags = [];

      // TODO: Not tag multi select, just one tag at a time.
      if (tagFiltered) {
        tags = [tagConfig[0].config.entityId];
      }

      if (props.content.fieldSchlagwort.length > 0) {
        for (const tag of props.content.fieldSchlagwort) {
          tags = [...tags, parseInt(tag.targetId, 10)];
        }
      }

      return {
        variables: {
          limit: props.content.fieldAnzahlDerAnzuzeigendenI,
          type: props.content.fieldTypenTimeline,
          tag: tags,
          filterTagEnabled: tags.length > 0,
          language: props.currentLanguage.toUpperCase(),
        },
      };
    },
    props: (props) => ({
      nodes: {
        ...props.nodes,
        loadMore: () =>
          props.nodes.fetchMore({
            variables: {
              offset: props.nodes.nodeQuery.entities.length,
            },
            updateQuery(previousResult, { fetchMoreResult }) {
              if (!fetchMoreResult) {
                return previousResult;
              }

              fetchMoreResult.nodeQuery.entities = [
                ...previousResult.nodeQuery.entities,
                ...fetchMoreResult.nodeQuery.entities,
              ];

              return fetchMoreResult;
            },
          }),
      },
    }),
  })(ParagraphTimeline)
);
