const viewModeAction = (viewMode) => ({
    viewMode,
    type: "VIEW_MODE",
  }),
  activeCloudDetailAction = (activeCloudDetail) => ({
    activeCloudDetail,
    type: "ACTIVE_CLOUD_DETAIL",
  });

export { activeCloudDetailAction, viewModeAction };
