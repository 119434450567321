const timelineReducerDefaultState = {
    config: [],
  },
  timelineReducer = (state = timelineReducerDefaultState, action) => {
    switch (action.type) {
      case "TIMELINE_SET_ACTIVE_FILTER_ACTION": {
        const configs = state.config.filter(
          (item) => item.timeLineId !== action.config.timeLineId
        );

        return Object.assign({}, state, {
          config: [...configs, action.config],
        });
      }
      case "TIMELINE_REMOVE_ACTIVE_FILTER_ACTION": {
        const configs = state.config.filter(
          (item) => item.timeLineId !== action.id
        );

        return Object.assign({}, state, { config: [...configs] });
      }
      default:
        return state;
    }
  };

export { timelineReducerDefaultState, timelineReducer };
