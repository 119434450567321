import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphZitat extends Component {
  render() {
    const { fieldImage = null, fieldZitat, fieldQuelle } = this.props.content;

    const sectionClassNames = classNames({
      "paragraph paragraph-zitat": true,
      "with-image": fieldImage,
      "no-image": !fieldImage,
      "highlighted ": this.props.content.fieldHighlighted,
      standard: !this.props.content.fieldHighlighted,
    });

    return (
      <section className={sectionClassNames} data-darkmode={this.props.content.fieldDarkMode}>
        <div className="container">
          <div className="row">
            {fieldImage !== null && (
              <div className="col-16 col-lg-7 image-col">
                <ErrorBoundary>
                  <Image
                    data={fieldImage.entity.fieldMediaImage}
                    className="quote-image"
                  />
                </ErrorBoundary>
              </div>
            )}
            <div
              className={classNames({
                "col-16 quote-col": true,
                "col-lg-9": fieldImage,
              })}
            >
              <blockquote className={"text"}>
                {/*<img src={`${self}/dashed-corner.svg`} alt={"abgerundete Ecke"} className={"dashed-corner top left"}/>
                <img src={`${self}/dashed-corner.svg`} alt={"abgerundete Ecke"} className={"dashed-corner top right"}/>
                <img src={`${self}/dashed-corner.svg`} alt={"abgerundete Ecke"} className={"dashed-corner bottom left"}/>
                <img src={`${self}/dashed-corner.svg`} alt={"abgerundete Ecke"} className={"dashed-corner bottom right"}/>*/}

                <div className={"dashed-corner top left"} />
                <div className={"dashed-corner top right"} />
                <div className={"dashed-corner bottom left"} />
                <div className={"dashed-corner bottom right"} />

                <div className="text">{`« ${fieldZitat} »`}</div>
                <cite>{fieldQuelle}</cite>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphZitat.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
    fieldHighlighted: PropTypes.bool.isRequired,
  }),
};

ParagraphZitat.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphZitat;
