import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const HiddenField = ({ item }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  return (
    <div className="form-group">
      <input
        id={item.id}
        name={item.id}
        type="hidden"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[item.id]}
      />
    </div>
  );
};

HiddenField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default HiddenField;
