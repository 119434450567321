import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps,
      keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[keys.length - 1]}"]`,
        errorElement = document.querySelector(selector);

      if (errorElement) {
        errorElement.focus();
      }
    }
  }

  render() {
    return null;
  }
}

ErrorFocus.propTypes = {
  isSubmitting: PropTypes.bool,
  isValidating: PropTypes.bool,
  errors: PropTypes.object,
};

export default connect(ErrorFocus);
