import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from "../../../image/image";
import { self } from "../../../../config";
import moment from "moment";
import {FormattedMessage} from "react-intl";

class ParagraphAudio extends Component {

  state = {
    currentTime: 0,
    duration: 0
  };

  timeUpdateListener = e => {
    this.setState({
      currentTime: e.target.currentTime,
      duration: e.target.duration
    });
  };

  constructor(props) {
    super(props);

    this.player = React.createRef();
  }

  getTime = (time) => {
    if (!isNaN(time)) {
      return Math.floor(time / 60) + '.' + ('0' + Math.floor(time % 60)).slice(-2);
    }

    return null;
  };

  goBack = seconds => {
    this.player.currentTime = this.state.currentTime - seconds;
  }

  play = () => {
    this.player.play();
  };

  pause = () => {
    this.player.pause();
  };

  minusVolume = () => {
    this.player.volume = this.player.volume - 0.2;
  }

  plusVolume = () => {
    this.player.volume = this.player.volume + 0.2;
  }

  mute = () => {
    this.player.volume = 0.0;
  }

  componentDidMount() {
    this.player.addEventListener('timeupdate', e => this.timeUpdateListener(e));
    this.player.addEventListener('loadedmetadata', e => this.audioLoaded(e));

    this.setState({
      currentTime: this.player.currentTime,
      duration: this.player.duration
    });
  }

  componentWillUnmount() {
    this.player.removeEventListener('timeupdate', e => this.timeUpdateListener(e));
  }

  audioLoaded = () => {
    this.setState({
      currentTime: this.player.currentTime,
      duration: this.player.duration
    });
  }

  skipThrough = (e) => {
    // Calculate the difference from start to click of the bar, to get the wanted "percentage to skip to".
    let difference = (e.clientX - e.target.getBoundingClientRect().left) / e.target.parentElement.getBoundingClientRect().width;
    this.player.currentTime = difference * this.player.duration;
  }

  render() {
    const sectionClassNames = classNames({
        'paragraph paragraph-audio': true,
        'with-image': this.props.content && this.props.content.fieldImage,
        'without-image': this.props.content && !this.props.content.fieldImage,
        'without-text': !this.props.content.fieldText
      }),
      { content } = this.props,
      currentTime = this.getTime(this.state.currentTime),
      duration = this.getTime(this.state.duration);

    return (
      <section className={sectionClassNames} data-darkmode={this.props.content.fieldDarkMode}>
        <div className="container">
          <div className="row">
            {(content.fieldHeadline || content.fieldSubline) &&
              <div className={`col-16 headline-wrapper ${content.fieldImage ? '' : 'col-lg-8'}`}>
                {content.fieldHeadline &&
                  <h2 className={"headline"}>{content.fieldHeadline}</h2>
                }
                {content.fieldSubheadline &&
                  <h3 className={"subline"}>{content.fieldSubheadline}</h3>
                }
              </div>
            }
            {content.fieldImage &&
              <div className={`col-16 col-lg-8 image-section`}>
                <Image data={content.fieldImage.entity.fieldMediaImage} />
              </div>
            }
            <div className={`col-16 col-lg-8 audio-section`}>
              <div className="height-wrapper">
                <div className="inner-wrapper">
                  <div className={"dashed-corner top left"} />
                  <div className={"dashed-corner top right"} />
                  <div className={"dashed-corner bottom left"} />
                  <div className={"dashed-corner bottom right"} />
                  <div className="file-info">
                    {(content.fieldSubline || content.fieldPublishedOn) &&
                      <div className="infos">
                        <div className="date">
                          {content.fieldPublishedOn &&
                            <h4>
                              {moment
                                .utc(content.fieldPublishedOn.value)
                                .local()
                                .format("DD.MM.YYYY")}
                            </h4>
                          }
                        </div>
                        <div className="subline">
                          {content.fieldSubline &&
                            <h4>{content.fieldSubline}</h4>
                          }
                        </div>
                      </div>
                    }
                    {content.fieldTitleAudio &&
                      <h3>{content.fieldTitleAudio}</h3>
                    }
                  </div>
                  <div className="audio-controls">
                    <audio
                      ref={ref => this.player = ref}
                      onCanPlay={() => this.audioLoaded()}
                    >
                      <source src={content.fieldAudioLink.url.path}/>
                    </audio>
                    <div className="button">
                      <div className="function">
                        {this.player.paused ? (
                          <button
                            tabIndex={0}
                            role="button"
                            aria-label={"play"}
                            className="audio-toggle"
                            onClick={this.play}
                          >
                            <div className={"sr-only"}>
                              <FormattedMessage id={"play"} />
                            </div>
                            <img src={`${self}/ui/play_black.svg`} alt={"play icon"}/>
                          </button>
                        ) : (
                          <button
                            tabIndex={0}
                            role="button"
                            aria-label={"pause"}
                            className="audio-toggle"
                            onClick={this.pause}
                          >
                            <div className={"sr-only"}>
                              <FormattedMessage id={"pause"} />
                            </div>
                            <img src={`${self}/ui/pause_black.svg`} alt={"pause icon"}/>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="bar-wrapper">
                      <div className={`progress-bar ${this.state.currentTime === 0 ? 'paused' : 'playing'}`}>
                        <div className="bar" onClick={(e) => this.skipThrough(e)}>
                          <div className="fill" style={{
                            width: `${(100 / this.state.duration) * this.state.currentTime}%`
                          }}/>
                        </div>
                      </div>
                    </div>
                    <div className="time">
                      <div className="current">
                        {currentTime ? (
                          <span className="current-time">
                      {currentTime}
                    </span>
                        ) : (
                          <span className="current-time">
                      00:00 min
                    </span>
                        )}
                        {duration && <React.Fragment> / {duration}</React.Fragment>}
                      </div>
                    </div>
                  </div>
                </div>
                {Boolean(content.fieldText) &&
                  <div className="text-wrapper">
                    {content.fieldText &&
                      <div
                        className="text allow-scrolling"
                        tabIndex={0}
                        dangerouslySetInnerHTML={{ __html: content.fieldText.processed }}
                      />
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

ParagraphAudio.propTypes = {
  content: PropTypes.object.isRequired
};

export default ParagraphAudio;
