import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import YouTubeVideo from "./component-youtube";

/**
 * Render YouTube, Vimeo or local video files.
 */
class ParagraphMedia extends Component {
  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-media": true,
        "small": !this.props.content.fieldBig,
        "big": this.props.content.fieldBig,
        "highlighted": this.props.content.fieldHighlighted,
      }),
      { content } = this.props;

    return (
      <YouTubeVideo
        content={content}
        sectionClassNames={sectionClassNames}
        size={this.props.content.fieldBig}
      />
    );
  }
}

ParagraphMedia.propTypes = {
  content: PropTypes.shape({
    fieldMediaArt: PropTypes.oneOf(["youtube", "vimeo", "video_file"])
      .isRequired,
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVimeoVideoUrl: PropTypes.string,
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string,
    }),
    fieldVideoFileMedia: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldBig: PropTypes.bool,
    fieldHighlighted: PropTypes.bool,
  }),
};

export default ParagraphMedia;
