import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";

import Image from "../../../image/image";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

class ParagraphTeaserImage extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-image": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div
            className={classNames({
              row: true,
              "image-left":
                this.props.content.fieldImagePosition === "left",
              "image-right flex-row-reverse":
                this.props.content.fieldImagePosition === "right",
            })}
          >
            <div
              className={classNames({
                "col-16 col-lg-8 image-col": true
              })}
            >
              {this.props.content.fieldImage.entity.fieldTyp === "youtube" && this.props.content.fieldImage.entity.fieldMediaOembedVideo &&
                <YouTube
                  videoId={this.props.content.fieldImage.entity.fieldMediaOembedVideo.split("?v=")[1]}/>
              }
              {this.props.content.fieldImage.entity.fieldTyp === "vimeo" && this.props.content.fieldImage.entity.fieldMediaOembedVideo &&
                <Vimeo
                  video={this.props.content.fieldImage.entity.fieldMediaOembedVideo}
                  responsive/>
              }
              {this.props.content.fieldImage.entity.fieldMediaVideoFile &&
                <video
                  src={this.props.content.fieldImage.entity.fieldMediaVideoFile.entity.url}
                  controls={true}
                />
              }
              {this.props.content.fieldImage.entity.fieldMediaImage &&
                <Image
                  data={this.props.content.fieldImage.entity.fieldMediaImage}
                  nodeTitle={this.props.content.fieldHeading}
                />
              }
            </div>
            <div
              className={classNames({
                "col-16 col-lg-8 text-col": true
              })}
            >
              <h2>{this.props.content.fieldHeading}</h2>
              {this.props.content.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldText.processed,
                  }}
                />
              )}
              {this.props.content.fieldCallToAction && (
                <Link
                  className="learn-more"
                  link={this.props.content.fieldCallToAction}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldTyp: PropTypes.oneOf(["youtube", "vimeo", "image", "video"]),
        fieldMediaOembedVideo: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
