import React from "react";

const TagWrapper = (props) => {

  if (props.content.fieldCategory || props.content.fieldGenre || props.content.fieldCountry) {
    return (
      <div className="tag-wrapper">
        {props.content.fieldCategory &&
          props.content.fieldCategory.entity && (
            <div className="tag category">
              {props.content.fieldCategory.entity.name}
            </div>
          )}
        {props.content.fieldGenre && props.content.fieldGenre.map((genre, genreIndex) =>
          <>
            {genre?.entity?.name &&
              <div className="tag genre" key={genreIndex}>
                {genre?.entity?.name}
              </div>
            }
          </>
          )}
        {props.content.fieldCountry &&
          props.content.fieldCountry[0] && (
            <div className="tag country">
              {props.content.fieldCountry[0]}
            </div>
          )}
      </div>
    );
  } else {
    return false;
  }
};

export default TagWrapper;
