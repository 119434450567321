import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import Marquee from "react-fast-marquee";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphLogosPartners extends Component {
  state = {
    speed: 40 + Math.floor(Math.random() * 20),
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-logos-partners": true,
      small: this.props.content.fieldSmall,
    });

    return (
      <section
        className={sectionClassNames}
        data-darkmode={this.props.content.fieldDarkMode}
      >
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16">
                <div className="heading">{this.props.content.fieldHeading}</div>
              </div>
            </div>
          </div>
        )}
        <div className="marquee-wrapper">
          <Marquee
            pauseOnHover={false}
            pauseOnClick={false}
            speed={this.state.speed}
            gradient={false}
          >
            {[...Array(8)].map((elem, index) => (
              <div key={index} className="marquee-element">
                {this.props.content.fieldLogosPartners.map((item, subIndex) => (
                  <div
                    key={subIndex}
                    className={classNames({
                      partner: true,
                    })}
                  >
                    {item.entity.fieldLink !== null ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.entity.fieldLink.url.path}
                      >
                        <Image data={item.entity.fieldMediaImage1} />
                      </a>
                    ) : (
                      <ErrorBoundary>
                        <Image data={item.entity.fieldMediaImage1} />
                      </ErrorBoundary>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Marquee>
        </div>
      </section>
    );
  }
}

ParagraphLogosPartners.propTypes = {
  content: PropTypes.shape({
    fieldDarkMode: PropTypes.bool,
    fieldSmall: PropTypes.bool,
    fieldHeading: PropTypes.string,
    fieldLogosPartners: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldLink: PropTypes.shape({
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
            }),
          }),
          fieldMediaImage1: PropTypes.shape({
            style: PropTypes.shape({
              url: PropTypes.string,
            }).isRequired,
            styleFullPage: null,
            desktop: null,
            mobile: null,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphLogosPartners;
