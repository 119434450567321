import React, { Component } from "react";
import PropTypes from "prop-types";

class ChooseAStyle extends Component {
  chooseACard = React.createRef();

  setStyle = (style) => {
    this.chooseACard.current.closest(".eforkbot").dataset.style = style;
  };

  clickStep = (triggerValue) => {
    this.props.triggerNextStep({ value: triggerValue, trigger: triggerValue });
    this.chooseACard.current.classList.add("no-pointers");
  };

  render() {
    return (
      <div className="choose-a-style" ref={this.chooseACard}>
        <div className="container">
          <div className="row">
            {this.props.value &&
              this.props.value.map((style, index) => (
                <div
                  className="answer col"
                  onClick={() => this.clickStep(style.customTrigger)}
                  onMouseOver={() => this.setStyle(style.value)}
                  key={index}
                >
                  <div className="option">{style.value}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

ChooseAStyle.propTypes = {
  value: PropTypes.object.isRequired,
  triggerNextStep: PropTypes.func,
};

export default ChooseAStyle;
