const appReducerDefaultState = {
    adminApp: false,
    mainMenuOpen: false,
    accessibilityMenuOpen: false,
    pagerFullPageContextId: false,
    pagerFullPages: [],
    mainSettings: false,
    isMobile: true,
    menuTitle: false,
    hideSort: false,
    hideFilter: false,
    overwriteFooter: false
  },
  appReducer = (state = appReducerDefaultState, action) => {
    switch (action.type) {
      case "ADMIN_APP": {
        return Object.assign({}, state, { adminApp: action.adminApp });
      }
      case "PAGER_FULL_PAGE": {
        // Remove if they are pushed twice (or after query finish).
        const pagerFullPages = state.pagerFullPages.filter(
          (config) => config.id !== action.config.id
        );
        return Object.assign({}, state, {
          pagerFullPages: [...pagerFullPages, action.config],
        });
      }
      case "PAGER_FULL_PAGE_CONTEXT_ID": {
        return Object.assign({}, state, { pagerFullPageContextId: action.id });
      }
      case "MAIN_MENU_OPEN": {
        return Object.assign({}, state, { mainMenuOpen: action.mainMenuOpen });
      }
      case "ACCESSIBILITY_MENU_OPEN": {
        return Object.assign({}, state, { accessibilityMenuOpen: action.accessibilityMenuOpen });
      }
      case "IS_MOBILE": {
        return Object.assign({}, state, { isMobile: action.isMobile });
      }
      case "MAIN_SETTINGS": {
        return Object.assign({}, state, { mainSettings: action.mainSettings });
      }
      case "MENU_TITLE": {
        return Object.assign({}, state, { menuTitle: action.menuTitle });
      }
      case "HIDE_SORT": {
        return Object.assign({}, state, { hideSort: action.hideSort });
      }
      case "HIDE_FILTER": {
        return Object.assign({}, state, { hideFilter: action.hideFilter });
      }
      case "OVERWRITE_FOOTER": {
        return Object.assign({}, state, { overwriteFooter: action.overwriteFooter });
      }

      default:
        return state;
    }
  };

export { appReducerDefaultState, appReducer };
