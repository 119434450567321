const originalPositions = [
  0.29745787382125854, 0.1983877271413803, 0.07860143482685089,
  0.29745787382125854, 0.1983877569437027, -0.0786014124751091,
  0.2977832555770874, 0.04851442202925682, 0.0786014273762703,
  0.2977832555770874, 0.04851442947983742, -0.0786013975739479,
  0.19199848175048828, -0.021118605509400368, 0.0786014273762703,
  0.19199848175048828, -0.021118616685271263, -0.0786013975739479,
  -0.038085222244262695, -0.021106714382767677, 0.0786014273762703,
  -0.038085222244262695, -0.021106725558638573, -0.0786013975739479,
  0.1833232045173645, 0.27340519428253174, 0.0786014273762703,
  0.1833232045173645, 0.27340519428253174, -0.0786014199256897,
  -0.039063960313797, 0.2734867334365845, 0.0786014199256897,
  -0.039063960313797, 0.2734867334365845, -0.0786014124751091,
  0.2977832555770874, 0.04851441830396652, -0.0786013975739479,
  0.29745787382125854, 0.1983877420425415, -0.0786014050245285,
  -0.039063960313797, 0.2734867334365845, -0.0786014124751091,
  -0.038085222244262695, -0.021106725558638573, -0.0786013975739479,
  -0.038085222244262695, -0.021106725558638573, -0.0786013975739479,
  0.19199848175048828, -0.021118616685271263, -0.0786013975739479,
  0.1833232045173645, 0.27340519428253174, -0.0786014124751091,
  -0.039063960313797, 0.2734867334365845, -0.0786014124751091,
  0.19199848175048828, -0.021118616685271263, -0.0786013975739479,
  0.2977832555770874, 0.04851441830396652, -0.0786013975739479,
  0.29745787382125854, 0.1983877420425415, -0.0786014050245285,
  0.1833232045173645, 0.27340519428253174, -0.0786014124751091,
  0.2977832555770874, 0.048514433205127716, 0.0786014273762703,
  0.29745787382125854, 0.1983877420425415, 0.0786014273762703,
  -0.039063960313797, 0.2734867334365845, 0.0786014199256897,
  -0.038085222244262695, -0.021106714382767677, 0.0786014273762703,
  -0.038085222244262695, -0.021106714382767677, 0.0786014273762703,
  0.19199848175048828, -0.021118605509400368, 0.0786014273762703,
  0.1833232045173645, 0.27340519428253174, 0.0786014199256897,
  -0.039063960313797, 0.2734867334365845, 0.0786014199256897,
  0.19199848175048828, -0.021118605509400368, 0.0786014273762703,
  0.2977832555770874, 0.048514433205127716, 0.0786014273762703,
  0.29745787382125854, 0.1983877420425415, 0.0786014273762703,
  0.1833232045173645, 0.27340519428253174, 0.0786014199256897,
  -0.4454119801521301, 0.27359211444854736, 0.0786014273762703,
  -0.4454119801521301, 0.27359211444854736, -0.0786014199256897,
  -0.3773220181465149, -0.3209960460662842, 0.07860143482685089,
  -0.3773220181465149, -0.3209960460662842, -0.07860138267278671,
  -0.3783900737762451, 0.07092864066362381, 0.0786014199256897,
  -0.3783900737762451, 0.07092862576246262, -0.0786014050245285,
  -0.04975154995918274, -0.3211454153060913, 0.07860143482685089,
  -0.04975154995918274, -0.3211454153060913, -0.07860138267278671,
  -0.05111730098724365, 0.27348339557647705, 0.0786014199256897,
  -0.05111730098724365, 0.27348339557647705, -0.0786014124751091,
  -0.44495540857315063, 0.07139129191637039, 0.0786014199256897,
  -0.44495540857315063, 0.07139124721288681, -0.0786014050245285,
  -0.05111730098724365, -0.3211454153060913, 0.0786013975739479,
  -0.04975154995918274, 0.27348339557647705, 0.07860145717859268,
  -0.05111730098724365, 0.27348339557647705, -0.0786014124751091,
  -0.4454119801521301, 0.27359211444854736, -0.0786014124751091,
  -0.3773220181465149, -0.3209960460662842, -0.07860138267278671,
  -0.04975154995918274, -0.3211454153060913, -0.07860138267278671,
  -0.4454119801521301, 0.27359211444854736, -0.0786014124751091,
  -0.44495540857315063, 0.07139124721288681, -0.0786014050245285,
  -0.3783900737762451, 0.07092862576246262, -0.0786014050245285,
  -0.3773220181465149, -0.3209960460662842, -0.07860138267278671,
  -0.44495540857315063, 0.07139124721288681, -0.0786014050245285,
  -0.3783900737762451, 0.07092862576246262, -0.0786014050245285,
  -0.05111730098724365, -0.3211454153060913, -0.07860143482685089,
  -0.04975154995918274, 0.27348339557647705, -0.07860136032104492,
  -0.05111730098724365, 0.27348339557647705, 0.0786014199256897,
  -0.4454119801521301, 0.27359211444854736, 0.0786014199256897,
  -0.3773220181465149, -0.3209960460662842, 0.07860143482685089,
  -0.04975154995918274, -0.3211454153060913, 0.07860143482685089,
  -0.4454119801521301, 0.27359211444854736, 0.0786014199256897,
  -0.44495540857315063, 0.07139129191637039, 0.0786014199256897,
  -0.3783900737762451, 0.07092864066362381, 0.0786014199256897,
  -0.3773220181465149, -0.3209960460662842, 0.07860143482685089,
  -0.44495540857315063, 0.07139129191637039, 0.0786014199256897,
  -0.3783900737762451, 0.07092864066362381, 0.0786014199256897,
  0.3893800973892212, -0.12058673799037933, 0.0786014273762703,
  0.3893800973892212, -0.12058670818805695, -0.07860139012336731,
  -0.0387856662273407, -0.0330650731921196, 0.0786014273762703,
  -0.0387856662273407, -0.03306508809328079, -0.0786013975739479,
  0.09676855802536011, -0.32184553146362305, 0.07860143482685089,
  0.09676855802536011, -0.32184553146362305, -0.07860138267278671,
  -0.03799408674240112, -0.20807281136512756, 0.0786014273762703,
  -0.03799408674240112, -0.20807281136512756, -0.07860139012336731,
  0.38692349195480347, -0.32174888253211975, 0.0786014124751091,
  0.38692349195480347, -0.32174888253211975, -0.0786014050245285,
  0.28231579065322876, -0.033690743148326874, 0.0786014273762703,
  0.28231579065322876, -0.03369075804948807, -0.0786013975739479,
  0.3893800675868988, -0.32174888253211975, -0.07860138267278671,
  0.38692352175712585, -0.12058673053979874, -0.0786013975739479,
  -0.0387856662273407, -0.03306508809328079, -0.0786013975739479,
  -0.03799408674240112, -0.20807281136512756, -0.07860139012336731,
  0.28231579065322876, -0.03369075804948807, -0.0786013975739479,
  -0.0387856662273407, -0.03306508809328079, -0.0786013975739479,
  0.09676855802536011, -0.32184553146362305, -0.07860138267278671,
  0.3893800973892212, -0.32174888253211975, -0.07860138267278671,
  -0.03799408674240112, -0.20807281136512756, -0.07860139012336731,
  0.09676855802536011, -0.32184553146362305, -0.07860138267278671,
  0.38692349195480347, -0.12058673053979874, -0.0786013975739479,
  0.28231579065322876, -0.03369075804948807, -0.0786013975739479,
  0.3893800675868988, -0.32174888253211975, 0.07860143482685089,
  0.38692352175712585, -0.12058671563863754, 0.0786014199256897,
  -0.0387856662273407, -0.0330650731921196, 0.0786014273762703,
  -0.03799408674240112, -0.20807281136512756, 0.0786014273762703,
  0.28231579065322876, -0.033690743148326874, 0.0786014273762703,
  -0.0387856662273407, -0.0330650731921196, 0.0786014273762703,
  0.09676855802536011, -0.32184553146362305, 0.07860143482685089,
  0.3893800973892212, -0.32174888253211975, 0.07860143482685089,
  -0.03799408674240112, -0.20807281136512756, 0.0786014273762703,
  0.09676855802536011, -0.32184553146362305, 0.07860143482685089,
  0.38692349195480347, -0.12058671563863754, 0.0786014199256897,
  0.28231579065322876, -0.033690743148326874, 0.0786014273762703,
];

export default originalPositions;
