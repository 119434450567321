import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserProjekt extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article
        style={{
          backgroundImage: `url("${this.props.item.fieldTeaserbild.entity.fieldMediaImage.style.url}")`,
        }}
        className="node node-teaser teaser-projekt"
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={
            this.props.item.entityUrl
              ? this.props.item.entityUrl.path
              : this.props.item.path.alias
          }
        >
          <h2>{this.props.item.title}</h2>
          <div className="tags">
            {this.props.item.fieldSchlagwort.length >= 1 &&
              this.props.item.fieldSchlagwort.map((item, index) => (
                <React.Fragment key={index}>
                  {item.entity !== null && (
                    <span className="tag">{item.entity.name}</span>
                  )}
                </React.Fragment>
              ))}
          </div>
        </Link>
      </article>
    );
  }
}

export const teaserProjectPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserProjekt.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserProjectPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserProjekt));
