import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { connect } from "react-redux";

import menuQuery from "../general-components/menu-query.graphql";
import {
  mainMenuOpenAction,
  accessibilityMenuOpenAction,
} from "../app-actions";
import Link from "../general-components/link/link";
import { Link as ReactRouterLink } from "react-router-dom";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  accessibilityMenuOpen: reduxStore.appStore.accessibilityMenuOpen,
  mainMenuOpen: reduxStore.appStore.mainMenuOpen,
});

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    menuOpen: false,
    openTree: false,
  };

  /*@todo: Store this in props and use body-scroll-lock in a lib function*/
  toggleFullScreenMenu = () => {
    if (this.props.mainMenuOpen) {
      this.props.dispatch(mainMenuOpenAction(false));
    }
    if (!this.props.mainMenuOpen && !this.props.accessibilityMenuOpen) {
      this.props.dispatch(mainMenuOpenAction(true));
    }
    if (this.props.accessibilityMenuOpen) {
      this.props.dispatch(accessibilityMenuOpenAction(false));
    }
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  render() {
    return (
      <>
        <button
          id="toggle-fullscreen-menu"
          tabIndex={0}
          className={this.props.mainMenuOpen ? "active" : ""}
          aria-label="toggle full-screen menu"
          onClick={() => this.toggleFullScreenMenu()}
          onKeyDown={this.handleKeyDown}
        >
          <div className="spin-wrapper">
            <span>Menu</span>
            <span>Close</span>
          </div>
        </button>

        <div
          id="fullscreen-menu"
          className={this.props.mainMenuOpen ? "active" : ""}
        >
          <div className="nav-wrap">
            <div className="upper-part header-element">
              <div className="border-element"/>
              <div className="effect-element"/>
              <div className="container">
                <div className="row">
                  {/*this.props.mainSettingsConfig && this.props.mainSettingsConfig.fieldHeadline &&
                  <div className="col-16">

                    <div className="claim-wrap">
                      {this.props.mainSettingsConfig.fieldHeadline}
                    </div>
                  </div>
                */}
                  <div className="col-16 col-md-custom-big">
                    <nav className="main-menu-desktop" aria-label={"main menu"}>
                      <ul className={"row"}>
                        {!this.props.mainMenuQuery.loading &&
                          this.props.mainMenuQuery.menuByName.links.map(
                            (item, index) => (
                              <li
                                className="col-16"
                                key={index}
                              >
                                <div className="menu-tree">
                                  <>
                                    {item.url.path !== "" ? (
                                      <ReactRouterLink to={item.url.path} aria-label={item.label}>
                                        {item.label}
                                      </ReactRouterLink>
                                    ) : (
                                      <span
                                        role={"button"}
                                        aria-label="Open Menu Tree"
                                        tabIndex={0}
                                        onClick={() =>
                                          this.setState({ openTree: index })
                                        }
                                        className="headline"
                                      >
                                        {item.label}
                                      </span>
                                    )}

                                    {item.links.length > 0 && (
                                      <ul
                                        className={
                                          this.state.openTree === index
                                            ? "nested active"
                                            : "nested"
                                        }
                                      >
                                        {item.links.map(
                                          (linkItem, linkIndex) => (
                                            <li
                                              key={linkIndex}
                                            >
                                              <div
                                                role={"button"}
                                                aria-label="Open Menu Tree"
                                                tabIndex={0}
                                                  onClick={() =>
                                                    linkItem.url.path !== ""
                                                      ? this.toggleFullScreenMenu()
                                                      : ""
                                                  }
                                              >

                                              </div>
                                              <ReactRouterLink to={linkItem.url.path} aria-label={linkItem.label}>
                                                {linkItem.label}
                                              </ReactRouterLink>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </>
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-part">
              <div className="container">
                <div className="row">
                  <div className="col-16">
                    {this.props.highlightMenuQuery &&
                      this.props.highlightMenuQuery.menuByName && (
                        <nav className="highlight-menu" aria-label={"highlight menu"}>
                          <ul>
                            {this.props.highlightMenuQuery.menuByName.links.map(
                              (item, index) => (
                                <li key={index} className={`${item.label.length >= 11 ? 'small' : 'standard'}`}>
                                  <div className="effect-element"/>
                                  {<Link link={{ url: item.url, title: item.label }} />}
                                </li>
                              )
                            )}
                          </ul>
                        </nav>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SimpleNavigation.propTypes = {
  dispatch: PropTypes.func,
  mainMenuOpen: PropTypes.bool,
  mainMenuQuery: PropTypes.object,
  oftenClickedMenuQuery: PropTypes.object,
  highlightMenuQuery: PropTypes.object,
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "main",
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "mainMenuQuery",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "haeufig-geklickt",
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "oftenClickedMenuQuery",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "highlight-menu",
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "highlightMenuQuery",
    })
  )(SimpleNavigation)
);
