import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import newsQuery from "./news.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "./../i18n/i18n";
import Image from "../general-components/image/image";
import FieldTime from "../general-components/date-time/field-time";
import {menuTitleAction} from "../app-actions";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

class News extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
    this.props.dispatch(menuTitleAction("News"));
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-news node-${this.props.data.route.entity.entityId} node-full-page ${published}`}
        >
          <Helmet>
            <title>{`Reeperbahn Festival | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>

          <div className="container news">
            <div className="row">
              <div className="col-16 col-md-8 image-col">
                {this.props.data.route.entity.fieldTeaserbild &&
                  <Image
                    data={this.props.data.route.entity.fieldTeaserbild.entity.fieldMediaImage}
                    nodeTitle={this.props.data.route.entity.title}
                    credit={this.props.data.route.entity.fieldTeaserbild.entity.fieldCredit}
                  />
                }
              </div>
              <div className="col-16 col-md-7 offset-md-1 content-col">
                <div className="meta">
                  <div className="published-on">
                    <FieldTime
                      timestamp={true}
                      date={
                        this.props.data.route.entity.publishedAt.value
                          ? this.props.data.route.entity.publishedAt.value
                          : this.props.data.route.entity.publishedAt.publishedAtOrNow
                      }
                      format={"D MMMM"}
                    />
                  </div>
                  <span>
                    {this.props.data.route.entity.fieldSponsored && <> Sponsored News</>}
                  </span>
                </div>
                {this.props.data.route.entity.entityLabel &&
                  <Hyphenated language={de}>
                    <h1>{this.props.data.route.entity.entityLabel}</h1>
                  </Hyphenated>
                }
                {this.props.data.route.entity.fieldModules && (
                  <ContentBase content={this.props.data.route.entity.fieldModules} />
                )}
              </div>
            </div>
          </div>

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true} />;
  }
}

News.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default graphql(newsQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(News)));
