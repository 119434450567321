const filterDuplicates = (items) => {
  items = items.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t != null &&
          "fieldProgramNumber" in t &&
          value != null &&
          "fieldProgramNumber" in value &&
          t.fieldProgramNumber === value.fieldProgramNumber
      )
  );
  return items;
};

export { filterDuplicates };
