import React, { Component } from "react";
import PropTypes from "prop-types";

class Audio extends Component {
  render() {
    return (
      <div className="audio-wrapper">
        {this.props.url &&
          typeof this.props.url.entity.fieldMediaAudioFile !== "undefined" && (
            <audio controls>
              <source
                src={this.props.url.entity.fieldMediaAudioFile.entity.url}
              />
            </audio>
          )}
      </div>
    );
  }
}

Audio.propTypes = {
  url: PropTypes.object.isRequired,
};

export default Audio;
