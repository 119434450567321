import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import initAnimationController from "./rbx-animation-controller";

const RBXAnimation = ({
  className,
  scalingFactor,
  dashColor,
  dashSize,
  dashGap,
  dashSpeedFactor,
  animationSpeedFactor,
  dashScale,
  animationEffectSize,
}) => {
  const animationRef = useRef();

  useEffect(() => {
    initAnimationController(animationRef.current, {
      scalingFactor,
      dashColor,
      dashSize,
      dashGap,
      dashSpeedFactor,
      animationSpeedFactor,
      dashScale,
      animationEffectSize,
    });
  }, [animationRef]);

  return <div className={className} ref={animationRef}></div>;
};

RBXAnimation.propTypes = {
  className: PropTypes.string,
  dashColor: PropTypes.string,
  dashSize: PropTypes.number,
  dashGap: PropTypes.number,
  dashSpeedFactor: PropTypes.number,
  animationSpeedFactor: PropTypes.number,
  dashScale: PropTypes.number,
  animationEffectSize: PropTypes.number,
};

export default RBXAnimation;
