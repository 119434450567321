import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import ReactPaginate from "react-paginate";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import { filterDuplicates } from "../../../../lib/filter-duplicates";

import teaserNodeQueryEventTagFiltered from "../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserPersonOverview from "../../../teaser-base/person/teaser-person-overview";
import TeaserNews from "../../../teaser-base/news/teaser-news";
import ComponentFilterCloud from "./components/component-filter-cloud";
import TeaserProjekt from "../../../teaser-base/project/teaser-projekt";
import TeaserLocation from "../../../teaser-base/location/teaser-location";
import { pagerFullPagesAction } from "../../../../app-actions";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEvent from "../../../teaser-base/event/teaser-event";
import TeaserParticipant from "../../../teaser-base/participant/teaser-participant";
import ProgrammeOverview from "../programme-overview/programme-overview";
import SpeakerOverview from "../extended-teaser-list/participants/speaker-overview";
import { MainSettingsProps } from "../../../../types";

import LoadingIndicator from "../../../loading-indicator";
import LocationOverview from "./locations/location-overview";
import { FormattedMessage } from "react-intl";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage,
  isMobile: reduxStore.appStore.isMobile,
});

class ParagraphExtendedTeaserOverview extends Component {
  static defaultProps = {
    skipQuery: false,
    filterCloudLabels: [],
    filterCloudFilters: ["fieldGenre"],
    filterCloudFilterSingle: true,
    filterCloudMultipleFilterCondition: "OR",
    filterCloudPreventEmptyResult: true,
  };

  filterItems = (filter = [], search = "") => {
    let filteredItems = [];

    const activeFilter =
        filter.length > 0
          ? filter
          : this.state
          ? this.state.activeFilter
          : null,
      activeSearch =
        search !== "" ? search : this.state ? this.state.activeSearch : null;

    if (
      (activeFilter && activeFilter.length > 0) ||
      (activeSearch && activeSearch !== "")
    ) {
      if (
        activeFilter.length === 1 &&
        activeFilter[0].entityId &&
        !activeFilter[0].field
      ) {
        // Default (old) filtering with fieldSchlagwort.
        filteredItems = this.props.nodes.nodeQuery.entities.filter((item) =>
          item.fieldSchlagwort.some((tag) =>
            tag && tag.entity
              ? tag.entity.entityId === activeFilter.entityId
              : false
          )
        );
      } else if (activeFilter.length > 0) {
        // Filter all selected filters.
        filteredItems = this.props.nodes.nodeQuery.entities.filter((item) => {
          if (this.props.filterCloudMultipleFilterCondition === "OR") {
            for (const activeFilterItem of activeFilter) {
              if (item[activeFilterItem.field] == null) {
                continue;
              }
              // Filtering on string-based fields.
              if (typeof item[activeFilterItem.field] === "string") {
                if (
                  item[activeFilterItem.field] === activeFilterItem.entityId
                ) {
                  return true;
                }

                continue;
              }

              // Single Entity reference fields.
              if (item[activeFilterItem.field].entity) {
                if (
                  item[activeFilterItem.field].entity.entityId ===
                  activeFilterItem.entityId
                ) {
                  return true;
                }

                continue;
              }

              // Multi Entity reference fields.
              if (
                item[activeFilterItem.field].some(
                  (tag) =>
                    tag.entity &&
                    tag.entity.entityId === activeFilterItem.entityId
                )
              ) {
                return true;
              }
            }
          } else {
            for (const activeFilterItem of activeFilter) {
              if (item[activeFilterItem.field] == null) {
                return false;
              }
              // Filtering on string-based fields.
              if (typeof item[activeFilterItem.field] === "string") {
                if (
                  item[activeFilterItem.field] !== activeFilterItem.entityId
                ) {
                  return false;
                }

                continue;
              }

              // Single Entity reference fields.
              if (item[activeFilterItem.field].entity) {
                if (
                  item[activeFilterItem.field].entity.entityId !==
                  activeFilterItem.entityId
                ) {
                  return false;
                }

                continue;
              }

              // Multi Entity reference fields.
              if (
                !item[activeFilterItem.field].some(
                  (tag) =>
                    tag.entity &&
                    tag.entity.entityId === activeFilterItem.entityId
                )
              ) {
                return false;
              }
            }

            return true;
          }
        });
      } else {
        // No active filter.
        filteredItems = this.props.nodes.nodeQuery.entities;
      }

      // Search.
      if (activeSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          const parsedItem = JSON.stringify(item).toLowerCase();

          return parsedItem.includes(activeSearch);
        });
      }
    } else {
      filteredItems = this.props.nodes.nodeQuery.entities;
    }

    if (this.props.content.entityBundle === "participants_overview" && !this.props.dayFilterEnabled) {

      // Filter all "unplanned" items and store them in a variable.
      let tbaContent = filteredItems.filter((item) => {
        return !item.fieldPlanned;
      });

      // remove all unplanned items from filtered content.
      filteredItems = filteredItems.filter((item) => {
        return !!item.fieldPlanned;
      });

      // add unplanned Events to end of filtered Events.
      filteredItems = [...filteredItems, ...tbaContent];
    }

    return filteredItems;
  };

  getItemsToShow = (start, end) => {
    if (
      !this.props.nodes.loading &&
      this.props.nodes.nodeQuery.entities.length >= 1
    ) {
      if (!end) {
        end = this.props.nodes.nodeQuery.entities.length;
      }

      let items = this.filterItems();

      if (this.props.content.fieldPagerVerwenden) {
        return items.slice(start, end);
      }

      /*
       *  Piet is sending us the participant data based on the event, not based on the artist. Therefore we get duplicated content, if an artists is playing several times.
       *  We remove the duplicates when "filtering for all" content, and show the duplicates when its filtered by day.
       *  This ensures that Artists will be visible multiple times, when the Timetable is more "date-oriented" in day-filter mode.
       */
      if (this.props.removeDuplicates) {
        items = filterDuplicates(items);
      }

      return items;
    }

    return [];
  };

  constructor(props) {
    super(props);
    this.state = {
      itemsMatching: this.getItemsToShow(0),
      itemsToShow: this.getItemsToShow(
        0,
        this.props.content.fieldPagerVerwenden
          ? this.props.content.fieldElementeProSeite
          : null
      ),
      activeFilter: [],
      activeSearch: "",
    };

    this.itemSection = React.createRef();
  }

  pushPagerFullPageConfig = () => {
    if (
      this.props.content.fieldPagerAufVollseiten &&
      this.props.nodes.nodeQuery
    ) {
      const pagerFullPagesConfig = {
        id: this.props.content.entityId,
        items: this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.content.fieldLinkZurUebersicht,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  sortByNewest = () => {
    let items = [...this.state.itemsToShow];
    let itemsToShow = items.sort((a, b) =>
      a.created > b.created
        ? 1
        : a.created === b.created
        ? a.created > b.created
          ? 1
          : -1
        : -1
    );
    this.setState({
      itemsToShow,
    });
  };

  sortByAlphabet = () => {
    let items = [...this.state.itemsToShow];
    let itemsToShow = items.sort((a, b) =>
      a.entityLabel > b.entityLabel
        ? 1
        : a.entityLabel === b.entityLabel
        ? a.entityLabel > b.entityLabel
          ? 1
          : -1
        : -1
    );
    this.setState({
      itemsToShow,
    });
  };

  sortBySchedule = () => {
    let items = [...this.state.itemsToShow],
      itemsWithoutDate = [...this.state.itemsToShow],
      itemsWithDate = [];
    // Remove elements without Date from array
    items = items.filter(
      (filterItem) =>
        filterItem.fieldDate !== null && filterItem.fieldDate.value
    );
    itemsWithDate = items.sort((a, b) =>
      a.fieldDate.value > b.fieldDate.value
        ? 1
        : a.fieldDate.value === b.fieldDate.value
        ? a.fieldDate.value > b.fieldDate.value
          ? 1
          : -1
        : -1
    );
    // Get items without a set date to add them to the sorted items
    itemsWithoutDate = itemsWithoutDate.filter(
      (filterItem) => filterItem.fieldDate === null
    );

    // concat items with dates and items without
    this.setState({
      itemsToShow: [...itemsWithDate, ...itemsWithoutDate],
    });
  };

  forcedSort = () => {
    if (this.props.forcedSort === "newest") {
      this.sortByNewest();
    }
    if (this.props.forcedSort === "alphabetically") {
      this.sortByAlphabet();
    }
    if (this.props.forcedSort === "schedule") {
      this.sortBySchedule();
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
    if (this.props.forcedSort) {
      this.forcedSort();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.content.fieldPagerVerwenden
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      });
      this.pushPagerFullPageConfig();
    }
    if (this.props.forcedSort !== prevProps.forcedSort) {
      this.forcedSort();
    }
  }

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      this.state.activeFilter.filter(
        (item) =>
          item.entityId === activeFilter.entityId &&
          item.field === activeFilter.field
      ).length > 0
    ) {
      this.setState({ activeFilter: null }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState(
        {
          activeFilter: {
            ...this.state.activeFilter,
            activeFilter,
          },
        },
        () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.content.fieldPagerVerwenden
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
      );
    }
  };

  changeActiveFilterCloud = (activeFilter) => {
    // Check if it is a filter or a reset call
    if (activeFilter === "reset") {
      this.setState({ activeFilter: [] }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      // First check "only one filter at a time" option.
      if (this.props.filterCloudFilterSingle) {
        if (
          this.state.activeFilter.length > 0 &&
          this.state.activeFilter[0].entityId === activeFilter.entityId
        ) {
          this.setState({ activeFilter: [] }, () =>
            this.setState({
              itemsMatching: this.getItemsToShow(0),
              itemsToShow: this.getItemsToShow(
                0,
                this.props.content.fieldPagerVerwenden
                  ? this.props.content.fieldElementeProSeite
                  : null
              ),
            })
          );
        } else {
          this.setState(
            {
              activeFilter: [activeFilter],
            },
            () =>
              this.setState({
                itemsMatching: this.getItemsToShow(0),
                itemsToShow: this.getItemsToShow(
                  0,
                  this.props.content.fieldPagerVerwenden
                    ? this.props.content.fieldElementeProSeite
                    : null
                ),
              })
          );
        }

        return;
      }

      if (
        this.state.activeFilter &&
        this.state.activeFilter.filter(
          (item) =>
            item.entityId === activeFilter.entityId &&
            item.field === activeFilter.field
        ).length > 0
      ) {
        // Remove filter from active filters.
        const newActiveFilter = this.state.activeFilter.filter(
          (item) =>
            item.entityId !== activeFilter.entityId ||
            item.field !== activeFilter.field
        );
        this.setState({ activeFilter: newActiveFilter }, () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.content.fieldPagerVerwenden
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
        );
      } else {
        // Add filter to active filters.
        this.setState(
          { activeFilter: [...this.state.activeFilter, activeFilter] },
          () =>
            this.setState({
              itemsMatching: this.getItemsToShow(0),
              itemsToShow: this.getItemsToShow(
                0,
                this.props.content.fieldPagerVerwenden
                  ? this.props.content.fieldElementeProSeite
                  : null
              ),
            })
        );
      }
    }
  };

  changeActiveSearch = (searchValue) => {
    if (typeof searchValue === "undefined") {
      this.setState({ activeSearch: "" }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeSearch: searchValue.toLowerCase() }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
      window.scroll(0, 0);
    }
  };

  handlePageClick = (data) => {
    this.setState(
      {
        itemsToShow: this.getItemsToShow(
          data.selected * this.props.content.fieldElementeProSeite,
          data.selected * this.props.content.fieldElementeProSeite +
            this.props.content.fieldElementeProSeite
        ),
      },
      () => scroll.scrollTo(this.itemSection.current.offsetTop)
    );
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-extended-teaser-overview": true,
      [`type-${
        this.props.content.fieldTypExtended
          ? this.props.content.fieldTypExtended
          : this.props.content.entityBundle.replace("_overview", "")
      }`]: true,
    });

    return (
      <section className={sectionClassNames} ref={this.itemSection}>
        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldFilterwolke &&
          this.props.content.fieldParticipantType !== "speaker" && (
            <ErrorBoundary>
              <ComponentFilterCloud
                items={this.props.nodes.nodeQuery.entities}
                filter={this.props.filterCloudFilters}
                labels={this.props.filterCloudLabels}
                activeFilter={this.state.activeFilter}
                filterItems={this.filterItems}
                filterDisplayType={this.props.filterDisplayType}
                preventEmptyResult={this.props.filterCloudPreventEmptyResult}
                changeActiveFilter={this.changeActiveFilterCloud}
                itemsMatchingAmmount={this.state.itemsMatching.length}
                changeActiveSearch={this.changeActiveSearch}
              />
            </ErrorBoundary>
          )}
        {this.state.itemsToShow && this.state.itemsToShow.length >= 1 ? (
          <div className="all-teasers">
            {/* Show Speakers when user selects speaker overview */}
            {this.props.content.entityBundle === "participants_overview" &&
              this.props.nodes.nodeQuery &&
              this.props.nodes.nodeQuery.entities &&
              this.props.content.fieldParticipantType === "speaker" && (
                <SpeakerOverview
                  /*
                   * Filters out `null` items - there was one case where the
                   * german translation run, but not the english.
                   * On the english page, there were also `null` items - which were
                   * the not translated entities (no en translation)
                   */
                  nodes={this.props.nodes.nodeQuery.entities.filter(
                    (item) => item
                  )}
                  skipQuery={true}
                  showBanners={true}
                  updateUrl={true}
                  type={this.props.content.fieldParticipantType}
                  mainSettings={this.props.mainSettings}
                />
              )}
            {/* Show Speakers when user selects artist overview or full programme (in this case: not speaker) */}
            {((this.props.content.entityBundle === "participants_overview" &&
              this.props.content.fieldParticipantType !== "speaker") ||
              this.props.content.entityBundle === "conference_overview") && (
              <ProgrammeOverview
                nodes={this.state.itemsToShow}
                skipQuery={true}
                showBanners={true}
                updateUrl={true}
                dayFilterEnabled={this.props.dayFilterEnabled}
                overviewType={
                  this.props.content.fieldParticipantType === "act"
                    ? "artists-overview"
                    : "programme-overview"
                }
                type={
                  this.props.content.fieldParticipantType
                    ? this.props.content.fieldParticipantType
                    : "conference"
                }
                mainSettings={this.props.mainSettings}
              />
            )}
            {this.props.content.entityBundle === "location_overview" && (
              <LocationOverview
                nodes={this.state.itemsToShow}
                skipQuery={true}
              />
            )}
            {this.props.content.entityBundle !== "location_overview" &&
              this.props.content.entityBundle !== "participants_overview" &&
              this.props.content.entityBundle !== "conference_overview" && (
                <div className="container">
                  <div className={`row ${this.props.content.fieldDarstellung}`}>
                    <>
                      {this.state.itemsToShow.map((item, index) => (
                        <React.Fragment key={index}>
                          {(() => {
                            switch (item.entityBundle) {
                              case "news":
                                return (
                                  <div className="col-16 col-md-4 offset-md-1">
                                    <ErrorBoundary>
                                      <TeaserNews
                                        item={item}
                                        pagerFullPage={
                                          this.props.content
                                            .fieldPagerAufVollseiten
                                            ? this.props.content.entityId
                                            : false
                                        }
                                      />
                                    </ErrorBoundary>
                                  </div>
                                );
                              case "participant":
                                return (
                                  <ErrorBoundary>
                                    <TeaserParticipant
                                      item={item}
                                      index={index}
                                      updateUrl={true}
                                      dayFilterEnabled={this.props.dayFilterEnabled}
                                      pagerFullPage={
                                        this.props.content
                                          .fieldPagerAufVollseiten
                                          ? this.props.content.entityId
                                          : false
                                      }
                                    />
                                  </ErrorBoundary>
                                );
                              case "location":
                                return (
                                  <ErrorBoundary>
                                    <TeaserLocation
                                      item={item}
                                      index={index}
                                      updateUrl={true}
                                      pagerFullPage={
                                        this.props.content
                                          .fieldPagerAufVollseiten
                                          ? this.props.content.entityId
                                          : false
                                      }
                                    />
                                  </ErrorBoundary>
                                );
                              case "person":
                                return (
                                  <div className="col-16 col-md-8">
                                    <ErrorBoundary>
                                      <TeaserPersonOverview
                                        item={item}
                                        pagerFullPage={
                                          this.props.content
                                            .fieldPagerAufVollseiten
                                            ? this.props.content.entityId
                                            : false
                                        }
                                      />
                                    </ErrorBoundary>
                                  </div>
                                );
                              case "projekt":
                                return (
                                  <div
                                    className={classNames({
                                      "col-16 col-md-custom":
                                        (index === 0 ||
                                          (index + 1) % 7 !== 0) &&
                                        this.props.content.fieldDarstellung ===
                                          "small_highlighted",
                                      "col-16 col-md-custom-highlighted":
                                        index !== 0 &&
                                        (index + 1) % 7 === 0 &&
                                        this.props.content.fieldDarstellung ===
                                          "small_highlighted",
                                      "col-md-5":
                                        this.props.content.fieldDarstellung ===
                                          "small_big" &&
                                        (index === 0 ||
                                          (index % 4 === 0 &&
                                            (index - 2) % 2 === 0) ||
                                          ((index + 1) % 4 === 0 &&
                                            (index - 1) % 2 === 0)),
                                      "col-16": true,
                                    })}
                                  >
                                    <ErrorBoundary>
                                      <TeaserProjekt
                                        item={item}
                                        pagerFullPage={
                                          this.props.content
                                            .fieldPagerAufVollseiten
                                            ? this.props.content.entityId
                                            : false
                                        }
                                      />
                                    </ErrorBoundary>
                                  </div>
                                );
                              case "event":
                                return <TeaserEvent item={item} />;
                              default:
                                return null;
                            }
                          })()}
                          {/*
                           * Load a Break Banner every 10th participant
                           */}
                          {/*this.props.content.entityBundle === "participants_overview" && ((index + 1) % 10) === 0 &&
                          <>
                            {this.props.mainSettings.fieldParticipantBreaks && this.props.mainSettings.fieldParticipantBreaks[((index + 1) / 10) - 1] &&
                              <BreakBanner
                                content={this.props.mainSettings.fieldParticipantBreaks[((index + 1) / 10) - 1].entity}/>
                            }
                          </>
                        */}
                        </React.Fragment>
                      ))}
                    </>
                  </div>
                </div>
              )}
          </div>
        ) : (
          <>
            {this.props.nodes.loading ? (
              <div className="loading-wrapper">
                <LoadingIndicator fullPage={true} />
              </div>
            ) : (
              <div className="no-results">
                <h2>
                  <FormattedMessage id="no.results" />
                </h2>
              </div>
            )}
          </>
        )}

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery &&
          this.props.nodes.nodeQuery.entities.length >
            this.props.content.fieldElementeProSeite &&
          this.props.content.fieldPagerVerwenden && (
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <div className="pagination">
                    <ErrorBoundary>
                      <ReactPaginate
                        onPageChange={this.handlePageClick}
                        pageCount={Math.ceil(
                          this.state.itemsMatching.length /
                            this.props.content.fieldElementeProSeite
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        previousLabel={"Vorherige Seite"}
                        nextLabel={"Nächste Seite"}
                        previousClassName="btn btn-primary previous"
                        nextClassName="btn btn-primary next"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphExtendedTeaserOverview.propTypes = {
  /**
   * The filters to use. Can be any fieldName of the items.
   */
  filterCloudFilters: PropTypes.arrayOf(PropTypes.string),
  /**
   * The labels to use for the filters. If not set, nothing will be displayed.
   */
  filterCloudLabels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Set to true, if only one filter at a time should be active.  If set to
   * false, multiple filters can be active at the same time.
   */
  filterCloudFilterSingle: PropTypes.bool,
  /**
   * How should multiple filters be applied? AND or OR condition?
   */
  filterCloudMultipleFilterCondition: PropTypes.oneOf(["AND", "OR"]),
  /**
   * Set to true, if empty results should be prevented. (Filters that would lead
   * to an empty result, are hidden)
   */
  filterCloudPreventEmptyResult: PropTypes.bool,
  forcedSort: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(["newest", "alphabetically", "schedule"]),
  ]),
  mainSettings: PropTypes.oneOfType([PropTypes.bool, MainSettingsProps]),
  content: PropTypes.shape({
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    // Macht es nur komplizierter immer verschiedene feld-namen zu verwenden!
    fieldParticipantType: PropTypes.oneOf([
      "act",
      "Speaker",
      "speaker",
      "projekt",
      "participant",
      "veranstaltung",
      "programme",
    ]),
    filterDisplayType: PropTypes.oneOf(["top", "overlay"]),
    fieldTypExtended: PropTypes.oneOf([
      "act",
      "speaker",
      "projekt",
      "participant",
      "veranstaltung",
      "location",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    removeDuplicates: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
  skipQuery: PropTypes.bool,
  removeDuplicates: PropTypes.bool,
};

/* TODO: Create own components for extended teaser paragraphs and add own
 *   queries for each component to prevent this chaos.
 * TODO: Refactor all teaserNodeQueries - work with fragments and just make
 *  the conditions and sorting unique for the queries. */
export default connect(mapStateToProps)(
  compose(
    graphql(teaserNodeQueryFilterTag, {
      name: "nodes",
      skip: (props) => props.content.fieldManuelleSortierung || props.skipQuery,
      options: (props) => ({
        variables: {
          limit: 10000,
          type: props.content.fieldTypExtended
            ? [props.content.fieldTypExtended]
            : ["participant"],
          sortField:
            props.content.entityBundle === "location_overview" ||
            props.content.fieldParticipantType === "act"
              ? "title"
              : props.content.fieldParticipantType === "programme"
              ? "field_date.value"
              : "created",
          sortDirection:
            props.content.entityBundle === "location_overview" ||
            props.content.fieldParticipantType === "act" ||
            props.content.fieldParticipantType === "programme"
              ? "ASC"
              : "DESC",
          tag:
            props.content.fieldSchlagwort &&
            props.content.fieldSchlagwort.map((item) =>
              item.targetId.toString()
            ),
          filterTagEnabled:
            props.content.fieldSchlagwort &&
            props.content.fieldSchlagwort.length > 0,
          participantTypeFilter:
            props.content.fieldParticipantType &&
            props.content.fieldParticipantType === "speaker"
              ? "Speaker"
              : "Act",
          participantTypeFilterEnabled:
            typeof props.content.fieldParticipantType !== "undefined" &&
            props.content.fieldParticipantType !== null,
          categoryFilter: 136,
          categoryFilterEnabled: props.content.fieldParticipantType === "act",
          language: props.currentLanguage.toUpperCase(),
          dayFilterStart: props.dayFilterStart,
          dayFilterEnd: props.dayFilterEnd,
          dayFilterEnabled: props.dayFilterEnabled,
          speakerTypeFilter: props.content.fieldSpeakerType,
          speakerTypeFilterEnabled: !!props.content.fieldSpeakerType,
          yearFilterEnabled: !!props.content.fieldYearFilter,
          yearFilter: moment(props.content.fieldYearFilter).unix().toString(),
          yearFilterEnd: moment((parseInt(props.content.fieldYearFilter) + 1).toString()).unix().toString(),
          tags: props.content.fieldSchlagwort
            ? props.content.fieldSchlagwort.map((item) =>
                item.targetId.toString()
              )
            : [""],
        },
      }),
    }),
    graphql(teaserNodeQueryEventTagFiltered, {
      name: "nodes",
      skip: (props) =>
        props.content.fieldManuelleSortierung ||
        props.skipQuery ||
        props.content.entityBundle !== "conference_overview",
      options: (props) => ({
        variables: {
          date: moment().format("YYYY-MM-DD"),
          limit: 10000,
          type: ["event"],
          tags: props.content.fieldSchlagwort
            ? props.content.fieldSchlagwort.map((item) =>
                item.targetId.toString()
              )
            : [""],
          filterTagEnabled: props.content.fieldSchlagwort
            ? props.content.fieldSchlagwort.length > 0
            : false,
          dayFilterStart: props.dayFilterStart,
          dayFilterEnd: props.dayFilterEnd,
          dayFilterEnabled: props.dayFilterEnabled,
          yearFilterEnabled: !!props.content.fieldYearFilter,
          yearFilter: moment(props.content.fieldYearFilter).unix().toString(),
          yearFilterEnd: moment((parseInt(props.content.fieldYearFilter) + 1).toString()).unix().toString(),
          language: props.currentLanguage.toUpperCase(),
        },
      }),
    })
  )(ParagraphExtendedTeaserOverview)
);
