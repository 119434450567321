import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { encodeUrl } from "../../../lib/encode-url";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import LazyLoad from "react-lazyload";
import { calculateCollapseHeight } from "../../../lib/calculate-collape-height";
import { getMapsLink } from "../../../lib/get-maps-link";
import { isIOS, isMacOs, isMobile } from "react-device-detect";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile,
});

const openLocationLink = ({ name, address, geo, fallback }) => {
  if (!!fallback) {
    return window.open(fallback, "_blank");
  }

  if (isIOS || isMacOs) {
    return window.open(
      getMapsLink(name, address, geo, { platform: "apple" }),
      "_blank"
    );
  }

  if (isMobile) {
    return window.open(
      getMapsLink(name, address, geo, { platform: "android" }),
      "_blank"
    );
  }
  return window.open(getMapsLink(name, address, geo), "_blank");
};

const TeaserTags = (props) => {
  return (
    <div className="tag-wrapper">
      {props.properties &&
        props.properties.map((property) => (
          <div className="tag" key={property.entity.entityId}>
            {property.entity.name}
          </div>
        ))}
      {props.capacity && props.capacity > 0 && props.capacity !== "0" && (
        <div className="tag">{props.capacity}P</div>
      )}
    </div>
  );
};

TeaserTags.propTypes = {
  properties: PropTypes.array,
  capacity: PropTypes.number,
};

class TeaserLocation extends Component {
  static defaultProps = { pagerFullPage: false };

  teaser = React.createRef();

  constructor(props) {
    super(props);

    let preExpandedItem = null;

    if (
      this.props.item &&
      encodeUrl(this.props.item.title) ===
        this.props.location.hash.replace("#", "")
    ) {
      preExpandedItem = this.props.item.entityId;
    }

    this.state = {
      preExpandedItem,
      initial: true,
    };
  }

  updateAccordion = () => {
    if (this.props.updateUrl) {
      let scrollPosition =
        this.teaser.current.getBoundingClientRect().top + window.scrollY - 150;

      history.pushState(
        null,
        window.location.pathname,
        `#${encodeUrl(this.props.item.title)}`
      );

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  componentDidMount() {
    // Timeout is only set because the "todoerror" below forces a re rendering, can be removed after error is fixed
    setTimeout(() => {
      calculateCollapseHeight(this.teaser.current);
    }, 500);

    if (
      this.state.initial &&
      this.props.item &&
      encodeUrl(this.props.item.title) ===
        this.props.location.hash.replace("#", "")
    ) {
      setTimeout(() => {
        this.updateAccordion();
        this.setState({
          initial: false,
        });
      }, 500);
    }
  }

  render() {
    {
      /* @todo: prop types & Stories finalization */
    }

    return (
      <article
        className={`node node-teaser teaser-location location-element ${this.props.item.fieldImage ? 'with-image' : 'no-image'}`}
        ref={this.teaser}
        id={encodeUrl(this.props.item.title)}
        data-to-scrollspy-id={encodeUrl(this.props.item.title)}
        data-teaser-index={this.props.index}
      >
        <Suspense fallback={<div></div>}>
          <Accordion
            allowZeroExpanded={true}
            onChange={() => this.updateAccordion()}
            preExpanded={[this.state.preExpandedItem]}
          >
            <AccordionItem uuid={this.props.item.entityId}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {this.props.item.fieldImage && this.props.isMobile && (
                    <div className="image">
                      <LazyLoad offset={1000}>
                        {this.props.item.fieldImage && (
                          <Image
                            data={
                              this.props.item.fieldImage.entity.fieldMediaImage
                            }
                            nodeTitle={this.props.item.title}
                          />
                        )}
                      </LazyLoad>
                    </div>
                  )}
                  <div className="name-wrapper infos">
                    {this.props.item.title && (
                      <h2 className="name">{this.props.item.title}</h2>
                    )}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <EditButton
                  adminApp={this.props.adminApp}
                  entityId={this.props.item.entityId}
                  destinationRoute={this.props.location.pathname}
                />
                <div className="inner-wrapper content-wrapper">
                  {(this.props.item.fieldLocationProperties ||
                    this.props.item.fieldCapacity) && (
                    <TeaserTags
                      properties={this.props.item.fieldLocationProperties}
                      capacity={
                        this.props.item.fieldCapacity > 0
                          ? this.props.item.fieldCapacity
                          : null
                      }
                    />
                  )}
                  {this.props.item.fieldTeasertext && (
                    <div
                      className="teaser text"
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.fieldTeasertext.processed,
                      }}
                    />
                  )}
                  <a
                    className="maps-link"
                    onClick={() =>
                      openLocationLink({
                        lat: this.props.item.fieldGeolocation.lat,
                        lng: this.props.item.fieldGeolocation.lng,
                        name: this.props.item.title,
                      })
                    }
                  >
                    Find me on Maps
                  </a>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Suspense>
      </article>
    );
  }
}

export const TeaserLocationPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  fieldCapacity: PropTypes.number,
  fieldGeolocation: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string,
  }),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldImage: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldLocationProperties: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
        entityId: PropTypes.string,
      }),
    })
  ),
});

TeaserLocation.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: TeaserLocationPropTypes,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  updateUrl: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserLocation));
