import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import EditButton from "../../../backend/edit-button";
import { Link as ReactRouterLink } from "react-router-dom";

import Image from "../../image/image";
import { encodeUrl } from "../../../lib/encode-url";
import DateWrapper from "./components/date-wrapper";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile,
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage
});

class TeaserParticipantLink extends Component {
  static defaultProps = { pagerFullPage: false };

  teaser = React.createRef();

  render() {
    return (
      <>
        <article
          className={`node node-teaser teaser-participant programme-element ${
            this.props.item.fieldImage ? "with-image" : "no-image"
          }`}
          ref={this.teaser}
          id={encodeUrl(this.props.item.title)}
          data-to-scrollspy-id={encodeUrl(this.props.item.title)}
          data-teaser-index={this.props.index}
          data-testid="teaser-participant"
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <ReactRouterLink to={`/${this.props.currentLanguage}/festival/${encodeUrl(this.props.item.title)}`}>
            <div className="row">
              {/* Image only needed for: Speaker Overview and mobile */}
              {this.props.item.fieldImage && this.props.isMobile && (
                <div className="col-8 image">
                  <LazyLoad offset={1000}>
                    <Image
                      data={this.props.item.fieldImage.entity.fieldMediaImage}
                      nodeTitle={this.props.item.title}
                    />
                  </LazyLoad>
                </div>
              )}
              <div className="col-8 col-lg-16 infos">
                <div className="row">
                  <div className="col-16 col-lg-4 date-col">
                    <DateWrapper
                      content={this.props.item}
                      showTBA={false}
                      releaseState={this.props.mainSettings.fieldReleaseState}
                    />
                  </div>
                  <div className="col-16 col-lg-12 info-col">
                    {this.props.item.fieldLocationName && (
                      <div className="meta-infos">
                        {this.props.item.fieldLocationName && this.props.mainSettings.fieldReleaseState === "all" && (
                          <div className="location">
                            {this.props.item.fieldLocationName}
                          </div>
                        )}
                      </div>
                    )}
                    <h2 className="name">{this.props.item.title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </ReactRouterLink>
        </article>
      </>
    );
  }
}

export const teaserParticipantPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  fieldCountry: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldText: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldGenre: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldCategory: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  fieldImage: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldLocationName: PropTypes.string,
  fieldDate: PropTypes.shape({
    value: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  fieldMainPresenterCompany: PropTypes.string,
  fieldType: PropTypes.string,
  fieldMainPresenterWeb: PropTypes.shape({
    uri: PropTypes.string,
  }),
  fieldDeezer: PropTypes.shape({
    uri: PropTypes.string,
  }),
});

TeaserParticipantLink.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserParticipantPropTypes,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  updateUrl: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(TeaserParticipantLink));
