import PropTypes from 'prop-types';

export const MainSettingsProps = PropTypes.shape({
  fieldParticipantBreaks: PropTypes.arrayOf(PropTypes.shape({
    entity: PropTypes.shape({
      fieldHeading: PropTypes.string,
      fieldSelectedParticipants: PropTypes.arrayOf(
        PropTypes.shape({
          entity: PropTypes.shape({
            entityId: PropTypes.string,
          }),
        }),
      ),
    }),
  }),)
});