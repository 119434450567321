import React, { Component } from "react";

import initialization from "./initialization";
import PropTypes from "prop-types";

export default class MorphSlider extends Component {
  morphSlider = React.createRef();

  componentDidMount() {
    if (typeof window !== "undefined") {
      initialization(
        this.morphSlider.current,
        this.props.id,
        this.props.isMobile
      );
    }
  }
  render() {
    let images = this.props.content;
    //let images = [];
    //images.push(`${self}/glitch-test/glitch-test-1.jpg`, `${self}/glitch-test/glitch-test-2.jpg`, `${self}/glitch-test/glitch-test-3.jpg`);

    return (
      <div className="morph-slider" ref={this.morphSlider}>
        {this.props.content && this.props.content && (
          <div
            id={this.props.id}
            data-images={JSON.stringify(images)}
            data-displacement=""
            className="canvas-wrapper"
          ></div>
        )}
      </div>
    );
  }
}

MorphSlider.propTypes = {
  content: PropTypes.array,
  id: PropTypes.string,
  isMobile: PropTypes.bool,
};
