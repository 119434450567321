import React, { Component } from "react";
import PropTypes from "prop-types";

class Video extends Component {
  render() {
    return (
      <div className="video-wrapper">
        <div className="container">
          <div className="row">
            {this.props.text && typeof this.props.text.value !== "undefined" && (
              <div className="col-16">
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: this.props.text.value }}
                />
              </div>
            )}
            {this.props.url && (
              <div className="col-16">
                <video
                  autoPlay
                  controls={false}
                  muted={true}
                  playsInline
                  loop={true}
                  src={this.props.url}
                  type="video/mp4"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Video.propTypes = {
  text: PropTypes.object,
  url: PropTypes.object.isRequired,
};

export default Video;
