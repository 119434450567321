import React from "react";
import ErrorBoundary from "../../../../error-boundary";

import { encodeUrl } from "../../../../lib/encode-url";
import { Link as ReactRouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DateWrapper from "../../participant/components/date-wrapper";
import {useSelector} from "react-redux";

const LinkedEvents = (props) => {
  const reduxStore = useSelector((state) => ({
    currentLanguage: state.i18n.currentLanguage
  }));

  let linkedEvents = props.content.fieldNetworkingEvents && props.content.fieldNetworkingEvents.filter(
    (event) => event.entity.status === true
  );

  if (props.content.fieldNetworkingEvents && linkedEvents && linkedEvents.length > 0) {
    return (
      <ErrorBoundary>
        <div className="linked-events">
          {linkedEvents.map((item, index) => (
            <article className="linked-event" key={index}>
              <ReactRouterLink
                to={`/${reduxStore.currentLanguage}/${reduxStore.currentLanguage === "de" ? "konferenz" : "conference"}/${encodeUrl(item.entity.entityLabel)}`}
              >
                {item.entity.fieldDate && (
                  <ErrorBoundary>
                    <DateWrapper
                      content={item.entity}
                      releaseState={
                        props.releaseState
                      }
                    />
                  </ErrorBoundary>
                )}
                <div className="event-name">{item.entity.entityLabel}</div>
                {item.entity.fieldLocationName && (
                  <div className="location">{item.entity.fieldLocationName}</div>
                )}
              </ReactRouterLink>
            </article>
          ))}
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default LinkedEvents;
