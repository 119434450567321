import React, { Component } from "react";
import PropTypes from "prop-types";

class Image extends Component {
  render() {
    return (
      <div className="image-wrapper">
        <div className="container">
          <div className="row">
            {this.props.text && typeof this.props.text.value !== "undefined" && (
              <div className="col-16">
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: this.props.text.value }}
                />
              </div>
            )}
            {this.props.url && (
              <div className="col-16">
                <img src={this.props.url} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Image.propTypes = {
  text: PropTypes.object,
  url: PropTypes.object.isRequired,
};

export default Image;
