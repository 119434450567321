export const states = (states, values) => {
  let invisible = false;
  let visible = true;
  let enabled = true;
  let disabled = false;
  let optional = false;
  let required = false;

  if (states) {
    for (const stateName in states) {
      if (states[stateName] && stateName !== "__typename") {
        const state = states[stateName];
        let conditionEvaluation = [];

        switch (stateName) {
          case "visible":
          case "enabled":
          case "disabled":
          case "optional":
          case "required":
          case "invisible": {
            state.conditions.forEach((item) => {
              switch (item.trigger) {
                case "EMPTY": {
                  if (values[item.field] === "") {
                    conditionEvaluation = [...conditionEvaluation, true];
                  } else {
                    conditionEvaluation = [...conditionEvaluation, false];
                  }

                  break;
                }
                case "CHECKED": {
                  // Single Checkbox.
                  if (typeof values[item.field] === "boolean") {
                    conditionEvaluation = [
                      ...conditionEvaluation,
                      values[item.field],
                    ];
                  } else {
                    if (values[item.field].includes(item.fieldValue)) {
                      conditionEvaluation = [...conditionEvaluation, true];
                    } else {
                      conditionEvaluation = [...conditionEvaluation, false];
                    }
                  }

                  break;
                }
                case "UNCHECKED": {
                  if (!values[item.field].includes(item.fieldValue)) {
                    conditionEvaluation = [...conditionEvaluation, true];
                  } else {
                    conditionEvaluation = [...conditionEvaluation, false];
                  }

                  break;
                }
                case "FILLED": {
                  if (values[item.field] !== "") {
                    conditionEvaluation = [...conditionEvaluation, true];
                  } else {
                    conditionEvaluation = [...conditionEvaluation, false];
                  }

                  break;
                }
                case "VALUE_IS": {
                  if (values[item.field] === item.fieldValue) {
                    conditionEvaluation = [...conditionEvaluation, true];
                  } else {
                    conditionEvaluation = [...conditionEvaluation, false];
                  }

                  break;
                }
                case "VALUE_IS_NOT": {
                  if (values[item.field] !== item.fieldValue) {
                    conditionEvaluation = [...conditionEvaluation, true];
                  } else {
                    conditionEvaluation = [...conditionEvaluation, false];
                  }

                  break;
                }
              }
            });
            break;
          }
        }

        switch (state.logic) {
          case "AND": {
            if (stateName === "invisible") {
              invisible =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "visible") {
              visible =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "enabled") {
              enabled =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "disabled") {
              disabled =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "optional") {
              optional =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "required") {
              required =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }

            break;
          }
          case "XOR":
          case "OR": {
            invisible =
              stateName === "invisible"
                ? conditionEvaluation.filter((item) => item).length > 0
                : false;
            visible =
              stateName === "visible"
                ? conditionEvaluation.filter((item) => item).length > 0
                : true;
            enabled =
              stateName === "enabled"
                ? conditionEvaluation.filter((item) => item).length > 0
                : true;
            disabled =
              stateName === "disabled"
                ? conditionEvaluation.filter((item) => item).length > 0
                : false;
            optional =
              stateName === "optional"
                ? conditionEvaluation.filter((item) => item).length > 0
                : true;
            required =
              stateName === "required"
                ? conditionEvaluation.filter((item) => item).length > 0
                : false;

            break;
          }
        }
      }
    }
  }

  if (invisible) {
    visible = false;
  }

  return { invisible, visible, enabled, disabled, optional, required };
};
