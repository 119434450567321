import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import { connect } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { teaserParticipantPropTypes } from "../../../../teaser-base/participant/teaser-participant";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";

import { waitForElm } from "./../../../../../lib/wait-for-elm";
import LazyLoad from "react-lazyload";
import TeaserParticipantMasked from "../../../../teaser-base/participant/teaser-participant-masked";
import { filterDuplicates } from "../../../../../lib/filter-duplicates";

const sliderSettings = {
  adaptiveHeight: false,
  arrows: true,
  centerMode: false,
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  touchMove: false,
  nextArrow: <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />,
  prevArrow: <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />,
};

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  isMobile: reduxStore.appStore.isMobile,
});

class ComponentTeaserlistCarouselMasked extends React.Component {
  state = {
    goForwards: true,
    orientation: "next",
    slides: 0,
    maxWidth: 0,
  };

  carousel = React.createRef();

  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  setMaxWidth = () => {
    let maxWidth = this.carousel.current
      .querySelector(".participant-wrapper .node-teaser:last-child")
      .getBoundingClientRect().right;

    this.carousel.current.style.setProperty(
      "--marquee-endpoint",
      `${-maxWidth + window.innerWidth}px`
    );
    this.setState({
      maxWidth: -maxWidth + window.innerWidth,
    });
  };

  componentDidMount() {
    // Pager on full screen pages.
    //this.pushPagerFullPageConfig();
    if (this.state.maxWidth === 0) {
      if (this.carousel.current) {
        waitForElm(
          ".participant-wrapper .node-teaser:last-child",
          this.carousel.current
        ).then((elm) => {
          this.setMaxWidth();
        });
      } else {
        // Fixing autoplay when there are initialization hickups
        setTimeout(() => {
          waitForElm(
            ".participant-wrapper .node-teaser:last-child",
            this.carousel.current
          ).then((elm) => {
            this.setMaxWidth();
          });
        }, 1500);
      }
    }
  }

  groupByN = (n, arr) => {
    let result = [];
    for (let i = 0; i < arr.length; i += n) {
      result.push(arr.slice(i, i + n));
    }
    return result;
  };

  setCarouselValue = () => {
    //this.carousel.current.style.setProperty('--spin-value', `${(window.innerWidth / 2) * this.state.slideIndex * -1}px`);
    let scrollWrapper =
      this.carousel.current.querySelector(".overflow-wrapper");
    if (scrollWrapper) {
      scrollWrapper.scrollTo({
        left: (window.innerWidth / 2) * this.state.slideIndex,
        behavior: "smooth",
      });
    }
  };

  spinCarousel = (orientation) => {
    // Make sure the spin can start. It can only work if maxWidth is initialized.
    if (this.state.maxWidth === 0) {
      this.setMaxWidth();
    }
    // get transition quotient by getting the scrolled position in this second.
    //@todo: This calculation colud be improved
    let transitionQuotient = Math.abs(
      this.state.goForwards
        ? this.carousel.current
            .querySelector(".participant-wrapper .node-teaser:first-child")
            .getBoundingClientRect().left / this.state.maxWidth
        : 1 -
            this.carousel.current
              .querySelector(".participant-wrapper .node-teaser:first-child")
              .getBoundingClientRect().left /
              this.state.maxWidth
    );

    // cannot be 0! otherwise it just jumps
    if (transitionQuotient === 0) {
      transitionQuotient = 1;
    }
    // Fallback for minimum transition
    if (transitionQuotient < 0.2) {
      transitionQuotient = 0.2;
    }

    if (orientation !== this.state.orientation) {
      this.carousel.current.style.setProperty(
        "--transition-quotient",
        transitionQuotient
      );
    }

    if (orientation === "next") {
      this.setState({
        orientation,
        goForwards: true,
      });
    } else {
      this.setState({
        orientation,
        goForwards: false,
      });
    }
  };

  render() {
    if (this.props.nodes && this.props.nodes.loading) {
      return false;
    }
    let entitiesCount = this.props.count,
      entities = this.props.manualNodes;

    if (this.props.nodesConfig === "Manuell") {
      entitiesCount = this.props.manualNodes.length;
    }

    if (
      this.props.nodesConfig !== "Manuell" &&
      this.props.nodes.nodeQuery &&
      !this.props.nodes.nodeQuery.loading &&
      this.props.nodes.nodeQuery.entities
    ) {
      entities = this.props.nodes.nodeQuery.entities;
      entities = filterDuplicates(entities);
    }

    return (
      <div className="carousel-wrapper" ref={this.carousel}>
        <VisibilitySensor
          partialVisibility={true}
          // This offset ensures that only the centered value is visible.
          offset={{
            top: this.props.isMobile ? 0 : 300,
          }}
          once={true}
        >
          {({ isVisible }) => (
            <div
              className={`scrollspy-wrapper ${
                isVisible ? "active" : "inactive"
              } ${this.state.goForwards ? "to-right" : "to-left"}`}
            >
              <>
                {!this.props.isMobile && (
                  <>
                    {/* @todo: Accesibility */}
                    <button
                      className="carousel-control prev"
                      aria-label={"show prev content"}
                      role={"button"}
                      tabIndex={0}
                      onMouseEnter={() => this.spinCarousel("prev")}
                      disabled={this.state.slideIndex === 0}
                    />
                    <button
                      className="carousel-control next"
                      tabIndex={0}
                      aria-label={"show next content"}
                      onMouseEnter={() => this.spinCarousel("next")}
                      disabled={entitiesCount / 2 === this.state.slideIndex}
                    />
                  </>
                )}
                {/*<ComponentCarouselMaskedAnimation content={entities} goForwards={this.state.goForwards} animationEndpoint={this.state.maxWidth}/>*/}
                <div className="overflow-wrapper">
                  <div className={"participant-wrapper"}>
                    {entities.map((item, index) => {
                      if (!item) {
                        return null;
                      }

                      return (
                        <React.Fragment key={index}>
                          <TeaserParticipantMasked
                            index={index}
                            item={item.entity ? item.entity : item}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            </div>
          )}
        </VisibilitySensor>
      </div>
    );
  }
}

ComponentTeaserlistCarouselMasked.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserParticipantPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf([
    "news",
    "person",
    "veranstaltung",
    "all",
    "participant",
  ]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags.map((item) => item.targetId.toString()),
        filterTagEnabled: props.tags.length > 0,
        participantTypeFilter: props.participantTypeFilter,
        participantTypeFilterEnabled: props.participantTypeFilter !== null,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(ComponentTeaserlistCarouselMasked)
);

export { sliderSettings };
