import React, { Component, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Search, Button } from "carbon-components-react";

const ComponentExtendedTeaserOverviewSearch = (props) => {
  const [content, setContent] = useState('');
  const span = useRef();
  const search = useRef();

  //
  useEffect(() => {
    // Set width of input to width of copied helper
    search.current.input.style.width = `${span.current.offsetWidth + 100}px`;
  }, [content]);

  const changeHandler = event => {
    // Copy the value of input to get its width
    setContent(event.target.value);
    if (typeof event.target.value === "undefined" || event.target.value.length < 3) {
      props.changeActiveSearch();
    } else {
      props.changeActiveSearch(search.current.input.value);
    }
  };

  return (
    <section className="search">
      <span className="search-helper" ref={span}>{content}</span>
      <Search
        ref={search}
        id="search-1"
        placeHolderText="Search"
        size="xl"
        onChange={(event) => changeHandler(event)}
        labelText="Suche..."
      />
    </section>
  );
}

ComponentExtendedTeaserOverviewSearch.propTypes = {
  changeActiveSearch: PropTypes.func.isRequired,
};

export default ComponentExtendedTeaserOverviewSearch;
