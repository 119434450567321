import React, { useRef } from "react";

import ErrorBoundary from "../../../../../error-boundary";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserLocation from "../../../../teaser-base/location/teaser-location";
import Scrollspy from "react-scrollspy";
import TeaserLocationImage from "../../../../teaser-base/location/teaser-location-image";
import { encodeUrl } from "../../../../../lib/encode-url";
import { changeActiveImage } from "../../../../../lib/change-active-image";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  mainSettings: reduxStore.appStore.mainSettings,
  isMobile: reduxStore.appStore.isMobile,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const LocationOverview = (props) => {
  const locationsOverview = useRef();

  let nodes = null;
  if (props.skipQuery) {
    nodes = props.nodes;
  } else if (props.nodes.nodeQuery) {
    nodes = props.nodes.nodeQuery.entities;
  }

  return (
    <div className="locations-overview" ref={locationsOverview}>
      {nodes && (
        <div className="container">
          {props.isMobile ? (
            <div className={"row"}>
              {nodes.map((item, index) => (
                <React.Fragment key={index}>
                  <ErrorBoundary>
                    <TeaserLocation
                      item={item}
                      index={index}
                      updateUrl={true}
                      pagerFullPage={
                        props.content.fieldPagerAufVollseiten
                          ? props.content.entityId
                          : false
                      }
                    />
                  </ErrorBoundary>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="row">
              <div className={"col-8 image-wrapper"}>
                <div className={"movement-wrapper"}>
                  <Scrollspy
                    offset={-350}
                    items={nodes.map((item) => encodeUrl(item.entityLabel))}
                    componentTag={"div"}
                    currentClassName={"is-current"}
                    onUpdate={(el) => {
                      changeActiveImage(el, locationsOverview.current);
                    }}
                  >
                    {nodes.map((item, index) => (
                      <a href={`#${encodeUrl(item.entityLabel)}`} aria-label={item.entityLabel} key={index}>
                        <ErrorBoundary>
                          <span className={"sr-only"}>
                            {item.entityLabel}
                          </span>
                          <TeaserLocationImage
                            item={item}
                            index={index}
                            updateUrl={true}
                            pagerFullPage={
                              props.content.fieldPagerAufVollseiten
                                ? props.content.entityId
                                : false
                            }
                          />
                        </ErrorBoundary>
                      </a>
                    ))}
                  </Scrollspy>
                </div>
              </div>
              <div className={"col-8 teaser-wrapper"}>
                {nodes.map((item, index) => (
                  <React.Fragment key={index}>
                    <ErrorBoundary>
                      <TeaserLocation
                        item={item}
                        index={index}
                        updateUrl={true}
                        pagerFullPage={
                          props.content.fieldPagerAufVollseiten
                            ? props.content.entityId
                            : false
                        }
                      />
                    </ErrorBoundary>
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

LocationOverview.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldPagerAufVollseiten: PropTypes.bool,
    entityId: PropTypes.string,
  }),
  nodes: PropTypes.object,
  skipQuery: PropTypes.bool,
  isMobile: PropTypes.bool,
  currentLanguage: PropTypes.string,
};

LocationOverview.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.skipQuery,
    options: () => ({}),
  })(LocationOverview)
);
