import React, { Component } from "react";
import PropTypes from "prop-types";

import Scrollspy from "react-scrollspy";
import { encodeUrl } from "../../../../lib/encode-url";
import { changeActiveImage } from "../../../../lib/change-active-image";
import ErrorBoundary from "../../../../error-boundary";
import TeaserParticipantImage from "../../../teaser-base/participant/teaser-participant-image";
import TeaserEvent from "../../../teaser-base/event/teaser-event";
import BreakBanner from "../extended-teaser-list/participants/break-banner";
import TeaserParticipant from "../../../teaser-base/participant/teaser-participant";
import { filterDuplicates } from "../../../../lib/filter-duplicates";

import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import { MainSettingsProps } from "../../../../types";
import TeaserParticipantLink from "../../../teaser-base/participant/teaser-participant-link";
import { waitForElm } from "../../../../lib/wait-for-elm";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  mainSettings: reduxStore.appStore.mainSettings,
  isMobile: reduxStore.appStore.isMobile,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ProgrammeOverview extends Component {
  programmeOverview = React.createRef();

  state = {
    scrollOffset: 0,
  };

  getScrollAnchors = (nodes) => {
    // The used Module needs an array with all anchors. However, we cannot just loop through the nodes, since whe intersect them with the participant breaks.
    let anchors = [],
      breaks = this.props.mainSettings.fieldParticipantBreaks;

    if (this.props.type === "conference") {
      breaks = this.props.mainSettings.fieldConferenceBreaks;
    }
    nodes.map((item, index) => {
      anchors.push(
        encodeUrl(item.entity ? item.entity.entityLabel : item.entityLabel)
      );
      if (this.props.showBanners) {
        if ((index + 1) % 10 === 0) {
          if (breaks[(index + 1) / 10 - 1]) {
            anchors.push(
              `${encodeUrl(
                breaks[(index + 1) / 10 - 1].entity.fieldHeading
              )}-${index}`
            );
          }
        }
      }
    });
    return anchors;
  };

  scrollListener = () => {
    if (window.scrollY <= 50) {
      this.setState({
        scrollOffset: 50,
      });
    }
    if (window.scrollY >= 50 && this.state.scrollOffset === 50) {
      this.setState({
        scrollOffset: -1 * (window.innerHeight / 2 - 100),
      });
    }
  };

  componentDidMount() {
    if (!this.state.programmeLoaded) {
      // Only works, if there is a teaser with an actual image.
      if (this.programmeOverview.current.querySelector(".node-teaser img")) {
        waitForElm(".node-teaser img", this.programmeOverview.current).then(
          (elm) => {
            setTimeout(() => {
              this.setState({
                programmeLoaded: true,
              });
            }, 150);
          }
        );
      } else {
        this.setState({
          programmeLoaded: true,
        });
      }
    }
    if (this.props.updateUrl) {
      window.addEventListener("scroll", () => this.scrollListener());
    }
  }

  render() {
    /* @todo: get this out of the render function */
    let nodes = [],
      preparedNodes = [],
      breaks = [];

    /* Manual and automatic nodes, needed for use in lists */
    if (this.props.skipQuery) {
      if (this.props.manualNodes) {
        nodes = this.props.manualNodes;
      } else {
        nodes = this.props.nodes;
      }
    } else if (this.props.nodes && this.props.nodes.nodeQuery) {
      nodes = this.props.nodes.nodeQuery.entities;
      nodes = filterDuplicates(nodes);
    }

    /* Check if there are breaks filled in main settings config and determine the type */
    if (this.props.type === "conference") {
      breaks = this.props.mainSettings.fieldConferenceBreaks;
    } else {
      breaks = this.props.mainSettings.fieldParticipantBreaks;
    }

    nodes.map((item, index) => {
      preparedNodes.push(
        <a
          href={`#${encodeUrl(
            item.entity ? item.entity.entityLabel : item.entityLabel
          )}`}
          key={index}
          className={"scrollspy-anchor"}
          aria-hidden="true"
          tabIndex="-1"
          aria-label={`link: ${item.entity ? item.entity.title : item.title}`}
        >
          {(() => {
            switch (
              item.entity ? item.entity.entityBundle : item.entityBundle
            ) {
              case "participant":
                return (
                  <ErrorBoundary>
                    <title className={""}>
                      {item.entity ? item.entity.title : item.title}
                    </title>
                    <TeaserParticipantImage
                      item={item.entity ? item.entity : item}
                      index={index}
                      updateUrl={true}
                      useLazyload={this.props.displayType !== "list"}
                      pagerFullPage={
                        this.props.content.fieldPagerAufVollseiten
                          ? this.props.content.entityId
                          : false
                      }
                    />
                  </ErrorBoundary>
                );
              default:
                return null;
            }
          })()}
        </a>
      );
      // Push Breaks every 10th element
      if (this.props.showBanners && breaks && breaks[(index + 1) / 10 - 1]) {
        preparedNodes.push(
          <a
            href={`${encodeUrl(
              breaks[(index + 1) / 10 - 1].entity.fieldHeading
            )}-${index}`}
            key={index}
          >
            {breaks && breaks[(index + 1) / 10 - 1] && (
              <BreakBanner
                content={breaks[(index + 1) / 10 - 1].entity}
                id={index}
                type={this.props.type}
              />
            )}
          </a>
        );
      }
    });

    return (
      <div
        className={`programme-overview ${
          this.state.programmeLoaded ? "loaded" : "loading"
        }`}
        ref={this.programmeOverview}
      >
        {/*<LoadingIndicator fullpage={true}/>*/}
        {/* Lines shown in list view */}
        <div className="line top" />
        {preparedNodes && nodes && (
          <div className="container content">
            {this.props.isMobile || this.props.type === "conference" ? (
              <>
                {nodes.map((item, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (item.entityBundle) {
                        case "participant":
                          if (
                            this.props.clickBehavior &&
                            this.props.clickBehavior === "link"
                          ) {
                            return (
                              <ErrorBoundary>
                                <TeaserParticipantLink
                                  item={item.entity ? item.entity : item}
                                  index={index}
                                  updateUrl={this.props.updateUrl}
                                  pagerFullPage={
                                    this.props.content.fieldPagerAufVollseiten
                                      ? this.props.content.entityId
                                      : false
                                  }
                                />
                              </ErrorBoundary>
                            );
                          } else {
                            return (
                              <ErrorBoundary>
                                <TeaserParticipant
                                  item={item.entity ? item.entity : item}
                                  index={index}
                                  updateUrl={true}
                                  overviewType={this.props.overviewType}
                                  dayFilterEnabled={this.props.dayFilterEnabled}
                                  pagerFullPage={
                                    this.props.content.fieldPagerAufVollseiten
                                      ? this.props.content.entityId
                                      : false
                                  }
                                />
                              </ErrorBoundary>
                            );
                          }
                        case "event":
                          return (
                            <ErrorBoundary>
                              <TeaserEvent
                                item={item.entity ? item.entity : item}
                                index={index}
                                updateUrl={this.props.updateUrl}
                                pagerFullPage={
                                  this.props.content.fieldPagerAufVollseiten
                                    ? this.props.content.entityId
                                    : false
                                }
                              />
                            </ErrorBoundary>
                          );
                        default:
                          return null;
                      }
                    })()}
                    {/*
                     * Load a Break Banner every 10th participant
                     */}
                    {this.props.showBanners && (index + 1) % 10 === 0 && (
                      <>
                        {breaks && breaks[(index + 1) / 10 - 1] && (
                          <div
                            className="col-16 break-teaser"
                            data-to-scrollspy-id={`${encodeUrl(
                              breaks[(index + 1) / 10 - 1].entity.fieldHeading
                            )}-${index}`}
                          >
                            <h2>
                              {breaks[(index + 1) / 10 - 1].entity.fieldHeading}
                            </h2>
                            {this.props.isMobile &&
                              breaks &&
                              breaks[(index + 1) / 10 - 1] && (
                                <BreakBanner
                                  content={breaks[(index + 1) / 10 - 1].entity}
                                  id={index}
                                  type={this.props.type}
                                />
                              )}
                          </div>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <div className={`row ${this.props.displayType}`}>
                <div className="col-8 image-wrapper">
                  {nodes.length > 1 ? (
                    <div className="absolute-wrapper">
                      <div className="sticky-wrapper">
                        <div className="movement-wrapper">
                          <Scrollspy
                            //updateHistoryStack={false}
                            // offsetTop Does not work, no idea why. Therefore i am using a negative offsetBottom
                            //offsetBottom={-300}
                            //offsetTop={500}
                            //useBoxMethod={true}
                            //onUpdateCallback={(id) => changeActiveImage(this.scrollContainer.current, id)}
                            offset={
                              typeof window !== "undefined"
                                ? this.state.scrollOffset
                                : -600
                            }
                            items={this.getScrollAnchors(nodes)}
                            componentTag={"div"}
                            currentClassName="is-current"
                            onUpdate={(el) => {
                              if (this.props.updateUrl) {
                                if (window.scrollY <= 50) {
                                  this.setState({
                                    scrollOffset: 50,
                                  });
                                } else {
                                  this.setState({
                                    scrollOffset:
                                      -1 * (window.innerHeight / 2 - 100),
                                  });
                                }
                              } else if (!this.props.updateUrl) {
                                // if teaserlist, not programme overview
                                this.setState({
                                  scrollOffset:
                                    -1 * (window.innerHeight / 2 - 100),
                                });
                              }
                              changeActiveImage(
                                el,
                                this.programmeOverview.current
                              );
                            }}
                          >
                            {preparedNodes}
                          </Scrollspy>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="single-result">{preparedNodes}</div>
                  )}
                </div>
                <div className="col-8 teaser-wrapper">
                  <div className="scroll-wrapper">
                    {nodes.map((item, index) => (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (
                            item.entity
                              ? item.entity.entityBundle
                              : item.entityBundle
                          ) {
                            case "participant":
                              if (
                                this.props.clickBehavior &&
                                this.props.clickBehavior === "link"
                              ) {
                                return (
                                  <ErrorBoundary>
                                    <TeaserParticipantLink
                                      item={item.entity ? item.entity : item}
                                      index={index}
                                      updateUrl={this.props.updateUrl}
                                      pagerFullPage={
                                        this.props.content
                                          .fieldPagerAufVollseiten
                                          ? this.props.content.entityId
                                          : false
                                      }
                                    />
                                  </ErrorBoundary>
                                );
                              } else {
                                return (
                                  <ErrorBoundary>
                                    <TeaserParticipant
                                      item={item.entity ? item.entity : item}
                                      index={index}
                                      updateUrl={this.props.updateUrl}
                                      overviewType={this.props.overviewType}
                                      dayFilterEnabled={
                                        this.props.dayFilterEnabled
                                      }
                                      pagerFullPage={
                                        this.props.content
                                          .fieldPagerAufVollseiten
                                          ? this.props.content.entityId
                                          : false
                                      }
                                    />
                                  </ErrorBoundary>
                                );
                              }
                            default:
                              return null;
                          }
                        })()}
                        {/*
                         * Load a Break Banner every 10th participant
                         */}
                        {this.props.showBanners && (index + 1) % 10 === 0 && (
                          <>
                            {breaks && breaks[(index + 1) / 10 - 1] && (
                              <div
                                className="break-teaser programme-element"
                                id={`${encodeUrl(
                                  breaks[(index + 1) / 10 - 1].entity
                                    .fieldHeading
                                )}-${index}`}
                              >
                                <div
                                  className="col-16 break-teaser"
                                  data-to-scrollspy-id={`${encodeUrl(
                                    breaks[(index + 1) / 10 - 1].entity
                                      .fieldHeading
                                  )}-${index}`}
                                >
                                  <h2>
                                    {
                                      breaks[(index + 1) / 10 - 1].entity
                                        .fieldHeading
                                    }
                                  </h2>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="line bottom" />
      </div>
    );
  }
}

ProgrammeOverview.propTypes = {
  mainSettings: PropTypes.oneOfType([PropTypes.bool, MainSettingsProps]),
  type: PropTypes.oneOf([
    "conference",
    "participant",
    "ProgrammeOverview",
    "programme",
  ]),
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldPagerAufVollseiten: PropTypes.bool,
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.string,
  }),
  overviewType: PropTypes.string,
  clickBehavior: PropTypes.string,
  displayType: PropTypes.string,
  isMobile: PropTypes.bool,
  nodes: PropTypes.array,
  skipQuery: PropTypes.bool,
  showBanners: PropTypes.bool,
  updateUrl: PropTypes.bool,
  manualNodes: PropTypes.array,
};

ProgrammeOverview.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 6,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags.map((item) => item.targetId.toString()),
        filterTagEnabled: props.tags.length > 0,
        participantTypeFilter: props.participantTypeFilter,
        participantTypeFilterEnabled: props.participantTypeFilter !== null,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(ProgrammeOverview)
);
