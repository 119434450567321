const timelineSetActiveFilterAction = (config) => ({
    config,
    type: "TIMELINE_SET_ACTIVE_FILTER_ACTION",
  }),
  timelineRemoveActiveFilterAction = (id) => ({
    id,
    type: "TIMELINE_REMOVE_ACTIVE_FILTER_ACTION",
  });

export { timelineRemoveActiveFilterAction, timelineSetActiveFilterAction };
