import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";
import { activeCloudDetailAction } from "../../cloud-view-action";
import { connect } from "react-redux";

class ListItem extends Component {
  render() {
    const { content } = this.props;

    return (
      <div
        className={classNames({
          "list-item": true,
          "col-16": true,
        })}
        onClick={() => this.props.dispatch(activeCloudDetailAction(content))}
      >
        <div className="content-wrapper in-view">
          <div className="row">
            <div className="item-image col-16 col-md-4">
              <img
                className="list-img"
                src={content.fieldBild.entity.fieldMediaImage.style.url}
                alt={content.fieldBild.entity.fieldMediaImage.alt}
              />
            </div>
            <div className="col-16 col-md-12 item-text">
              <div className="title">
                <h2>{content.entityLabel}</h2>
              </div>
              <div className="bottom-wrapper">
                {!!content.fieldTeasertext && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: content.fieldTeasertext.value,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListItem.propTypes = {
  content: PropTypes.shape(ContentCloudNodePropTypes),
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ListItem);
