import React, { Component } from "react";
import PropTypes from "prop-types";

class AnchorLink extends Component {
  state = {
    initial: true,
  };

  componentDidMount() {
    if (
      this.state.initial &&
      this.props.link &&
      window.location.hash &&
      window.location.hash.replace("#", "") ===
      this.props.link
    ) {
      // get Anchor position and scroll to it
      let anchorLink = document.getElementById(
        window.location.hash.replace("#", "")
      );
      if (anchorLink) {
        // Notice: NO offset needed here, its done via css
        let scrollPosition = anchorLink.getBoundingClientRect().top;

        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
        this.setState({
          initial: false,
        });
      }
    }
  }

  render() {
    return (
      <div
        className={"sr-only scroll-anchor"}
        id={this.props.link}
      />
    );
  }
}

AnchorLink.propTypes = {
  link: PropTypes.string.isRequired
};

export default AnchorLink;
