import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Link as ReactRouterLink } from "react-router-dom";
import ClickSuggestion from "./click-suggestion";
import { waitForElm } from "./../../../../lib/wait-for-elm";

class ParagraphClickSuggestions extends Component {
  constructor(props) {
    super(props);
    this.clickSuggestions = React.createRef();
  }

  calculateMaxHeight = () => {
    let clickSuggestions = this.clickSuggestions.current.querySelectorAll(
        ".click-suggestion.highlighted"
      ),
      biggestClickSuggestion = 0;

    if (clickSuggestions.length > 0) {
      [...clickSuggestions].map((clickSuggestion) => {
        if (
          parseInt(clickSuggestion.offsetHeight) >
          parseInt(biggestClickSuggestion)
        ) {
          biggestClickSuggestion = clickSuggestion.offsetHeight;
        }
      });
    }
    this.clickSuggestions.current.style.setProperty(
      "--click-suggestion-max-height",
      `${biggestClickSuggestion}px`
    );
  };

  componentDidMount() {
    setTimeout(() => {
      this.calculateMaxHeight();
    }, 500);
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-click-suggestions": true,
    });

    return (
      <section
        className={sectionClassNames}
        data-darkmode={this.props.content.fieldDarkMode}
        ref={this.clickSuggestions}
      >
        <div className="container">
          <div className="row">
            {this.props.content.fieldHeading && (
              <div className="col-16">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            )}
            <div className="col-16 links">
              <ul className="ctas">
                {this.props.content.fieldReferencedContent.map(
                  (link, index) => (
                    <li key={index}>
                      {link.entity.fieldLink.url.routed ? (
                        <ReactRouterLink to={link.entity.fieldLink.url.path}>
                          <ClickSuggestion content={link} />
                        </ReactRouterLink>
                      ) : (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={link.entity.fieldLink.url.path}
                          className={
                            link.entity.fieldHighlighted
                              ? "highlighted"
                              : "standard"
                          }
                        >
                          <ClickSuggestion content={link} />
                        </a>
                      )}
                      {/* Text needs to be outside to ensure it can be marked and can contain links. Otherwise there would be nested links */}
                      {link.entity.fieldText && link.entity.fieldHighlighted && (
                        <div className="highlighted-text col">
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: link.entity.fieldText.processed,
                            }}
                          />
                        </div>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.shape({
    fieldDarkMode: PropTypes.bool,
    fieldHeading: PropTypes.string,
    fieldReferencedContent: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldHeadline: PropTypes.string,
          fieldSubline: PropTypes.string,
          fieldImage: PropTypes.object,
          fieldLink: PropTypes.shape({
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphClickSuggestions;
