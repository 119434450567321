import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { teaserNewsPropTypes } from "../../../../teaser-base/news/teaser-news";
import { teaserPersonPropTypes } from "../../../../teaser-base/person/teaser-person";
import { calculateCollapseHeight } from "../../../../../lib/calculate-collape-height";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { hideSortAction, hideFilterAction } from "../../../../../app-actions";
import ErrorBoundary from "../../../../../error-boundary";
import ComponentExtendedTeaserOverviewSearch from "./component-extended-teaser-overview-search";
import OutsideClickHandler from 'react-outside-click-handler';

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  hideSort: reduxStore.appStore.hideSort,
  hideFilter: reduxStore.appStore.hideFilter,
});

class ComponentFilterCloud extends Component {
  filterCloud = React.createRef();

  static defaultProps = { wrap: true, preventEmptyResult: false };

  getTagsFromItems = (items, filterField) => {
    let tags = [];

    for (const item of items) {
      if (item[filterField] == null) {
        continue;
      }
      // Text field.
      if (typeof item[filterField] === "string") {
        if (!tags.some(({ entityId }) => entityId === item[filterField])) {
          tags = [
            ...tags,
            {
              entityId: item[filterField],
              name: item[filterField],
            },
          ];
        }

        continue;
      }

      // Multi value Text field.
      if (Array.isArray(item[filterField]) && typeof item[filterField][0] === "string") {
        //@todo: FINISH THIS
        for (const tag of item[filterField]) {
          if (
            tag !== null &&
            tag.name !== null &&
            !tags.some(({ entityId }) => entityId.entityId === tag.entityId)
          ) {
            tags = [...tags, {
              entityId: tag,
              name: tag,
            },];
          }
        }

        continue;
      }

      // Single Entity reference field.
      if (
        typeof item[filterField] === "object" &&
        !Array.isArray(item[filterField]) &&
        item[filterField].entity.name !== null &&
        item[filterField].entity.entityId !== null
      ) {
        if (
          !tags.some(
            ({ entityId }) => entityId === item[filterField].entity.entityId
          )
        ) {
          tags = [...tags, item[filterField].entity];
        }

        continue;
      }

      // Multi Entity reference field.
      for (const tag of item[filterField]) {
        if (
          tag.entity !== null &&
          tag.entity.name !== null &&
          !tags.some(({ entityId }) => entityId === tag.entity.entityId)
        ) {
          tags = [...tags, tag.entity];
        }
      }
    }

    return tags;
  };

  getFilters = (items, filtersInput) => {
    const filters = {};

    for (const filter of filtersInput) {
      filters[filter] = this.getTagsFromItems(items, filter);
    }

    return filters;
  };

  state = {
    showFilters: false,
    tags: this.props.items
      ? this.getFilters(this.props.items, this.props.filter)
      : [],
    scrollOptions: {
      reserveScrollBarGap: true,
      allowTouchMove: (el) => {
        while (el && el !== document.body) {
          if (el.classList.contains("body-scroll-lock-ignore")) {
            return true;
          }

          el = el.parentElement;
        }
      },
    },
  };

  componentDidMount = () => {
    if (this.filterCloud.current) {
      Object.keys(this.state.tags).map((filterName, index) => {
        calculateCollapseHeight(
          this.filterCloud.current.querySelectorAll(".filter-type")[index]
        );
      });
    }
  };

  setFilter = (item, filterName) => {
    window.scroll(0, 0);
    this.props.changeActiveFilter({
      ...item,
      field: filterName,
    });
  };

  toggleOverlay = () => {
    this.props.dispatch(hideSortAction(!this.props.hideSort));
    this.props.dispatch(hideFilterAction(!this.props.hideFilter));

    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  closeOverlay = () => {
    this.setState({
      showFilters: false,
    });
    this.props.dispatch(hideSortAction(false));
    this.props.dispatch(hideFilterAction(false));
  };

  resetFilters = () => {
    this.props.changeActiveFilter("reset");
  };

  getFilterOptions = (filterName) => {
    let filters = [];
    this.state.tags[filterName].map((item, index) => {
      if (
        this.props.preventEmptyResult &&
        typeof this.props.filterItems === "function"
      ) {
        const matchingItems = this.props.filterItems([
          ...this.props.activeFilter,
          {
            ...item,
            field: filterName,
          },
        ]);

        if (matchingItems.length === 0) {
          return null;
        }
      }

      filters.push(
        <div
          role="button"
          tabIndex={0}
          aria-label={`Filter by ${item.name}`}
          className={classNames({
            filter: true,
            active:
              this.props.activeFilter &&
              this.props.activeFilter.filter(
                (filterItem) =>
                  filterItem.entityId === item.entityId &&
                  filterItem.field === filterName
              ).length > 0,
          })}
          key={index}
          onClick={() => this.setFilter(item, filterName)}
        >
          <span>{item.name}</span>
        </div>
      );
    })
    return filters;
  }

  render() {
    const filters = Object.keys(this.state.tags);

    //@todo: move filterOptions and collapseFilters into own components.

    const collapseFilters = <>
      <Accordion
        allowZeroExpanded={true}
        allowMultipleExpanded={false}
      >
        {filters.map((filterName, index) => {
          return (
            <div className="filter-type" key={index}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {this.props.labels && this.props.labels[index] ? (
                      <div className="col-16 label-wrapper">
                        <h2>{this.props.labels[index]}</h2>
                      </div>
                    ) : null}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="collapse-content">
                    <div className="inside-filter-wrapper">
                      {this.getFilterOptions(filterName)}
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
          );
        })}
      </Accordion>
    </>

    if (this.props.wrap) {
      return (
        <section
          className={`filter-cloud ${
            this.state.showFilters || this.props.filterDisplayType === "top" ? "active" : "inactive"
          } filter-${this.props.filterDisplayType}`}
          ref={this.filterCloud}
          id={"filter-cloud"}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              this.closeOverlay();
            }}
          >
          <div className={"filter-wrapper"}>
            {/*@todo: accesibility button check*/}
            {this.props.filterDisplayType === "overlay" ? (
              <>
                <div className="filter-button-wrapper">
                  <button
                    aria-label="Filter"
                    className="btn btn-primary more"
                    onClick={() => this.toggleOverlay()}
                  >
                    <div className="inner-wrapper">
                      <FormattedMessage id="filter"/>
                      {this.props.activeFilter &&
                        this.props.activeFilter.length > 0 && (
                          <span className="filter-ammount">
                          ({this.props.activeFilter.length})
                        </span>
                        )}
                      <div className="border-element"/>
                      <div className="effect-element"/>
                    </div>
                  </button>
                </div>
                {/*<div className="blur-background" />*/}
                <div className="filter-overlay fixed-wrapper">
                  <div className="effect-element"/>
                  <div className="header-element">
                    <div className="border-element"/>
                    <ErrorBoundary>
                      <ComponentExtendedTeaserOverviewSearch
                        changeActiveSearch={this.props.changeActiveSearch}
                      />
                    </ErrorBoundary>
                    <div className="available-filter-options">
                      {collapseFilters}
                    </div>
                    <div className="control-elements">
                      <button
                        data-testid="reset-filters"
                        onClick={() => this.resetFilters()}
                        disabled={this.props.activeFilter.length < 1}
                        aria-label={"reset filters"}
                        tabIndex={0}
                      >
                        <FormattedMessage id="filter.reset"/>
                      </button>
                      <button
                        data-testid="submit-filters"
                        onClick={() => this.toggleOverlay()}
                        aria-label={"toggle filter overlay"}
                        tabIndex={0}
                      >
                        <FormattedMessage id="filter.submit"/>
                        {this.props.itemsMatchingAmmount && (
                          <span className="filter-ammount">
                          ({this.props.itemsMatchingAmmount})
                        </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </>
              ) : (
              <div className="filter-overlay fixed-wrapper">
                <div className="effect-element"/>
                <span>{this.getFilterOptions()}</span>
              </div>
            )
            }
          </div>
          </OutsideClickHandler>
        </section>
      );
    }

    return (
      <section className="filter-cloud" ref={this.filterCloud}>
        <div>{filter}</div>
      </section>
    );
  }
}

ComponentFilterCloud.propTypes = {
  /**
   * The active filter.
   */
  activeFilter: PropTypes.array,
  /**
   * The function to change the active filter.
   */
  changeActiveFilter: PropTypes.func.isRequired,
  /**
   * The filter function used to filter the items. Used for the "prevent zero
   * results" feature.
   */
  filterItems: PropTypes.func,
  /**
   * The items to be filtered.
   */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
  ),
  /**
   * The name of the filtered field.
   */
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The labels of the filters.
   */
  labels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether to prevent zero results.
   */
  preventEmptyResult: PropTypes.bool,
  /**
   * Whether to wrap the filter cloud in a container.
   */
  wrap: PropTypes.bool.isRequired,
  /**
   * Whether to filter yhould be shown as collapsible overlay with a button on bottom, or docked to the nav showing the options all the time
   */
  filterDisplayType: PropTypes.oneOf([
    "top",
    "overlay"
  ]),
  /**
   * Gets the ammount of matching elements / Results
   */
  itemsMatchingAmmount: PropTypes.number,
  dispatch: PropTypes.func,
  hideSort: PropTypes.bool,
  hideFilter: PropTypes.bool,
};

export default connect(mapStateToProps)(ComponentFilterCloud);
