const waitForElm = (selector, scope) => {
  // From: https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
  // Changed a bit so it can be used with a scope, to use it with refs inside of multiple appearing components.
  return new Promise(resolve => {
    if (scope.querySelector(selector)) {
      return resolve(scope.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (scope.querySelector(selector)) {
        resolve(scope.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export { waitForElm };