import React, { Component } from "react";
import PropTypes from "prop-types";

import { encodeUrl } from "../../../../../lib/encode-url";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserParticipantSpeaker from "../../../../teaser-base/participant/teaser-participant-speaker";

import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import { MainSettingsProps } from "../../../../../types";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  mainSettings: reduxStore.appStore.mainSettings,
  isMobile: reduxStore.appStore.isMobile,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class SpeakerOverview extends Component {
  SpeakerOverview = React.createRef();

  render() {
    /* Manual and automatic nodes, needed for use in lists */
    let nodes = [];

    if (this.props.skipQuery) {
      if (this.props.manualNodes) {
        nodes = this.props.manualNodes;
      } else {
        nodes = [...this.props.nodes].sort((a, b) =>
          a.entityLabel > b.entityLabel
            ? 1
            : a.entityLabel === b.entityLabel
            ? a.entityLabel > b.entityLabel
              ? 1
              : -1
            : -1
        );
      }
    } else if (this.props.nodes?.nodeQuery?.entities) {
      nodes = [...this.props.nodes.nodeQuery.entities].sort((a, b) => {
        if (a && b) {
          return a.entityLabel > b.entityLabel
            ? 1
            : a.entityLabel < b.entityLabel
            ? -1
            : 0;
        } else if (a) {
          return 1; // Put a ahead of b
        } else if (b) {
          return -1; // Put b ahead of a
        } else {
          return 0; // Leave them in their current order (both are null or undefined)
        }
      });
    }

    return (
      <div className="speaker-overview" ref={this.SpeakerOverview}>
        {/* Lines shown in list view */}
        <div className="line top" />
        <div className="container">
          <div className="row">
            {nodes &&
              nodes.map((item, index) => {
                if (!item) {
                  return null;
                }

                return (
                  <React.Fragment key={index}>
                    <ErrorBoundary>
                      <TeaserParticipantSpeaker
                        item={item.entity ? item.entity : item}
                        index={index}
                        updateUrl={true}
                        pagerFullPage={
                          this.props.content.fieldPagerAufVollseiten
                            ? this.props.content.entityId
                            : false
                        }
                      />
                    </ErrorBoundary>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <div className="line bottom" />
      </div>
    );
  }
}

SpeakerOverview.propTypes = {
  mainSettings: PropTypes.oneOfType([PropTypes.bool, MainSettingsProps]),
  type: PropTypes.oneOf(["conference", "speaker"]),
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldPagerAufVollseiten: PropTypes.bool,
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  nodes: PropTypes.object,
  skipQuery: PropTypes.bool,
  showBanners: PropTypes.bool,
};

SpeakerOverview.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 6,
        type: "participant",
        tag: props.tags.map((item) => item.targetId.toString()),
        filterTagEnabled: props.tags.length >= 1,
        participantTypeFilter: props.participantTypeFilter,
        participantTypeFilterEnabled: props.participantTypeFilter !== null,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(SpeakerOverview)
);
