import React, { Component } from "react";
import PropTypes from "prop-types";
import RBXAnimation from "./rbx-animation/rbx-animation";

class LoadingIndicator extends Component {
  render() {
    return (
      <div
        className={`loading-indicator-wrap ${
          this.props.fullPage ? "full-page" : "normal"
        }`}
        data-testid="loading-indicator"
      >
        <RBXAnimation className="loading-animation" scalingFactor={2} />
      </div>
    );
  }
}

LoadingIndicator.propTypes = {
  fullPage: PropTypes.bool,
};

export default LoadingIndicator;
