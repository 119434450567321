const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  isMobileAction = (isMobile) => ({
    isMobile,
    type: "IS_MOBILE",
  }),
  mainMenuOpenAction = (mainMenuOpen) => ({
    mainMenuOpen,
    type: "MAIN_MENU_OPEN",
  }),
  accessibilityMenuOpenAction = (accessibilityMenuOpen) => ({
    accessibilityMenuOpen,
    type: "ACCESSIBILITY_MENU_OPEN",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  mainSettingsAction = (mainSettings) => ({
    mainSettings,
    type: "MAIN_SETTINGS",
  }),
  menuTitleAction = (menuTitle) => ({
    menuTitle,
    type: "MENU_TITLE",
  }),
  hideSortAction = (hideSort) => ({
    hideSort,
    type: "HIDE_SORT",
  }),
  hideFilterAction = (hideFilter) => ({
    hideFilter,
    type: "HIDE_FILTER",
  }),
  overwriteFooterAction = (overwriteFooter) => ({
    overwriteFooter,
    type: "OVERWRITE_FOOTER",
  });

export { adminAppAction, isMobileAction, mainMenuOpenAction, accessibilityMenuOpenAction, pagerFullPagesAction, pagerFullPageContextAction, mainSettingsAction, menuTitleAction, hideSortAction, hideFilterAction, overwriteFooterAction };
