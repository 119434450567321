import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "./../i18n/i18n";
import {menuTitleAction, overwriteFooterAction} from "../app-actions";
import NotFound from "../general-components/not-found";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  menuTitle: reduxStore.appStore.menuTitle,
  currentLanguage: reduxStore.i18n.currentLanguage,
  overwriteFooter: reduxStore.appStore.overwriteFooter
});

class Landingpage extends Component {

  // Push used Custom Footer to Props if Used in a Landingpage
  overwriteFooter = () => {
    if (this.props.data.route && this.props.data.route.entity.fieldOverwriteFooter) {
      this.props.dispatch(overwriteFooterAction(this.props.data.route.entity.fieldOverwriteFooter.entity));
    } else {
      this.props.dispatch(overwriteFooterAction(false));
    }
  }

  componentDidMount() {
    this.overwriteFooter();

    i18nActionHelper(this.props, this.props.dispatch);
    if (this.props.data.route && this.props.data.route.entity.entityLabel) {
      this.props.dispatch(menuTitleAction(this.props.data.route.entity.entityLabel));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      this.overwriteFooter();
      i18nActionHelper(this.props, this.props.dispatch);
      if (this.props.data.route && this.props.data.route.entity.entityLabel) {
        this.props.dispatch(menuTitleAction(this.props.data.route.entity.entityLabel));
      }
    }
  }

  render() {
    if (!this.props.data.loading && this.props.data.route === null) {
      return (
        <NotFound />
      );
    }

    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} ${(this.props.location.pathname === "/" || this.props.location.pathname === "/en" || this.props.location.pathname === "/de/startseite" || this.props.location.pathname === "/en/frontpage") ? 'frontpage' : 'standard-page'} node-full-page ${published}`}
        >
          <Helmet>
            <title>{`Reeperbahn Festival | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>
          {this.props.data.route.entity.fieldModules && (
            <ContentBase content={this.props.data.route.entity.fieldModules} />
          )}

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true} />;
  }
}

Landingpage.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default graphql(landingPageQuery, {
  options: (props) => ({
    variables: {
      /*  The Old Website used Detail Pages for Artists and Events. We do not need them anymore.
      *   We use an Overview with collapsible teasers. However, the old Link Structure has to be the same. Therefore we check wether or not the landingpage is one of the
      *   Overview Pages. They are: festival, conference, konferenz, artists.
      *   If we want to see an artists detail, the url is something like /festival/artist-name.
      *   We have to make sure that only the "festival" page is loaded, not festival/artist-name.
      * */
      path: props.location.pathname.includes("/en/festival/")
      || props.location.pathname.includes("/de/festival/")
      || props.location.pathname.includes("/en/conference/")
      || props.location.pathname.includes("/de/konferenz/")
      || props.location.pathname.includes("/en/speakers-festival/")
      || props.location.pathname.includes("/de/speakers-festival/")
      || props.location.pathname.includes("/en/speakers-conference/")
      || props.location.pathname.includes("/de/speakers-conference/")
      || props.location.pathname.includes("/de/artists/")
      || props.location.pathname.includes("/en/artists/") ?
        `/${props.location.pathname.split("/")[1]}/${props.location.pathname.split("/")[2]}` : props.location.pathname
    },
  }),
})(connect(mapStateToProps)(withRouter(Landingpage)));
