import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { self } from '../config';

const mapStateToProps = (reduxStore) => ({
  menuTitle: reduxStore.appStore.menuTitle,
  currentLanguage: reduxStore.i18n.currentLanguage
});

class LogoButton extends Component {
  render() {
    return (
      <Link className="site-logo-button" to={`/${this.props.currentLanguage !== "de" ? this.props.currentLanguage : ''}`}>
        <img src={`${self}/rbf-logo.svg`} className={"logo"} alt={"RBX Logo"}/>
      </Link>
    );
  }
}

LogoButton.propTypes = {};

export default connect(mapStateToProps)(LogoButton);
