import React, { Component } from "react";
import PropTypes from "prop-types";

class Links extends Component {
  render() {
    return (
      <div className="showcased-links">
        <div className="container">
          <div className="row rsc-ts-user">
            {this.props.value &&
              this.props.value.map((style, index) => (
                <a
                  className="link col-8"
                  key={index}
                  href={style.link.url.path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="rsc-ts-bubble">
                    {style.url &&
                      typeof style.url.entity.fieldMediaImage !==
                        "undefined" && (
                        <div className="image-wrapper">
                          <img
                            src={style.url.entity.fieldMediaImage.style.url}
                          />
                        </div>
                      )}
                    {style.label && typeof style.label !== "undefined" && (
                      <div
                        className="label"
                        dangerouslySetInnerHTML={{ __html: style.label }}
                      />
                    )}
                    {style.text && typeof style.text.value !== "undefined" && (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{ __html: style.text.value }}
                      />
                    )}
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

Links.propTypes = {
  value: PropTypes.object.isRequired,
};

export default Links;
