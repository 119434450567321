import * as THREE from "three";
import originalPositions from "./rbx-model-data";

const initAnimationController = (
  element,
  {
    scalingFactor,
    dashColor,
    dashSize,
    dashGap,
    dashSpeedFactor,
    animationSpeedFactor,
    dashScale,
    animationEffectSize,
  } = {}
) => {
  const SCALING_FACTOR = scalingFactor || 1;
  const DASH_COLOR = dashColor || "black";
  const DASH_SIZE = dashSize || 0.05;
  const DASH_GAP = dashGap || 0.02;
  const DASH_SPEED_FACTOR = dashSpeedFactor || 0.1;
  const DASH_SCALE = dashScale || 1;
  const ANIMATION_SPEED_FACTOR = animationSpeedFactor || 2;
  const ANIMATION_EFFECT_SIZE = animationEffectSize || 0.66;
  
  let size = 200 * SCALING_FACTOR;
  if (window.innerWidth > 768) {
    size = 400 * SCALING_FACTOR;
  }

  /* create scene */
  let scene = new THREE.Scene();

  /* set camera properties */
  let camera = new THREE.PerspectiveCamera(35, 1, 1, 100);
  camera.position.set(-0.05, 0, 1.5);

  /* create renderer */
  let renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(size, size);
  element.appendChild(renderer.domElement);

  /* create dashed materials with animation based on time */
  let uniforms = {
    time: { value: 0 },
  };
  let mLong = new THREE.LineDashedMaterial({
    color: DASH_COLOR,
    dashSize: DASH_SIZE,
    gapSize: DASH_GAP,
    scale: DASH_SCALE,
    onBeforeCompile: (shader) => {
      shader.uniforms.time = uniforms.time;
      shader.fragmentShader = `
      uniform float time;
      ${shader.fragmentShader}
      `.replace(`vLineDistance,`, `vLineDistance - time,`);
      //console.log(shader.fragmentShader);
    },
  });
  let mShort = new THREE.LineDashedMaterial({
    color: DASH_COLOR,
    dashSize: DASH_SIZE - 0.04,
    gapSize: DASH_GAP + 0.04,
    scale: DASH_SCALE,
    onBeforeCompile: (shader) => {
      shader.uniforms.time = uniforms.time;
      shader.fragmentShader = `
      uniform float time;
      ${shader.fragmentShader}
      `.replace(`vLineDistance,`, `vLineDistance + 0.015 - time,`);
      //console.log(shader.fragmentShader);
    },
  });

  /**
   * Build a _BufferGeometry from positions array
   * Positions were extracted by using the GLTF loader and logging the geometry
   * to the console. The positions are located in
   * _BufferGeometry.attributes.position as Float32Array. This function creates
   * the THREE.BufferGeometry object from these values.
   */
  const buildGeometryFromPositions = (originalPositions) => {
    const numVertices = originalPositions.length / 3;
    const positions = new Float32Array(numVertices * 3);

    for (let i = 0; i < numVertices; i++) {
      positions[i * 3] = originalPositions[i * 3];
      positions[i * 3 + 1] = originalPositions[i * 3 + 1];
      positions[i * 3 + 2] = originalPositions[i * 3 + 2];
    }

    const attribute = new THREE.BufferAttribute(positions, 3);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", attribute);

    return geometry;
  };

  /**
   * Load the glTF model using the GLTFLoader (uncomment to run in local
   * threejs project) This will log the geometry data to the console so you can
   * use the position array as argument for buildGeometryFromPositions. To
   * export from Blender select the model in edit mode → select mesh, F3 → mesh
   * → delete → only faces Finally, export → glb → mesh → loose edges
   */
  // import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
  // const loader = new GLTFLoader();
  // loader.load("/rbx-logo_outline.glb", function (gltf) {
  //   /* import model and convert to THREE object */
  //   let geometry = gltf.scene.children[0].geometry;
  //   geometry = geometry.toNonIndexed();
  //   console.log(geometry)
  // }, undefined, function (error) {
  //   console.error(error);
  // });

  const rbxLogo = buildGeometryFromPositions(originalPositions);
  const lineSegmentsShort = new THREE.LineSegments(rbxLogo, mShort);
  const lineSegmentsLong = new THREE.LineSegments(rbxLogo, mLong);
  const lineSegments = [lineSegmentsShort, lineSegmentsLong];

  lineSegments.forEach((e) => {
    e.computeLineDistances();
    scene.add(e);
  });

  let clock = new THREE.Clock();

  renderer.setAnimationLoop((_) => {
    const elapsedTime = clock.getElapsedTime();
    uniforms.time.value = elapsedTime * DASH_SPEED_FACTOR;
    try {
      renderer.render(scene, camera);
      scene.children[0] &&
        (scene.children[0].rotation.y =
          Math.sin(elapsedTime * ANIMATION_SPEED_FACTOR) *
          ANIMATION_EFFECT_SIZE);
      scene.children[1] &&
        (scene.children[1].rotation.y =
          Math.sin(elapsedTime * ANIMATION_SPEED_FACTOR) *
          ANIMATION_EFFECT_SIZE);
    } catch (e) {
      console.log(e);
    }
  });
};

export default initAnimationController;
