import React from "react";
import ErrorBoundary from "../../../../error-boundary";
import { encodeUrl } from "../../../../lib/encode-url";
import { Link as ReactRouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import Image from "../../../image/image";

const EventPresenter = (props) => {
  if (
    props.content.fieldPresenter1Logo ||
    props.content.fieldPresenter2Logo ||
    props.content.fieldPresenter3Logo
  ) {
    return (
      <ErrorBoundary>
        <div className="sponsor-wrapper">
          <div className="all-sponsors">
            <div className="label">
              <FormattedMessage id={"presented"} />
            </div>
            {props.content.fieldPresenter1Logo && (
              <div className="sponsor main">
                {props.content.fieldPresenter1Web ? (
                  <a href={props.content.fieldPresenter1Web?.uri} target="_blank">
                    <span className="sr-only">
                      {props.content.fieldPresenter1Company}
                    </span>
                    {props.content.fieldPresenter1Logo &&
                      <div className={"sponsor-image"}>
                        <LazyLoad>
                          <Image
                            data={
                              props.content.fieldPresenter1Logo.entity
                                .fieldMediaImage
                            }
                            nodeTitle={props.content.fieldPresenter1Company}
                          />
                        </LazyLoad>
                      </div>
                    }
                  </a>
                ) : (
                  <div className={"sponsor-image"}>
                    <span className="sr-only">
                      {props.content.fieldPresenter1Company}
                    </span>
                    {props.content.fieldPresenter1Logo &&
                      <LazyLoad>
                        <Image
                          data={
                            props.content.fieldPresenter1Logo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={props.content.fieldPresenter1Logo}
                        />
                      </LazyLoad>
                    }
                  </div>
                )}
              </div>
            )}
            {props.content.fieldPresenter2Logo && (
              <div className="sponsor main">
                {props.content.fieldPresenter2Web ? (
                  <a href={props.content.fieldPresenter2Web?.uri} target="_blank">
                    <span className="sr-only">
                      {props.content.fieldPresenter2Company}
                    </span>
                    {props.content.fieldPresenter2Logo &&
                      <div className={"sponsor-image"}>
                        <LazyLoad>
                          <Image
                            data={
                              props.content.fieldPresenter2Logo.entity
                                .fieldMediaImage
                            }
                            nodeTitle={props.content.fieldPresenter2Logo}
                          />
                        </LazyLoad>
                      </div>
                    }
                  </a>
                ) : (
                  <div className={"sponsor-image"}>
                    <span className="sr-only">
                      {props.content.fieldPresenter2Company}
                    </span>
                    {props.content.fieldPresenter2Logo &&
                      <LazyLoad>
                        <Image
                          data={
                            props.content.fieldPresenter2Logo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={props.content.fieldPresenter2Logo}
                        />
                      </LazyLoad>
                    }
                  </div>
                )}
              </div>
            )}
            {props.content.fieldPresenter3Logo && (
              <div className="sponsor main">
                {props.content.fieldPresenter3Web ? (
                  <a href={props.content.fieldPresenter3Web?.uri} target="_blank">
                    <span className="sr-only">
                      {props.content.fieldPresenter3Company}
                    </span>
                    {props.content.fieldPresenter3Logo &&
                      <div className={"sponsor-image"}>
                        <LazyLoad>
                          <Image
                            data={
                              props.content.fieldPresenter3Logo.entity
                                .fieldMediaImage
                            }
                            nodeTitle={props.content.fieldPresenter3Logo}
                          />
                        </LazyLoad>
                      </div>
                    }
                  </a>
                ) : (
                  <div className={"sponsor-image"}>
                    <span className="sr-only">
                      {props.content.fieldPresenter3Company}
                    </span>
                    {props.content.fieldPresenter3Logo &&
                      <LazyLoad>
                        <Image
                          data={
                            props.content.fieldPresenter3Logo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={props.content.fieldPresenter3Logo}
                        />
                      </LazyLoad>
                    }
                  </div>
                )}
              </div>
            )}
            {props.content.fieldPresenter4Logo && (
              <div className="sponsor main">
                {props.content.fieldPresenter4Web ? (
                  <a href={props.content.fieldPresenter4Web?.uri} target="_blank">
                    <span className="sr-only">
                      {props.content.fieldPresenter4Company}
                    </span>
                    {props.content.fieldPresenter4Logo &&
                      <div className={"sponsor-image"}>
                        <LazyLoad>
                          <Image
                            data={
                              props.content.fieldPresenter4Logo.entity
                                .fieldMediaImage
                            }
                            nodeTitle={props.content.fieldPresenter4Logo}
                          />
                        </LazyLoad>
                      </div>
                    }
                  </a>
                ) : (
                  <div className={"sponsor-image"}>
                    <span className="sr-only">
                      {props.content.fieldPresenter4Company}
                    </span>
                    {props.content.fieldPresenter4Logo &&
                      <LazyLoad>
                        <Image
                          data={
                            props.content.fieldPresenter4Logo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={props.content.fieldPresenter4Logo}
                        />
                      </LazyLoad>
                    }
                  </div>
                )}
              </div>
            )}
            {props.content.fieldPresenter5Logo && (
              <div className="sponsor main">
                {props.content.fieldPresenter5Web ? (
                  <a href={props.content.fieldPresenter5Web?.uri} target="_blank">
                    <span className="sr-only">
                      {props.content.fieldPresenter5Company}
                    </span>
                    {props.content.fieldPresenter5Logo &&
                      <div className={"sponsor-image"}>
                        <LazyLoad>
                          <Image
                            data={
                              props.content.fieldPresenter5Logo.entity
                                .fieldMediaImage
                            }
                            nodeTitle={props.content.fieldPresenter5Logo}
                          />
                        </LazyLoad>
                      </div>
                    }
                  </a>
                ) : (
                  <div className={"sponsor-image"}>
                    <span className="sr-only">
                      {props.content.fieldPresenter5Company}
                    </span>
                    {props.content.fieldPresenter5Logo &&
                      <LazyLoad>
                        <Image
                          data={
                            props.content.fieldPresenter5Logo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={props.content.fieldPresenter5Logo}
                        />
                      </LazyLoad>
                    }
                  </div>
                )}
              </div>
            )}
            {props.content.fieldPresenter6Logo && (
              <div className="sponsor main">
                {props.content.fieldPresenter6Web ? (
                  <a href={props.content.fieldPresenter6Web?.uri} target="_blank">
                    <span className="sr-only">
                      {props.content.fieldPresenter6Company}
                    </span>
                    {props.content.fieldPresenter6Logo &&
                      <div className={"sponsor-image"}>
                        <LazyLoad>
                          <Image
                            data={
                              props.content.fieldPresenter6Logo.entity
                                .fieldMediaImage
                            }
                            nodeTitle={props.content.fieldPresenter6Logo}
                          />
                        </LazyLoad>
                      </div>
                    }
                  </a>
                ) : (
                  <div className={"sponsor-image"}>
                    <span className="sr-only">
                      {props.content.fieldPresenter6Company}
                    </span>
                    {props.content.fieldPresenter6Logo &&
                      <LazyLoad>
                        <Image
                          data={
                            props.content.fieldPresenter6Logo.entity
                              .fieldMediaImage
                          }
                          nodeTitle={props.content.fieldPresenter6Logo}
                        />
                      </LazyLoad>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default EventPresenter;
