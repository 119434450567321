import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link as ReactRouterLink } from "react-router-dom";
import Image from "../../../image/image";
import classNames from "classnames";

class ClickSuggestion extends Component {
  render() {
    const articleClassNames = classNames({
      "click-suggestion": true,
      "with-image": this.props.content.entity.fieldImage,
      "no-image": !this.props.content.entity.fieldImage,
      highlighted: this.props.content.entity.fieldHighlighted,
    });
    return (
      <article className={articleClassNames}>
        <div className="inner-wrapper">
          <div className={"dashed-corner top left"} />
          <div className={"dashed-corner top right"} />
          <div className={"dashed-corner bottom left"} />
          <div className={"dashed-corner bottom right"} />
          <div className="content-wrapper">
            {this.props.content.entity.fieldImage && (
              <div className="background-image">
                <Image
                  data={
                    this.props.content.entity.fieldImage.entity.fieldMediaImage
                  }
                />
              </div>
            )}
            <div className="text-wrapper">
              <span className="headline">
                {this.props.content.entity?.fieldHeadline && (
                  <>{this.props.content.entity.fieldHeadline}</>
                )}
              </span>
              <span className="subline">
                {this.props.content.entity.fieldSubline}
              </span>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

ClickSuggestion.propTypes = {
  content: PropTypes.shape({
    fieldDarkMode: PropTypes.bool,
    fieldHeading: PropTypes.string,
    fieldReferencedContent: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldHeadline: PropTypes.string,
          fieldSubline: PropTypes.string,
          fieldImage: PropTypes.object,
          fieldLink: PropTypes.shape({
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

export default ClickSuggestion;
