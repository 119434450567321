import React from "react";
import ErrorBoundary from "../../../../error-boundary";

import { useSelector } from "react-redux";
import { encodeUrl } from "../../../../lib/encode-url";
import { Link as ReactRouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ParticipatingSpeakers = (props) => {
  const reduxStore = useSelector((state) => ({
    currentLanguage: state.i18n.currentLanguage
  }));

  let publishedSpeakers = props.content.fieldSpeaker.filter(
    (speaker) => speaker.entity.status === true
  );

  if (props.content.fieldSpeaker && publishedSpeakers && publishedSpeakers.length > 0) {
    return (
      <ErrorBoundary>
        <div className="participating-speakers">
          <div className="label">
            <FormattedMessage id={"speakers"} />
          </div>
          {publishedSpeakers.map((item, index) => (
            <article className="speaker" key={index}>
              <ReactRouterLink
                to={`/${reduxStore.currentLanguage}/speakers-conference/${encodeUrl(item.entity.entityLabel)}`}
              >
                <div className="speaker-name">{item.entity.entityLabel}</div>
                {item.entity.fieldPosition && (
                  <div className="position">{item.entity.fieldPosition}</div>
                )}
                {item.entity.fieldCompany && (
                  <div className="company"> / {item.entity.fieldCompany}</div>
                )}
              </ReactRouterLink>
            </article>
          ))}
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default ParticipatingSpeakers;
