import React, { Component } from "react";
import PropTypes from "prop-types";
import { Close32 } from "@carbon/icons-react";

import Image from "../../image/image";
import { teaserParticipantPropTypes } from "./teaser-participant";

class TeaserParticipantOverlay extends Component {
  render() {
    return (
      <article className="node node-teaser teaser-participant teaser-overlay">
        <div className="row">
          <div className="col-16 col-md-8">
            {this.props.item.fieldImage &&
              <Image
                data={this.props.item.fieldImage.entity.fieldMediaImage}
                nodeTitle={this.props.item.title}
                credit={this.props.item.fieldImage.entity.fieldCredit}
                styleSmall={true}
                caption={
                  this.props.item.fieldImage.defaultCaption && this.props.item.fieldImage.defaultCaption === false
                    ? this.props.item.fieldImage.customCaption
                    : null
                }
              />
            }
          </div>
          <div className="col-16 col-md-8">
            {this.props.toggleOverlay && (
              <div className="close" onClick={() => this.props.toggleOverlay()}>
                <Close32 />
              </div>
            )}

            <h1>{this.props.item.title}</h1>
            <div className={"meta-infos"}>
              {this.props.item.fieldCategory &&
                <div className="category">{this.props.item.fieldCategory.entity.name}</div>
              }
              {this.props.item.fieldGenre &&
                <div className="genre-wrapper">
                  {this.props.item.fieldGenre.map((item, index) => (
                    <div key={index} className="genre">
                      {item.entity.name}
                    </div>
                  ))}
                </div>
              }
              {this.props.item.fieldCountry &&
                <div className="country">{this.props.item.fieldCountry}</div>
              }
            </div>
            {this.props.item.fieldText && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldText.processed,
                }}
              />
            )}
            <div className="social-infos">
              <div className="label">
                Mehr zu {this.props.item.title}:
              </div>
              {this.props.item.fieldTwitter &&
                <a href={this.props.item.fieldTwitter.uri} target="_blank" rel="noreferrer">
                  X
                </a>
              }
              {this.props.item.fieldFacebook &&
                <a href={this.props.item.fieldFacebook.uri} target="_blank" rel="noreferrer">
                  Facebook
                </a>
              }
              {this.props.item.fieldInstagram &&
                <a href={this.props.item.fieldInstagram.uri} target="_blank" rel="noreferrer">
                  Instagram
                </a>
              }
            </div>
          </div>
        </div>
      </article>
    );
  }
}

TeaserParticipantOverlay.propTypes = {
  item: teaserParticipantPropTypes,
  toggleOverlay: PropTypes.func,
};

export default TeaserParticipantOverlay;
