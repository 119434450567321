import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import FooterSitemap from "./components/footer-sitemap";
import FooterPartners from "./components/footer-partners";
import FooterMenu from "./components/footer-menu";
import SocialMedia from "./components/social-media";
import { self } from "../config";
import RBXAnimation from "../general-components/rbx-animation/rbx-animation";
import Image from "../general-components/image/image";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  overwriteFooter: reduxStore.appStore.overwriteFooter,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/**
 * @todo Add title attribute to links.
 */
class PageFooter extends Component {

  render() {
    const footerConfig = this.props.footerConfig?.entities?.filter((config) => {
      return config.context.includes(this.props.currentLanguage);
    })[0];

    if (
      footerConfig &&
      this.props.accesibilityMenu
    ) {

      const socialMediaContent = !!this.props.overwriteFooter &&
        (this.props.overwriteFooter.fieldFacebook || this.props.overwriteFooter.fieldYoutube || this.props.overwriteFooter.fieldFlickr || this.props.overwriteFooter.fieldInstagram || this.props.overwriteFooter.fieldVimeo || this.props.overwriteFooter.fieldLinkedin || this.props.overwriteFooter.fieldPinterest || this.props.overwriteFooter.fieldTwitter || this.props.overwriteFooter.fieldXing || this.props.overwriteFooter.fieldTumblr || this.props.overwriteFooter.fieldDeezer)
          ? this.props.overwriteFooter : footerConfig,
      footerPartners = this.props.overwriteFooter.fieldFooterPartners || footerConfig.fieldFooterPartners;


      return (
        <div className="container">
          <>
            {footerConfig.fieldFooterSitemapZeigen && (
              <FooterSitemap
                menu={
                  footerConfig.fieldMenue
                    ? footerConfig.fieldMenue.targetId
                    : "main"
                }
              />
            )}
            <div className="row">
              <div className="col-16 col-lg-8 footer logo">
                {this.props.overwriteFooter.fieldFooterLogo ? (
                  <Image
                    data={this.props.overwriteFooter.fieldFooterLogo.entity.fieldMediaImage}
                    className="footer-logo"
                  />
                ) : (
                  <RBXAnimation className="loading-animation" />
                )}
              </div>
              <div className="col-16 col-lg-8 footer links">
                <div className="row">
                  <FooterMenu/>
                  {this.props.currentLanguage !== "en" && !!this.props.accesibilityMenu?.links?.length && (
                    <div className="col-16 col-lg-8">
                      <img
                        src={`${self}/accessibility-logo-white.svg`}
                        alt={"Button: Infos zur Barrierearmut"}
                        className="accessibility-icon"
                      />
                      <nav
                        className="accesibility-menu"
                        aria-label={"footer accessibility menu"}
                      >
                        <ul className="row">
                          {this.props.accesibilityMenu.links.map(
                            (item, index) => (
                              <li key={index}>
                                <Link to={item.url.path}>{item.label}</Link>
                              </li>
                            )
                          )}
                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
                <SocialMedia content={socialMediaContent}/>
                <FooterPartners content={footerPartners}/>
              </div>
            </div>
          </>
        </div>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  currentLanguage: PropTypes.string,
  footerConfig: PropTypes.shape({
    fieldFooterSitemapZeigen: PropTypes.bool,
    fieldCopyright: PropTypes.string.isRequired,
    fieldMenue: PropTypes.shape({
      targetId: PropTypes.string,
    }),
    fieldLinkBkmDe: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkBkmEn: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkHhDe: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkHhEn: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldDeezer: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
  footerMenu: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
  accesibilityMenu: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(PageFooter);
