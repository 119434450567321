import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { self } from "../../../../config";
import Image from "../../../image/image";
import classNames from "classnames";

const YouTubeVideo = (props) => {
  const [showVideo, setShowVideo] = useState(false),
    [showInfo, setShowInfo] = useState(false);

  const mediaClassNames = classNames({
    "media-wrapper col-16": true,
    "col-md-8": !props.content.fieldBig,
    "show-infos": showInfo && !showVideo,
    "hide-infos": !showInfo && showVideo,
    "show-video": showVideo,
    "hide-video": !showVideo,
  });

  const infoClassNames = classNames({
    "info-wrapper col-16": true,
    "col-md-8": true
  });

  const rowClassNames = classNames({
    "row": true,
    "left": !props.content.fieldBig && props.content.fieldOrientation !== "right",
    "right": !props.content.fieldBig && props.content.fieldOrientation === "right"
  });

  return (
    <section
      className={props.sectionClassNames}
      data-darkmode={props.content.fieldDarkMode}
    >
      <div className="container">
        <div className={rowClassNames}>
          <div
            className={mediaClassNames}
          >
            <div className="play-wrapper">
              <button
                className="play"
                onClick={() => setShowInfo(true)}
                tabIndex={0}
                role={"button"}
                aria-label={"play video"}
              >
                <img src={`${self}/ui/play_video.svg`} alt={"Abspielen Button"} />
              </button>
              <div className="dsgvo-thumbnail youtube">
                <Image data={props.content.fieldImage.entity.fieldMediaImage} />
              </div>
              {showInfo && (
                <div className="privacy-info-banner">
                  <div className="action-wrapper">
                    <div className="accept-info">
                      Beim Abspielen dieses Videos werden Daten an YouTube
                      übertragen. Näheres dazu unter{" "}
                      <Link to={"/datenschutz"}>Datenschutz</Link>.
                    </div>
                    <button
                      tabIndex={0}
                      role="button"
                      aria-label="accept privacy infos"
                      className="btn btn-primary"
                      onClick={() => {
                        setShowVideo(true);
                        setShowInfo(false);
                      }}
                    >
                      Alles klar
                    </button>
                  </div>
                </div>
              )}
            </div>
            {showVideo && (
              <div className="video-wrapper">
                <div className="iframe-positioner">
                  <div className="iframe-wrap">
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube-nocookie.com/embed/${
                        props.content.fieldYoutubeVideoUrl.videoId
                      }${showVideo ? "?autoplay=1" : ""}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      rel={0}
                      playsInline
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={infoClassNames}
          >
            <div className="scroll-wrapper">
              <div className="text-wrapper">
                {props.content.fieldSubline && (
                  <h3 className="subline">{props.content.fieldSubline}</h3>
                )}
                {props.content.fieldHeadline && (
                  <h2 className="headline">{props.content.fieldHeadline}</h2>
                )}
                {props.content.fieldText && (
                  <div
                    tabIndex={0}
                    className="text allow-scrolling"
                    dangerouslySetInnerHTML={{
                      __html: props.content.fieldText.processed,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

YouTubeVideo.propTypes = {
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldBig: PropTypes.bool,
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string.isRequired,
    }),
    fieldDarkMode: PropTypes.bool,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object.isRequired,
      }),
    }),
    fieldSubline: PropTypes.string,
    fieldHeadline: PropTypes.string,
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
  }),
  sectionClassNames: PropTypes.string,
};

export default YouTubeVideo;
