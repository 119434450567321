import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";

const CompositeField = ({ item, token, generatedInitialValues }) => {
  const { values } = useFormikContext();

  const { invisible, visible, optional, required } = states(
    item.states,
    values
  );

  const initialValue = values[item.id];

  return (
    <div
      className={classNames({
        "form-group custom-composite": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        <h2>
          {item.title}
          {(!!item.required || required) && !optional && visible && (
            <span className="required">*</span>
          )}
        </h2>
      </label>

      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}

      {/* @see https://formik.org/docs/api/fieldarray */}
      <FieldArray
        name={item.id}
        render={(arrayHelpers) => (
          <>
            <div className="form-row">
              {[...Array(initialValue.length)].map((i, index) => (
                <div
                  key={index}
                  className="col-16 col-md-8"
                  style={{
                    padding: "1em",
                    border: "1px solid grey",
                  }}
                >
                  <WebformElements
                    items={item.elements}
                    token={token}
                    compositeIndex={item.multiple ? index : undefined}
                    compositeParent={item.id}
                  />

                  {item.multiple ? (
                    <div style={{ marginTop: "1em" }}>
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Element entfernen
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            {!!item.multiple &&
            (item.multiple.limit === 0 ||
              item.multiple.limit > initialValue.length) ? (
              <div style={{ marginTop: "1em" }}>
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push(generatedInitialValues[item.id][0])
                  }
                >
                  Element hinzufügen
                </button>
              </div>
            ) : null}
          </>
        )}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
    </div>
  );
};

CompositeField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    states: PropTypes.object,
    flex: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.object,
    multiple: PropTypes.shape({
      limit: PropTypes.number,
      message: PropTypes.string,
    }),
    elements: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
  generatedInitialValues: PropTypes.object,
};

export default CompositeField;
