import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AnchorLink from "./../anchor-link";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

class ParagraphSectionheading extends Component {

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
    });

    return (
      <section className={sectionClassNames} data-darkmode={this.props.content.fieldDarkMode}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-16 col-lg-12">
              {this.props.content.fieldAnchorLink && (
                <AnchorLink link={this.props.content.fieldAnchorLink}/>
              )}
              <h2 className="section-heading">
                <Hyphenated language={de}>
                  {this.props.content.fieldHeading}
                </Hyphenated>
              </h2>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
    fieldAnchorLink: PropTypes.string,
  }),
};

export default ParagraphSectionheading;
