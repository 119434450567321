import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import menuQuery from "../general-components/menu-query.graphql";
import { self } from "../config";
import {
  accessibilityMenuOpenAction,
  mainMenuOpenAction,
} from "../app-actions";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  accessibilityMenuOpen: reduxStore.appStore.accessibilityMenuOpen,
  mainMenuOpen: reduxStore.appStore.mainMenuOpen,
});

/**
 * Close on menu link click.
 */
class AccessibilityMenu extends Component {
  state = {
    menuOpen: false,
  };

  toggleAccessibilityMenu = () => {
    this.props.dispatch(
      accessibilityMenuOpenAction(!this.props.accessibilityMenuOpen)
    );
    this.props.dispatch(mainMenuOpenAction(false));
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  render() {
    if (!this.props.accessibilityMenu?.menuByName?.links?.length) {
      return;
    }

    return (
      <div
        className={`accesibility-menu-wrapper ${
          this.props.accessibilityMenuOpen ? "active" : "inactive"
        }`}
      >
        <button
          className={"accesibility-button"}
          onClick={() => this.toggleAccessibilityMenu()}
          aria-label="open accessibility menu"
          tabIndex={0}
        >
          <img
            src={`${self}/accessibility-logo.svg`}
            alt={"Button: Einstellungen zur Barrierearmut"}
          />
        </button>
        {this.props.accessibilityMenu &&
          this.props.accessibilityMenu.menuByName && (
            <div className="fixed-wrapper header-element">
              <nav className="accesibility-menu" aria-label={"accessibility menu"}>
                <div className="border-element" />
                <div className="effect-element" />
                <ul>
                  {this.props.accessibilityMenu.menuByName.links.map(
                    (item, index) => (
                      // Rempved: ${index === 0 || index === 1 ? 'with-icon' : 'without-icon'}
                      <li key={index} className={`col`}>
                        {/*{index === 0 &&
                          <img className="accesibility-icon" src={`${self}/icon-gebaerdensprache.svg`} alt={"icon Gebärdensprache"}/>
                        }
                        {index === 1 &&
                          <img className="accesibility-icon" src={`${self}/icon-leichte-sprache.svg`} alt={"icon Leichte Sprache"}/>
                        }*/}
                        <Link to={item.url.path}>{item.label}</Link>
                      </li>
                    )
                  )}
                </ul>
              </nav>
            </div>
          )}
      </div>
    );
  }
}

AccessibilityMenu.propTypes = {
  accessibilityMenuOpen: PropTypes.bool,
  accessibilityMenu: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "accessibility-menu",
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "accessibilityMenu",
    })
  )(AccessibilityMenu)
);
