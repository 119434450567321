import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import Image from "../../../image/image";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import VisibilitySensor from "react-visibility-sensor";
import LazyLoad from "react-lazyload";

/**
 * This get called from ParagraphBilderSlider and actually renders the images.
 */

class ComponentBildSlider extends Component {
  slider = React.createRef();
  slickSlider = React.createRef();

  state = {
    slideIndex: 0,
    sliderSettings: {
      adaptiveHeight: false,
      arrows: true,
      fade: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: false,
      centerMode: false,
      infinite: true,
      nextArrow: <button aria-label="next" tabIndex={0} />,
      beforeChange: (current, next) => {
        // determine active slide, next and next but one slide
        let activeSlide = next,
          nextSlide = next + 1 <= this.props.bilder.length - 1 ? next + 1 : 0,
          nextButOneSlide =
            next + 2 <= this.props.bilder.length - 1 ? next + 2 : 0;

        // Set CSS Variables, god knows why
        this.slider.current.style.setProperty("--active-slide", next);
        this.slider.current.style.setProperty("--next-slide", nextSlide);
        this.slider.current.style.setProperty(
          "--next-but-one-slide",
          nextButOneSlide
        );

        // Remove classes
        this.slider.current
          .querySelector(".inner-slide.active-slide")
          .classList.remove("active-slide");
        this.slider.current
          .querySelector(".inner-slide.next-slide")
          .classList.remove("next-slide");
        this.slider.current
          .querySelector(".inner-slide.next-but-one-slide")
          .classList.remove("next-but-one-slide");

        // Add new classes
        setTimeout(() => {
          this.slider.current
            .querySelectorAll(".inner-slide")
            [activeSlide].classList.add("active-slide");
          this.slider.current
            .querySelectorAll(".inner-slide")
            [nextSlide].classList.add("next-slide");
          this.slider.current
            .querySelectorAll(".inner-slide")
            [nextButOneSlide].classList.add("next-but-one-slide");
        }, 0);
      },
    },
  };

  updateSlides = (current, next) => {
    this.setState({ slideIndex: next });
  };

  visibilityAnimation = () => {
    this.slickSlider.current.slickNext();
  };

  render() {
    return (
      <div
        className={`slider-wrapper format-${this.props.stackFormat}`}
        ref={this.slider}
      >
        {this.props.bilder.length > 1 ? (
          <VisibilitySensor
            partialVisibility={false}
            onChange={(isVisible) =>
              isVisible ? this.visibilityAnimation(isVisible) : false
            }
          >
            {({ isVisible }) => (
              <div className={isVisible ? "visible" : "not-visible"}>
                {/*<MorphSlider opts={opts} images={this.props.bilder}/>*/}
                <Slider {...this.state.sliderSettings} ref={this.slickSlider}>
                  {this.props.bilder.map((item, index) => (
                    <div
                      className={`
                      inner-slide
                      ${index === 0 ? "active-slide" : ""}
                      ${index === 1 ? "next-slide" : ""}
                      ${index === 2 ? "next-but-one-slide" : ""}
                  `}
                      data-id={index}
                      key={index}
                    >
                      {item.entity.fieldMediaImage && (
                        <article className="media-image">
                          <Image
                            data={item.entity.fieldMediaImage}
                            credit={item.entity.fieldCredit}
                            globalCaption={this.props.globalCaption}
                            wrapCaption={this.props.wrapCaption}
                          />
                        </article>
                      )}
                      {item.entity.fieldTyp === "youtube" &&
                        item.entity.fieldMediaOembedVideo && (
                          <YouTube
                            videoId={
                              item.entity.fieldMediaOembedVideo.split("?v=")[1]
                            }
                          />
                        )}
                      {item.entity.fieldTyp === "vimeo" &&
                        item.entity.fieldMediaOembedVideo && (
                          <Vimeo
                            video={item.entity.fieldMediaOembedVideo}
                            responsive
                          />
                        )}
                      {item.entity.fieldMediaVideoFile && (
                        <video
                          src={item.entity.fieldMediaVideoFile.entity.url}
                          autoPlay
                          muted
                          playsInline
                          loop
                          controls={false}
                        />
                      )}
                      {(item.customCaption || item.defaultCaption) && (
                        <div className="caption-wrap">
                          {item.customCaption && (
                            <span className="caption">
                              {item.customCaption}
                            </span>
                          )}
                          {item.defaultCaption && (
                            <span className="caption">
                              {item.defaultCaption}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </VisibilitySensor>
        ) : (
          <article className="media-image">
            <Image
              data={this.props.bilder[0].entity.fieldMediaImage}
              credit={this.props.bilder[0].entity.fieldCredit}
              caption={
                this.props.bilder[0].defaultCaption === false
                  ? this.props.bilder[0].customCaption
                  : null
              }
              globalCaption={this.props.globalCaption}
              wrapCaption={this.props.wrapCaption}
            />
          </article>
        )}
      </div>
    );
  }
}

ComponentBildSlider.propTypes = {
  bilder: PropTypes.arrayOf(
    PropTypes.shape({
      defaultCaption: PropTypes.bool,
      customCaption: PropTypes.string,
      entity: PropTypes.shape({
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldMediaVideoFile: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldTyp: PropTypes.string,
        fieldMediaOembedVideo: PropTypes.string,
      }),
    })
  ),
  globalCaption: PropTypes.string,
  wrapCaption: PropTypes.bool,
};

export default ComponentBildSlider;
