import React from "react";
import ErrorBoundary from "../../../../error-boundary";
import DateWrapper from "./date-wrapper";
import {FormattedMessage} from "react-intl";

const AllDates = (props) => {

  if (props.content.fieldDate) {
    return (
      <ErrorBoundary>
        <div className="multiple-dates">
          <div className="info-headline">
            <FormattedMessage id={"event.dates"}/>
          </div>
          {!props.content.fieldDuplicates &&
            <article className="other-date">
              <DateWrapper content={props.content} showTBA={true} releaseState={props.releaseState}/>
              {props.content.fieldLocationName &&
                props.releaseState ===
                "all" && (
                  <div className="location">
                    {props.content.fieldLocationName}
                  </div>
                )}
            </article>
          }
          {props.content.fieldDuplicates &&
            <>
              {props.content.fieldDuplicates.map((item, index) =>
                <article className="other-date" key={index}>
                  <DateWrapper content={item.entity} showTBA={true} releaseState={props.releaseState}/>
                  {props.content.fieldLocationName &&
                    props.releaseState ===
                    "all" && (
                      <div className="location">
                        {props.content.fieldLocationName}
                      </div>
                    )}
                </article>
              )}
            </>
          }
          <article className="other-date">
            <DateWrapper content={props.content} showTBA={true} releaseState={props.releaseState}/>
            {props.content.fieldLocationName &&
              props.releaseState ===
              "all" && (
                <div className="location">
                  {props.content.fieldLocationName}
                </div>
              )}
          </article>
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default AllDates;
