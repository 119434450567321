import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Image from "../../../image/image";

class ParagraphDossier extends Component {
  state = {
    slideIndex: 0,
    sliderSettings: {
      adaptiveHeight: false,
      arrows: true,
      fade: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      touchMove: true,
      centerMode: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 3,
            touchMove: true,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1.5,
            touchMove: true,
          },
        },
      ],
    },
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-dossier": true,
      "with-background-image": this.props.content.fieldImage !== null,
    });

    return (
      <section
        className={sectionClassNames}
        data-darkmode={this.props.content.fieldDarkMode}
      >
        {this.props.content.fieldImage !== null && (
          <div className="background-image">
            <Image
              data={this.props.content.fieldImage.entity.fieldMediaImage}
            />
          </div>
        )}
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <Slider {...this.state.sliderSettings}>
          {this.props.content.fieldDossierbeitraege.map((item, index) => {
            let content = (
              <div className={`teaser`}>
                {item.entity.fieldTeaserbild &&
                  item.entity.fieldTeaserbild.entity.fieldMediaImage && (
                    <Image
                      data={item.entity.fieldTeaserbild.entity.fieldMediaImage}
                      credit={item.entity.fieldTeaserbild.entity.fieldCredit}
                      className="main-image"
                    />
                  )}
                <div className="text-wrapper">
                  <h2>{item.entity.fieldHeading}</h2>
                  {item.entity.fieldTeasertext && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: item.entity.fieldTeasertext,
                      }}
                    />
                  )}
                  {item.entity.fieldLink && item.entity.fieldLink.url && (
                    <>
                      {item.entity.fieldLink.title ? (
                        <span className="read-more">{item.entity.fieldLink.title}</span>
                      ) : (
                        <span className="read-more">learn more</span>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
            return (
              <React.Fragment key={index}>
                {item.entity.fieldLink && item.entity.fieldLink.url && (
                  <>
                    {item.entity.fieldLink.url.routed ? (
                      <Link to={item.entity.fieldLink.url.path}>{content}</Link>
                    ) : (
                      <a
                        href={item.entity.fieldLink.url.path}
                        target={"_blank"}
                        rel="noreferrer noopener"
                      >
                        {content}
                      </a>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </Slider>
      </section>
    );
  }
}

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldDossierbeitraege: PropTypes.array,
  }),
};

export default ParagraphDossier;
