import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import { teaserParticipantPropTypes } from "./teaser-participant";
import { encodeUrl } from "../../../lib/encode-url";
import ProgressiveImage from "react-progressive-graceful-image";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserParticipantImage extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {

    return (
      <article
        className="participant-image"
        data-to-scrollspy-id={encodeUrl(this.props.item.title)}
      >
        {/*<LazyLoad offset={1000} scrollContainer={document.querySelector(".movement-wrapper")} once={true}>*/}
        {this.props.item.fieldImage &&
          <ProgressiveImage
            src={
              this.props.item.fieldImage.entity.fieldMediaImage
                .style.url
            }
            placeholder={
              this.props.item.fieldImage.entity.fieldMediaImage
                .thumbnail.url
            }
            delay={0}
          >
            {(src, loading) => (
              <Image
                data={
                  this.props.item.fieldImage.entity
                    .fieldMediaImage
                }
                loading={loading}
                src={src}
                nodeTitle={this.props.item.title}
              />
            )}
          </ProgressiveImage>
        }
        {/*}</LazyLoad>*/}
      </article>
    );
  }
}

TeaserParticipantImage.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserParticipantPropTypes,
  index: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserParticipantImage));
