import React, { Component } from "react";
import { connect } from "react-redux";
import {compose} from "recompose";
import {graphql} from "@apollo/client/react/hoc";
import menuQuery from "../../general-components/menu-query.graphql";
import {Link, withRouter} from "react-router-dom";
import LoadingIndicator from "../../general-components/loading-indicator";
import footerConfigQuery from "../../footer-config-query.graphql";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  overwriteFooter: reduxStore.appStore.overwriteFooter,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class FooterMenu extends Component {

  render() {
    if (this.props.footerMenuQuery) {
      return (
        <div className="col-16 col-lg-8">
          <nav className="footer-menu" aria-label={"footer menu"}>
            <ul className="row">
              {this.props.footerMenuQuery?.menuByName?.links.map((item, index) => {
                if (item.url.path.includes("/node/")) {
                  return false;
                }

                return (
                  <li key={index} className="col">
                    <Link to={item.url.path}>{item.label}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      );
    }

    return <LoadingIndicator />;
  }
}

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: props.overwriteFooter.fieldFooterMenu?.targetId ? props.overwriteFooter.fieldFooterMenu?.targetId : "footer",
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "footerMenuQuery",
    })
  )(withRouter(FooterMenu))
);
