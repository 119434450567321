import React from "react";
import DateWrapper from "./date-wrapper";

const BusinessWrapper = (props) => {
  if (props.content.fieldCompany) {
    return (
      <div className="business-wrapper text">
        <p>
          <span
            className="company"
            dangerouslySetInnerHTML={{
              __html: props.content.fieldCompany,
            }}
          />{" "}
          {props.content.fieldPosition && (
            <span
              className="position"
              dangerouslySetInnerHTML={{
                __html: ` / ${props.content.fieldPosition}`,
              }}
            />
          )}
        </p>
      </div>
    );
  } else {
    return false;
  }
};

export default BusinessWrapper;
