import React from "react";
import {FormattedMessage} from "react-intl";

const LookingFor = (props) => {

  if (props.content.fieldLookingFor || props.content.fieldBookingTerritory || props.content.fieldManagementTerritory) {
    return (
      <div className="looking-for">
           <span className="label">
              <FormattedMessage id="lookingfor" />
           </span>
        {props.content.fieldLookingFor &&
          <div className="main">
            <span className="what">
               {props.content.fieldLookingFor}
             </span>
            {props.content.fieldLookingForLocation &&
              <span className="where">
                 : {props.content.fieldLookingForLocation}
               </span>
            }
          </div>
        }
        {props.content.fieldBookingTerritory &&
          <div className="booking">
            <span className="what">
               <FormattedMessage id="booking" />
             </span>
            {props.content.fieldBookingTerritory &&
              <span className="where">
                 : {props.content.fieldBookingTerritory}
              </span>
            }
          </div>
        }
        {props.content.fieldLabelTerritory &&
          <div className="label">
            <span className="what">
               <FormattedMessage id="label" />
             </span>
            {props.content.fieldLabelTerritory &&
              <span className="where">
                 : {props.content.fieldLabelTerritory}
              </span>
            }
          </div>
        }
        {props.content.fieldManagementTerritory &&
          <div className="management">
            <span className="what">
               <FormattedMessage id="management" />
             </span>
            {props.content.fieldManagementTerritory &&
              <span className="where">
                 : {props.content.fieldManagementTerritory}
              </span>
            }
          </div>
        }
      </div>
    );
  } else {
    return false;
  }
};

export default LookingFor;
