import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AnchorLink from "../anchor-link";

/**
 * A module representing a text paragraph.
 */
class ParagraphText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-text": true,
      "no-padding-bottom": this.props.content.fieldReduceSpacing
    });

    return (
      <section className={sectionClassNames} data-darkmode={this.props.content.fieldDarkMode}>
        <div className="container">
          <div className="row">
            {this.props.content.fieldHeadline &&
              <div className="title-wrapper col-16 col-lg-8">
                {this.props.content.fieldAnchorLink && (
                  <AnchorLink link={this.props.content.fieldAnchorLink}/>
                )}
                <h2 className="headline"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldHeadline
                  }}
                />
              </div>
            }
            <div className={`text-wrapper col-16 col-lg-8 ${this.props.content.fieldHeadline || this.props.content.fieldTextOrientation === "left" ? '' : 'offset-lg-8'}`}>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fieldText.processed,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldDarkMode: PropTypes.bool,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ParagraphText;
