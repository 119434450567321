import React from "react";
import DateWrapper from "./date-wrapper";

const FeaturedEvents = (props) => {
  /*@todo: release State should not be given by props, it should be connected to redux.*/
  if (props.content.fieldEvents) {
    return (
      <div className={"featured-event-wrapper"}>
        {props.content.fieldEvents.map(
          (featuredEvent, index) => (
            <div className="featured-on-event" key={index}>
              <DateWrapper
                content={featuredEvent.entity}
                releaseState={
                  props.releaseState
                }
              />
              {featuredEvent.entity.entityLabel && (
                <div className="title">
                  {featuredEvent.entity.entityLabel}
                </div>
              )}
            </div>
          )
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default FeaturedEvents;
