import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MorphSlider from "./morph-slider";
import Link from "./../../../link/link";
import Slider from "react-slick";
import { connect } from "react-redux";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  mainSettings: reduxStore.appStore.mainSettings,
  currentLanguage: reduxStore.i18n.currentLanguage,
  isMobile: reduxStore.appStore.isMobile,
});

class ParagraphTeaserSlider extends Component {
  slider = React.createRef();

  state = {
    sliderSettings: {
      arrows: true,
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: false,
      infinite: true,
      touchMove: true,
      beforeChange: (oldIndex, newIndex) => {
        this.slider.current.classList.add("changing");
        setTimeout( () => {
          this.slider.current.classList.remove("changing");
        }, 2000);
      },
    },
  };

  objectToArray = (myObject) => {
    // Only get the file urls from the object and store them as array
    let imageArray = [];

    Object.keys(myObject).forEach((item) => {
      imageArray.push(
        myObject[item].entity.fieldImage.entity.fieldMediaImage.style.url
      );
    });
    return imageArray;
  };

  restructure = (urls) => {
    // Moving first element to end
    let array = [...urls];
    let first = array[0];

    array.shift();
    array.push(first);

    return array;
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-slider": true,
    });

    let content = [],
    slides = this.props.content.fieldTeaserSlides;

    if (slides) {
      // Duplicate if less than 2, otherwise the slider wont work.
      if (slides.length <= 2) {
        slides = [...slides, ...slides];
      }
      content = this.objectToArray(slides);
    }

    return (
      <section className={sectionClassNames}>
        {this.props.content && this.props.content.fieldTeaserSlides && (
          <>
            <React.Suspense>
              <div className="slider-wrapper">
                <MorphSlider
                  content={content}
                  id={`slider-${this.props.content.entityId}-1`}
                  isMobile={this.props.isMobile}
                />
                <MorphSlider
                  content={this.restructure(content)}
                  id={`slider-${this.props.content.entityId}-2`}
                  isMobile={this.props.isMobile}
                />
              </div>
            </React.Suspense>
            <div className="content-slider-wrapper" ref={this.slider}>
              <Slider {...this.state.sliderSettings}>
                {slides.map((item, index) => (
                  <React.Fragment key={index}>
                    <article className="container">
                      <div className="row">
                        <div className="col-16 position-static">
                          {item.entity.fieldHeading && (
                            <h2>{item.entity.fieldHeading}</h2>
                          )}
                          {item.entity.fieldText && (
                            <div
                              className="text"
                              dangerouslySetInnerHTML={{
                                __html: item.entity.fieldText.processed,
                              }}
                            />
                          )}
                          {item.entity.fieldLink && (
                            <>
                              <Link
                                className="link"
                                link={item.entity.fieldLink}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </article>
                  </React.Fragment>
                ))}
              </Slider>
            </div>
          </>
        )}
      </section>
    );
  }
}

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldHeading: PropTypes.string,
          fieldImage: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphTeaserSlider);
