import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphBilderSlider extends Component {

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-bilder-slider": true,
      [`format-${this.props.content.fieldFormat}`]: true,
      [`stack-format-${this.props.content.fieldStackFormat}`]: true,
    });

    return (
      <section className={sectionClassNames} data-darkmode={this.props.content.fieldDarkMode}>
        {this.props.content.fieldFormat === "full" ? (
          <ErrorBoundary>
            <ComponentBildSlider
              wrapCaption={true}
              bilder={this.props.content.fieldBilderWCaption}
              stackFormat={this.props.content.fieldStackFormat}
              globalCaption={this.props.content.fieldBildunterschrift}
            />
          </ErrorBoundary>
        ) : (
          <div className="container">
            <div className="row">
              <div
                className={classNames({
                  "col-16": true,
                  "col-lg-8 offset-lg-4":
                    this.props.content.fieldFormat === "center" && this.props.content.fieldBilderWCaption.length > 1,
                  "col-lg-8": this.props.content.fieldFormat === "left"  && this.props.content.fieldBilderWCaption.length > 1,
                  "col-lg-8 offset-lg-8":
                    this.props.content.fieldFormat === "right" && this.props.content.fieldBilderWCaption.length > 1,
                })}
              >
                <ErrorBoundary>
                  <ComponentBildSlider
                    bilder={this.props.content.fieldBilderWCaption}
                    globalCaption={this.props.content.fieldBildunterschrift}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaption: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
