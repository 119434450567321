import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserPersonOverview from "../../../teaser-base/person/teaser-person-overview";
import ErrorBoundary from "../../../../error-boundary";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";

class ParagraphKontakt extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-kontakt": true,
    });

    return (
      <section className={sectionClassNames} data-darkmode={this.props.content.fieldDarkMode}>
        <div className="container">
          <div className="row">
            {this.props.content.fieldPersonen.map((item, index) => (
              <div
                key={index}
                className={classNames({
                  "col-16 col-lg-8": true
                })}
              >
                <ErrorBoundary>
                  <TeaserPersonOverview
                    item={item.entity}
                    pagerFullPage={this.props.pagerFullPage}
                  />
                </ErrorBoundary>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphKontakt.propTypes = {
  content: PropTypes.shape({
    fieldBilderZeigen: PropTypes.bool,
    fieldPersonen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphKontakt;
