import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { encodeUrl } from "../../../lib/encode-url";
import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";

const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserLocationImage = (props) => {
  return (
    <article
      className="location-image"
      data-to-scrollspy-id={encodeUrl(props.item.title)}
    >
      <EditButton
        adminApp={props.adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location.pathname}
      />
      {props.item.fieldImage && (
        <Image
          data={props.item.fieldImage.entity.fieldMediaImage}
          nodeTitle={`Location: ${props.item.title}`}
        />
      )}
    </article>
  );
};

TeaserLocationImage.propTypes = {
  adminApp: PropTypes.bool,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserLocationImage));
