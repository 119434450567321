import React, { Component } from 'react';
import {connect} from "react-redux";
import RBXAnimation from "./rbx-animation/rbx-animation";


class NotFound extends Component {

  render() {
    const { staticContext } = this.props;

    staticContext.status = 404;

    return (
      <div className="page-404">
        <div className="inner-wrapper">
          <h1>404 - Page not found</h1>
          <RBXAnimation/>
        </div>
      </div>
    );
  };
}


NotFound.defaultProps = {
  staticContext: {}
};

export default NotFound;
