import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

const Credits = ({ content }) => {
  const credits = content.fieldImage?.entity?.fieldCredit;
  if (!credits) {
    return null;
  }

  return (
    <div>
      <FormattedMessage id="photo" />
      {!credits.includes("©") ? <>{" © "}</> : null} {credits}
    </div>
  );
};

Credits.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Credits;
