import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";

moment.locale("de");

/**
 * Usually the first module on an event page - event intro module with most
 * important information.
 *
 * @todo field_ticket_link
 */
class ParagraphCalendarInformation extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-calendar-information": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-15 offset-md-1">
              <div className="row">
                <div className="col-3">
                  <p>
                    {moment
                      .utc(this.props.nodeContent.fieldDatum.value)
                      .local()
                      .format("dddd")}
                  </p>
                  <p>
                    {moment
                      .utc(this.props.nodeContent.fieldDatum.value)
                      .local()
                      .format("DD.MM.YYYY")}
                  </p>
                </div>
                <div className="col-4">
                  <p>{this.props.nodeContent.fieldOrt}</p>
                  <p>
                    {moment
                      .utc(this.props.nodeContent.fieldDatum.value)
                      .local()
                      .format("HH:mm") !== "00:00" && (
                      <span>
                        {moment
                          .utc(this.props.nodeContent.fieldDatum.value)
                          .local()
                          .format("HH:mm")}{" "}
                        Uhr
                      </span>
                    )}
                    {this.props.nodeContent.fieldDatum.endValue &&
                      !this.props.nodeContent.fieldDauerAusblenden && (
                        <span className="duration">
                          {Math.round(
                            moment
                              .duration(
                                moment
                                  .utc(
                                    this.props.nodeContent.fieldDatum.endValue
                                  )
                                  .local()
                                  .diff(
                                    moment
                                      .utc(
                                        this.props.nodeContent.fieldDatum.value
                                      )
                                      .local()
                                  )
                              )
                              .asHours()
                          )}{" "}
                          Std.
                        </span>
                      )}
                  </p>
                </div>
                <div className="col-5">
                  {this.props.nodeContent.fieldSchlagwort.length > 0 && (
                    <p>
                      {this.props.nodeContent.fieldSchlagwort[0].entity.name}
                    </p>
                  )}
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <a href="#" className="btn btn-primary">
                    <FormattedMessage id="event.remember" />
                  </a>
                  <a href="#" className="btn btn-primary">
                    <FormattedMessage id="event.ticket" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphCalendarInformation.propTypes = {
  content: PropTypes.object,
  nodeContent: PropTypes.shape({
    fieldDauerAusblenden: PropTypes.bool,
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldOrt: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphCalendarInformation;
